<template>
  <div
    v-if="
      !record ||
      (record &&
        (record.kam ||
          record.recordtype == 'customer' ||
          record.recordtype == 'country' ||
          record.recordtype == 'manufacturergroup'))
    "
  >
    <v-row v-show="show">
      <v-expand-transition>
        <v-col>
          <v-row>
            <v-col>
              <v-select
                :items="quarters"
                v-model="quarter"
                label="Quarter"
                class="mt-2 mb-2"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                dense
                @change="getData"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :items="manufacturers.length ? manufacturers : []"
                v-model="manufacturergroup"
                label="Manufacturers"
                :disabled="!manufacturers.length"
                class="mt-2 mb-2"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                dense
                @change="getData"
                hide-details
                v-if="
                  !record ||
                  (record && record.recordtype !== 'manufacturergroup')
                "
              >
              </v-select>
            </v-col>
            <v-col>
              <v-autocomplete
                v-if="!record || (record && record.recordtype !== 'country')"
                :items="countries"
                v-model="country"
                :disabled="!countries.length"
                label="Country"
                class="mt-2 mb-2"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                @change="getData"
                hide-details
                dense
              >
              </v-autocomplete
            ></v-col>
            <v-col>
              <v-select
                :items="salesreps"
                v-model="salesrep"
                item-text="name"
                :disabled="
                  !['admin', 'role_sales_manager'].includes(
                    $store.state.user.role._id
                  ) ||
                  (record && record.recordtype == 'employee')
                "
                item-value="_id"
                label="Sales Rep"
                class="mt-2 mb-2"
                outlined
                clearable
                return-object
                @change="getData"
                hide-details
                dense
              ></v-select
            ></v-col>
            <v-col class="text-right" v-if="false">
              <v-btn
                @click="show = !show"
                tile
                color="primary"
                class="mt-2 mb-2"
              >
                Reports
                <v-icon right>{{
                  show ? "mdi-chevron-down" : "mdi-chevron-up"
                }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="{ itemsPerPageOptions: [-1] }"
          :search="search"
          hide-default-footer
          :loading="loading"
          @current-items="getFiltered"
          loading-text="Loading... Please wait"
          :item-class="rowClass"
        >
          <template v-slot:item.billcountry="{ item }">
            <router-link
              v-if="item.billcountry"
              :to="{
                path: `/countries/country/view/${item.billcountry}`,
              }"
              class="link"
              >{{ item.billcountry }}</router-link
            >
          </template>

          <template v-slot:item.manufacturergroup="{ item }">
            <router-link
              v-if="item.manufacturergroup && item.manufacturergroup._id"
              :to="{
                path: `/${item.manufacturergroup.collection}/${item.manufacturergroup.recordtype}/view/${item.manufacturergroup._id}`,
              }"
              class="link"
              >{{ item.manufacturergroup.name }}</router-link
            >
          </template>
          <template v-slot:item.lastsales="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.lastsales)
            }}
          </template>
          <template v-slot:item.sales="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.sales)
            }}
          </template>
          <template v-slot:item.salestarget="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salestarget)
            }}
          </template>
          <template v-slot:item.forecast="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.forecast)
            }}
          </template>
          <template v-slot:item.lastpercent="{ item }">
            <v-chip
              label
              v-if="item.lastpercent"
              :color="
                parseFloat(item.lastpercent) > 75
                  ? 'green'
                  : parseFloat(item.lastpercent) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.lastpercent }}%</v-chip
            >
          </template>
          <template v-slot:item.percent="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent) > 75
                  ? 'green'
                  : parseFloat(item.percent) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent }}%</v-chip
            >
          </template>
          <template v-slot:item.percent2="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent2) > 75
                  ? 'green'
                  : parseFloat(item.percent2) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent2 }}%</v-chip
            >
          </template>
          //foreqts q
          <template v-slot:item.lastsalesq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.lastsalesq)
            }}
          </template>
          <template v-slot:item.salesq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salesq)
            }}
          </template>
          <template v-slot:item.salestargetq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salestargetq)
            }}
          </template>
          <template v-slot:item.forecastq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.forecastq)
            }}
          </template>
          <template v-slot:item.lastpercentq="{ item }">
            <v-chip
              label
              v-if="item.lastpercentq"
              :color="
                parseFloat(item.lastpercentq) > 75
                  ? 'green'
                  : parseFloat(item.lastpercentq) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.lastpercentq }}%</v-chip
            >
          </template>
          <template v-slot:item.percentq="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percentq) > 75
                  ? 'green'
                  : parseFloat(item.percentq) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percentq }}%</v-chip
            >
          </template>
          <template v-slot:item.percent2q="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent2q) > 75
                  ? 'green'
                  : parseFloat(item.percent2q) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent2q }}%</v-chip
            >
          </template>
          <template slot="body.append">
            <tr v-if="items.length > 1">
              <th v-for="(col, index) in headers" :key="index">
                <v-chip
                  label
                  dark
                  v-if="
                    [
                      'lastpercent',
                      'percent',
                      'percent2',
                      'percentq',
                      'percent2q',
                    ].includes(col.value)
                  "
                  >{{ calcTotal(col.value) }}%</v-chip
                >
                <span v-else> {{ calcTotal(col.value) }}</span>
              </th>
            </tr>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-expansion-panels
              tile
              flat
              focusable
              v-if="
                [
                  'admin',
                  'role_sales_manager',
                  'role_kam',
                  'role_kam_uk',
                ].includes($store.state.user.role._id) ||
                (record && record.recordtype == 'employee')
              "
            >
              <v-expansion-panel>
                <v-subheader
                  ><v-expansion-panel-header class="ma-0 pa-0">
                    BONUS SYSTEM (click to show)
                  </v-expansion-panel-header></v-subheader
                >
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-subheader>
                        <v-icon left color="primary">
                          mdi-account-multiple-plus
                        </v-icon>
                        This Year
                      </v-subheader>
                      <v-list two-line>
                        <v-list-item>
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  activeCustomers
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Active Customers</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  activeCustomers5k
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Active Customers (+5K)</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title
                                  >+{{ newCustomers }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >New Customers</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  newCustomers5k
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >New Customers (+5K)</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title
                                  >+{{ restoredCustomers }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Restored Customers</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  restoredCustomers5k
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Restored Customers
                                  (+5K)</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                      <v-btn tile text color="primary" @click="opensource">
                        <v-icon left>mdi-format-list-bulleted</v-icon>Data
                        Source</v-btn
                      >
                    </v-col>
                    <v-col v-if="!country && !manufacturergroup && salesrep">
                      <v-subheader>
                        <v-icon left color="primary"> mdi-currency-usd </v-icon>
                        Est. Bonus
                      </v-subheader>
                      <v-list two-line>
                        <v-list-item v-if="quarter > 1">
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  new Intl.NumberFormat(format.local, {
                                    style: "currency",
                                    currency: format.currency,
                                  }).format(lastBonus)
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Last Quarter (Q{{
                                    quarter - 1
                                  }})</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                            <v-col>
                              <v-list-item-subtitle
                                v-for="(c, index) of quarterBonus(true)"
                                :key="index"
                              >
                                <small
                                  >{{ index }}:
                                  <b>{{
                                    new Intl.NumberFormat(format.local, {
                                      style: "currency",
                                      currency: format.currency,
                                    }).format(c)
                                  }}</b></small
                                >
                              </v-list-item-subtitle>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  new Intl.NumberFormat(format.local, {
                                    style: "currency",
                                    currency: format.currency,
                                  }).format(thisBonus)
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >This Quarter (Q{{
                                    quarter
                                  }})</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                            <v-col>
                              <v-list-item-subtitle
                                v-for="(c, index) of quarterBonus(false)"
                                :key="index"
                              >
                                <small
                                  >{{ index }}:
                                  <b>{{
                                    new Intl.NumberFormat(format.local, {
                                      style: "currency",
                                      currency: format.currency,
                                    }).format(c)
                                  }}</b></small
                                >
                              </v-list-item-subtitle>
                            </v-col>
                          </v-row>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item>
                          <v-row>
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  new Intl.NumberFormat(format.local, {
                                    style: "currency",
                                    currency: format.currency,
                                  }).format(yearBonus)
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Year Bonus</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                    </v-col>
                    <v-col v-if="!country && !manufacturergroup && salesrep">
                      <v-subheader>
                        <v-icon left color="primary">
                          mdi-information-outline
                        </v-icon>
                        Details
                      </v-subheader>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              new Intl.NumberFormat(format.local, {
                                style: "currency",
                                currency: format.currency,
                              }).format(totalbonus)
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Bonus Pool</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >55% ({{
                                new Intl.NumberFormat(format.local, {
                                  style: "currency",
                                  currency: format.currency,
                                }).format(totalbonus * 0.55)
                              }})</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >Key Accounts (Rating 1)</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >20% ({{
                                new Intl.NumberFormat(format.local, {
                                  style: "currency",
                                  currency: format.currency,
                                }).format(totalbonus * 0.2)
                              }})</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >Important Customers (Rating
                              2)</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >25% ({{
                                new Intl.NumberFormat(format.local, {
                                  style: "currency",
                                  currency: format.currency,
                                }).format(totalbonus * 0.25)
                              }})</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >Other Customers ( 3 +
                              Others)</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              new Intl.NumberFormat(format.local, {
                                style: "currency",
                                currency: format.currency,
                              }).format(customerbonus)
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >For each new and restored customer whose turnover
                              is at above 5000 PLN / (~1000 GBP) net - annual
                              bonus</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-subheader
          v-if="(record && record.recordtype == 'employee') || salesrep"
        >
          Customer Targets
        </v-subheader>
        <v-data-table
          v-if="(record && record.recordtype == 'employee') || salesrep"
          :headers="kamHeaders2"
          :items="customersresults"
          :footer-props="{ itemsPerPageOptions: [-1] }"
          :search="search"
          :loading="loading"
          @current-items="getFiltered"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.customer="{ item }">
            <router-link
              v-if="item.customer && item.customer._id"
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}/?headers-active=7`,
              }"
              class="link"
              >{{ item.customer.name }}</router-link
            >
          </template>
          <template v-slot:item.billcountry="{ item }">
            <router-link
              v-if="item.billcountry"
              :to="{
                path: `/countries/country/view/${item.billcountry}`,
              }"
              class="link"
              >{{ item.billcountry }}</router-link
            >
          </template>
          <template v-slot:item.manufacturergroup="{ item }">
            <router-link
              v-if="item.manufacturergroup && item.manufacturergroup._id"
              :to="{
                path: `/${item.manufacturergroup.collection}/${item.manufacturergroup.recordtype}/view/${item.manufacturergroup._id}`,
              }"
              class="link"
              >{{ item.manufacturergroup.name }}</router-link
            >
          </template>
          <template v-slot:item.lastsales="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.lastsales)
            }}
          </template>
          <template v-slot:item.sales="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.sales)
            }}
          </template>
          <template v-slot:item.salestarget="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salestarget)
            }}
          </template>
          <template v-slot:item.forecast="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.forecast)
            }}
          </template>
          <template v-slot:item.lastpercent="{ item }">
            <v-chip
              label
              v-if="item.lastpercent"
              :color="
                parseFloat(item.lastpercent) > 75
                  ? 'green'
                  : parseFloat(item.lastpercent) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.lastpercent }}%</v-chip
            >
          </template>
          <template v-slot:item.percent="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent) > 75
                  ? 'green'
                  : parseFloat(item.percent) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent }}%</v-chip
            >
          </template>
          <template v-slot:item.percent2="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent2) > 75
                  ? 'green'
                  : parseFloat(item.percent2) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent2 }}%</v-chip
            >
          </template>
          //foreqts q
          <template v-slot:item.lastsalesq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.lastsalesq)
            }}
          </template>
          <template v-slot:item.salesq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salesq)
            }}
          </template>
          <template v-slot:item.salestargetq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.salestargetq)
            }}
          </template>
          <template v-slot:item.forecastq="{ item }">
            {{
              new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(item.forecastq)
            }}
          </template>
          <template v-slot:item.lastpercentq="{ item }">
            <v-chip
              label
              v-if="item.lastpercentq"
              :color="
                parseFloat(item.lastpercentq) > 75
                  ? 'green'
                  : parseFloat(item.lastpercentq) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.lastpercentq }}%</v-chip
            >
          </template>
          <template v-slot:item.percentq="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percentq) > 75
                  ? 'green'
                  : parseFloat(item.percentq) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percentq }}%</v-chip
            >
          </template>
          <template v-slot:item.percent2q="{ item }">
            <v-chip
              label
              :color="
                parseFloat(item.percent2q) > 75
                  ? 'green'
                  : parseFloat(item.percentq) > 25
                  ? 'orange'
                  : 'red'
              "
              dark
              >{{ item.percent2q }}%</v-chip
            >
          </template>
          <!-- <template slot="body.append">
            <tr v-if="items.length > 1 &&">
              <th v-for="(col, index) in headers" :key="index">
                {{ calcTotal(col.value) }}
              </th>
            </tr>
          </template> -->
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="customersresults" />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import service from "../../../api/index";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer", "record", "country", "manufacturergroup", "salesrep"],
  data() {
    return {
      active: 0,
      date: new Date().toISOString().substring(0, 10),
      loading: true,
      search: "",
      show: true,
      items: [],
      customers: [],
      customersresults: [],
      filteredItems: [],
      totalbonus: 7000,
      customerbonus: 200,
      format: {
        local: "pl-PL",
        currency: "PLN",
      },
      rid: null,
      quarters: [
        { _id: 1, name: "Q1" },
        { _id: 2, name: "Q2" },
        { _id: 3, name: "Q3" },
        { _id: 4, name: "Q4" },
      ],
      quarter: Math.ceil((new Date().getMonth() + 1) / 3),
      year: new Date().getFullYear(),
      headers: [
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Description", value: "description" },
        //{ text: "Sales (Last Year)", value: "lastsales" },
        //{ text: "Sales (This Year)", value: "sales" },
        //{ text: "% (Sales(TY)/Sales(LY))", value: "lastpercent" },
        { text: "Target", value: "salestarget" },
        { text: "% (Sales/Target)", value: "percent" },
        { text: "Forecast", value: "forecast" },
        { text: "% (Forecast/Target)", value: "percent2" },
      ],
      kamHeaders: [
        //{ text: "Customer", value: "customer" },
        //{ text: "Country", value: "billcountry" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Company Rating", value: "description" },

        //{ text: "Sales (This Q Last Year)", value: "lastsalesq" },

        // { text: "%", value: "lastpercentq" },
        { text: "Target This Q", value: "salestargetq" },
        { text: `Sales (This Q)`, value: "salesq" },
        { text: "%", value: "percentq" },
        { text: "Forecast This Q", value: "forecastq" },
        { text: "%", value: "percent2q" },
        { text: "Target (This Year)", value: "salestarget" },
        //  { text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        //{ text: "%", value: "lastpercent" },

        { text: "%", value: "percent" },
        { text: "Forecast This Year", value: "forecast" },
        { text: "%", value: "percent2" },
      ],
      kamHeaders2: [
        { text: "Customer", value: "customer" },
        //{ text: "Country", value: "billcountry" },
        //{ text: "Manufacturer", value: "manufacturer" },
        { text: "Company Rating", value: "description" },
        { text: "Target This Q", value: "salestargetq" },
        //{ text: "Sales (This Q Last Year)", value: "lastsalesq" },
        { text: "Sales (This Q)", value: "salesq" },
        //{ text: "%", value: "lastpercentq" },

        { text: "%", value: "percentq" },
        { text: "Forecast This Q", value: "forecastq" },
        { text: "%", value: "percent2q" },

        { text: "Target (This Year)", value: "salestarget" },
        //{ text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        // { text: "%", value: "lastpercent" },

        { text: "%", value: "percent" },
        { text: "Forecast This Year", value: "forecast" },
        { text: "%", value: "percent2" },
      ],
      totalHeaders: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Country", value: "billcountry" },
        { text: "Description", value: "description" },
        { text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        { text: "% (Sales(TY)/Sales(LY))", value: "lastpercent" },
        { text: "Target", value: "salestarget" },
        { text: "% (Sales/Target)", value: "percent" },
        { text: "Forecast", value: "forecast" },
        { text: "% (Forecast/Target)", value: "percent2" },
      ],

      countries: [],
      manufacturers: [],
      salesreps: [],
    };
  },
  async created() {
    this.loading = true;
    if (
      (this.record &&
        this.record.recordtype == "employee" &&
        this.record._id == "63a002dbb6a7591eeef3ddbd") ||
      (this.salesrep && this.salesrep._id == "63a002dbb6a7591eeef3ddbd") //jason
    ) {
      this.totalbonus = 1500;
      this.customerbonus = 50;
      this.format = {
        local: "en-US",
        currency: "GBP",
      };
    }
    if (
      (this.record &&
        this.record.recordtype == "employee" &&
        this.record._id == "5e0dbabb9e33df43f0b3d185") ||
      (this.salesrep && this.salesrep._id == "5e0dbabb9e33df43f0b3d185") //kosa
    ) {
      this.totalbonus = 7600;
    }
    if (
      (this.record &&
        ["employee", "country"].includes(this.record.recordtype)) ||
      !this.record
    ) {
      this.headers = this.kamHeaders;
      this.headers.forEach((header) => {
        if (header.value == "salestargetq")
          header.text = `Target (Q${this.quarter})`;
        if (header.value == "salesq") header.text = `Sales (Q${this.quarter})`;
        if (header.value == "forecastq")
          header.text = `Forecast (Q${this.quarter})`;

        if (header.value == "salestarget")
          header.text = `Target (${this.year})`;
        if (header.value == "sales") header.text = `Sales (${this.year})`;
        if (header.value == "forecast") header.text = `Forecast (${this.year})`;
      });

      this.kamHeaders2.forEach((header) => {
        if (header.value == "salestargetq")
          header.text = `Target (Q${this.quarter})`;
        if (header.value == "salesq") header.text = `Sales (Q${this.quarter})`;
        if (header.value == "forecastq")
          header.text = `Forecast (Q${this.quarter})`;

        if (header.value == "salestarget")
          header.text = `Target (${this.year})`;
        if (header.value == "sales") header.text = `Sales (${this.year})`;
        if (header.value == "forecast") header.text = `Forecast (${this.year})`;
      });
    }

    //if (!this.record) this.headers = this.totalHeaders;

    await this.getData();
    let countriesFilter = {};
    if (this.record && this.record.recordtype == "employee")
      countriesFilter.salesrep = this.record._id;
    this.countries = await service("countries", {
      action: "find",
      data: [countriesFilter],
      limit: -1,
    });
    this.manufacturers = await service("classification", {
      action: "find",
      data: [{ recordtype: "manufacturergroup" }],
    });
    this.salesreps = await service("users", {
      action: "find",
      data: [{ recordtype: "employee", kam: true }],
    });

    this.loading = false;
  },
  computed: {
    newCustomers() {
      return this.customers.reduce((total, v) => {
        if (v.new) {
          total += 1;
        }
        return total;
      }, 0);
    },
    activeCustomers() {
      return this.customers.reduce((total, v) => {
        if (v.active) {
          total += 1;
        }
        return total;
      }, 0);
    },
    activeCustomers5k() {
      return this.customers.reduce((total, v) => {
        if (v.active && v.sales[new Date().getFullYear()] > 5000) {
          total += 1;
        }
        return total;
      }, 0);
    },
    restoredCustomers() {
      return this.customers.reduce((total, v) => {
        if (v.restored) {
          total += 1;
        }
        return total;
      }, 0);
    },
    restoredCustomers5k() {
      return this.customers.reduce((total, v) => {
        if (v.restored && !v.new && v.sales[new Date().getFullYear()] > 5000) {
          total += 1;
        }
        return total;
      }, 0);
    },
    newCustomers5k() {
      return this.customers.reduce((total, v) => {
        if (v.new && v.sales[new Date().getFullYear()] > 5000) {
          total += 1;
        }
        return total;
      }, 0);
    },
    lastBonus() {
      return Object.values(this.quarterBonus(true)).reduce((t, v) => {
        t += v;
        return t;
      }, 0);
    },
    thisBonus() {
      return Object.values(this.quarterBonus(false)).reduce((t, v) => {
        t += v;
        return t;
      }, 0);
    },
    yearBonus() {
      let customers = this.customers.reduce((total, v) => {
        if ((v.new || v.restored) && v.sales[new Date().getFullYear()] > 5000) {
          total += 1;
        }
        return total;
      }, 0);

      return customers * this.customerbonus;
    },
    thisQ() {
      return Math.ceil(new Date().getMonth() / 3) + 1;
    },
    thisYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    quarter(v) {
      this.headers = [
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Description", value: "description" },
        //{ text: "Sales (Last Year)", value: "lastsales" },
        //{ text: "Sales (This Year)", value: "sales" },
        //{ text: "% (Sales(TY)/Sales(LY))", value: "lastpercent" },
        { text: "Target", value: "salestarget" },
        { text: "% (Sales/Target)", value: "percent" },
        { text: "Forecast", value: "forecast" },
        { text: "% (Forecast/Target)", value: "percent2" },
      ];
      this.kamHeaders = [
        //{ text: "Customer", value: "customer" },
        //{ text: "Country", value: "billcountry" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Company Rating", value: "description" },

        //{ text: "Sales (This Q Last Year)", value: "lastsalesq" },

        // { text: "%", value: "lastpercentq" },
        { text: "Target This Q", value: "salestargetq" },
        { text: `Sales (This Q)`, value: "salesq" },
        { text: "%", value: "percentq" },
        { text: "Forecast This Q", value: "forecastq" },
        { text: "%", value: "percent2q" },
        { text: "Target (This Year)", value: "salestarget" },
        //  { text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        //{ text: "%", value: "lastpercent" },

        { text: "%", value: "percent" },
        { text: "Forecast This Year", value: "forecast" },
        { text: "%", value: "percent2" },
      ];

      this.kamHeaders2 = [
        { text: "Customer", value: "customer" },
        //{ text: "Country", value: "billcountry" },
        //{ text: "Manufacturer", value: "manufacturer" },
        { text: "Company Rating", value: "description" },
        { text: "Target This Q", value: "salestargetq" },
        //{ text: "Sales (This Q Last Year)", value: "lastsalesq" },
        { text: "Sales (This Q)", value: "salesq" },
        //{ text: "%", value: "lastpercentq" },

        { text: "%", value: "percentq" },
        { text: "Forecast This Q", value: "forecastq" },
        { text: "%", value: "percent2q" },

        { text: "Target (This Year)", value: "salestarget" },
        //{ text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        // { text: "%", value: "lastpercent" },

        { text: "%", value: "percent" },
        { text: "Forecast This Year", value: "forecast" },
        { text: "%", value: "percent2" },
      ];
      if (
        (this.record &&
          ["employee", "country"].includes(this.record.recordtype)) ||
        !this.record
      ) {
        this.headers = this.kamHeaders;
        this.headers.forEach((header) => {
          if (header.value == "salestargetq")
            header.text = `Target (Q${this.quarter})`;
          if (header.value == "salesq")
            header.text = `Sales (Q${this.quarter})`;
          if (header.value == "forecastq")
            header.text = `Forecast (Q${this.quarter})`;

          if (header.value == "salestarget")
            header.text = `Target (${this.year})`;
          if (header.value == "sales") header.text = `Sales (${this.year})`;
          if (header.value == "forecast")
            header.text = `Forecast (${this.year})`;
        });

        this.kamHeaders2.forEach((header) => {
          if (header.value == "salestargetq")
            header.text = `Target (Q${this.quarter})`;
          if (header.value == "salesq")
            header.text = `Sales (Q${this.quarter})`;
          if (header.value == "forecastq")
            header.text = `Forecast (Q${this.quarter})`;

          if (header.value == "salestarget")
            header.text = `Target (${this.year})`;
          if (header.value == "sales") header.text = `Sales (${this.year})`;
          if (header.value == "forecast")
            header.text = `Forecast (${this.year})`;
        });
      }
    },
    salesrep(v) {
      if (v && v._id == "63a002dbb6a7591eeef3ddbd") {
        //jason
        this.totalbonus = 1500;
        this.customerbonus = 50;
        this.format = {
          local: "en-US",
          currency: "GBP",
        };
      } else {
        this.totalbonus = 7000;
        this.customerbonus = 200;
        this.format = {
          local: "pl-PL",
          currency: "PLN",
        };
      }
      if (v && v._id == "5e0dbabb9e33df43f0b3d185") {
        //kosa
        this.totalbonus = 7600;
      }
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      let items = await service(
        "sales",
        {
          action: "getSalesTargets",
          user: this.record || this.salesrep,
          manufacturergroup: this.manufacturergroup,
          country: this.country,
          quarter: this.quarter,
        },
        false,
        false
      );
      this.items = items.items;
      this.customers = Object.values(items.customers);
      this.customersresults = items.customerstargets;
      this.loading = false;
    },
    rowClass(item) {
      if (item.total) {
        return "total";
      }
    },
    quarterBonus(q) {
      let results = this.items.reduce(
        (total, v) => {
          if (v.companyrating && v.companyrating == "1") {
            total.company1 += parseFloat(v[q ? "percentpq" : "percentcq"] || 0);
          }
          if (v.companyrating && v.companyrating == "2") {
            total.company2 += parseFloat(v[q ? "percentpq" : "percentcq"] || 0);
          }
          if (v.companyrating && ["3", "Others"].includes(v.companyrating)) {
            total.company3 += parseFloat(v[q ? "salespq" : "salesq"]);
            total.target3 += parseFloat(
              v[q ? "salestargetpq" : "salestargetq"]
            );
          }
          return total;
        },
        { company1: 0, company2: 0, company3: 0, target3: 0 }
      );

      return {
        "Company Rating 1": results.company1 * this.totalbonus * 0.55,
        "Company Rating 2": results.company2 * this.totalbonus * 0.2,
        "Company Rating 3/Others":
          parseFloat((results.company3 / results.target3).toFixed(2)) *
          this.totalbonus *
          0.25,
      };
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    opensource() {
      let filters = {};
      if (this.record && ["employee"].includes(this.record.recordtype))
        filters.salesrep = this.record._id;
      this.$router.push({
        name: "Queries",
        params: {
          recordtype: "report",
          mode: "view",
          id: "679a36cddd8c1c786642b482",
          filters: filters,
        },
      });
    },
    calcTotal(key) {
      if (this.items.length > 0) {
        if (
          ![
            "lastpercent",
            "percent",
            "percent2",
            "percentq",
            "percent2q",
            "description",
          ].includes(key)
        ) {
          let sum = this.items.reduce((a, b) => {
            if (
              !isNaN(parseFloat(a[key] || a)) &&
              !isNaN(parseFloat(b[key] || b))
            ) {
              return (
                parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0)
              );
            } else return parseFloat(a || 0);
          });

          return sum && !isNaN(parseInt(sum))
            ? new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(sum)
            : "";
        } else {
          if (
            [
              "lastpercent",
              "percent",
              "percent2",
              "percentq",
              "percent2q",
            ].includes(key)
          ) {
            let sum1 = 0;
            let sum2 = 0;
            let val1 = "";
            let val2 = "";
            if (key == "percentq") {
              val1 = "salestargetq";
              val2 = "salesq";
            }
            if (key == "percent") {
              val1 = "salestarget";
              val2 = "sales";
            }
            if (key == "percent2") {
              val1 = "salestarget";
              val2 = "forecast";
            }
            if (key == "percent2q") {
              val1 = "salestargetq";
              val2 = "forecastq";
            }
            if (val1 && val2) {
              sum1 = this.items.reduce((a, b) => {
                if (
                  !isNaN(parseFloat(a[val1] || a)) &&
                  !isNaN(parseFloat(b[val1] || b))
                ) {
                  return (
                    parseFloat(a[val1] || a || 0) +
                    parseFloat(b[val1] || b || 0)
                  );
                } else return parseFloat(a || 0);
              });

              sum2 = this.items.reduce((a, b) => {
                if (
                  !isNaN(parseFloat(a[val2] || a)) &&
                  !isNaN(parseFloat(b[val2] || b))
                ) {
                  return (
                    parseFloat(a[val2] || a || 0) +
                    parseFloat(b[val2] || b || 0)
                  );
                } else return parseFloat(a || 0);
              });
            }
            return parseInt((sum2 / sum1) * 100);
          }
        }
      }
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
<style scoped>
.theme--light.v-data-table
  >>> .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(7) {
  border-right: 5px solid #e1e1e1;
}
.theme--light.v-data-table
  >>> .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(7) {
  border-right: 5px solid #e1e1e1;
}

.theme--light.v-data-table >>> tr.total {
  background: #424242;
  color: white;
}

.theme--light.v-data-table >>> tr.total:hover {
  color: black;
}
</style>