<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Analytics Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Sales Orders Pending Fulfillment</v-tab>
      <v-tab :key="2">Open Orders Status</v-tab>
      <v-tab :key="3">Redarc Open Orders Status</v-tab>
      <v-tab :key="4">Inventory Back Order</v-tab>
      <v-tab :key="5">Orders Pre-Paid Waiting for Payment</v-tab>
      <v-tab :key="6">Statistics</v-tab>
      <v-tab :key="7">Reports</v-tab>
      <v-tab :key="8">Map</v-tab>
      <v-tab :key="9"><v-icon>mdi-close-outline</v-icon></v-tab>
      <!-- <v-tab :key="8">Statistics</v-tab> -->

      <v-tab-item :key="1">
        <v-tabs dark v-model="active2" v-if="active == 0">
          <v-tab :key="1">Per Item</v-tab>
          <v-tab :key="2">Per Order</v-tab>
          <v-tab-item :key="1">
            <orderstofulfill v-if="active2 == 0" />
          </v-tab-item>
          <v-tab-item :key="2">
            <orderstofulfilltotal v-if="active2 == 1" />
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item :key="2">
        <openordersstatus v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <redarcopenordersstatus v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <inventorybackorder v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <v-tabs dark v-model="active2">
          <v-tab :key="1">Warehouse PL</v-tab>
          <v-tab :key="2">Warehouse UK</v-tab>
          <v-tab :key="3">Warehouse NL</v-tab>
          <v-tab-item :key="1">
            <queryresult
              used="widget"
              queryid="615ff967b0749a311ca924b6"
              v-if="active2 == 0"
              :filters="filtersPL"
            />
          </v-tab-item>
          <v-tab-item :key="2">
            <queryresult
              used="widget"
              queryid="615ff967b0749a311ca924b6"
              v-if="active2 == 1"
              :filters="filtersPEDDERSUK"
            />
          </v-tab-item>
          <v-tab-item :key="3">
            <queryresult
              used="widget"
              queryid="615ff967b0749a311ca924b6"
              v-if="active2 == 2"
              :filters="filtersNL"
            />
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item :key="6">
        <statistics v-if="active == 5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <reports v-if="active == 6" />
      </v-tab-item>
      <v-tab-item :key="8">
        <googlemap v-if="active == 7" />
      </v-tab-item>
      <!-- <v-tab-item :key="8">
        <statistics v-if="active==7" />
      </v-tab-item>-->
    </v-tabs>
  </v-card>
</template>

<script>
import orderstofulfill from "../components/tools/orderstofulfill/View";
import openordersstatus from "../components/tools/openordersstatus/View";
import inventorybackorder from "../components/tools/inventorybackorder/View";
import redarcopenordersstatus from "../components/tools/redarcopenordersstatus/View";
import reports from "../components/tools/reports/View";
import statistics from "../components/tools/statistics/ViewNEW";
import googlemap from "../components/tools/map/View";
import orderstofulfilltotal from "../components/tools/orderstofulfillment/View";

/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: 8,
      active2: 0,
      filtersPL: { location: "5e0dbaba9e33df43f0b3a495" },
      filtersUK: { location: "5faa7485371aa739b8a08843" },
      filtersNL: { location: "66e04fcba16b2e1e7d873be8" },
      filtersPEDDERSUK: { location: "61e14c6ae4a35a668517a8fe" },
    };
  },

  components: {
    orderstofulfill,
    openordersstatus,
    inventorybackorder,
    redarcopenordersstatus,
    reports,
    statistics,
    googlemap,
    orderstofulfilltotal,
  },
};
</script>