<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-card tile>
      <record-toolbar :record="record" :form="$refs.form" />
      <v-progress-linear
        :indeterminate="loading"
        height="3"
        class="ma-0"
      ></v-progress-linear>
      <component
        :is="`header-${$route.params.recordtype}`"
        v-if="!loading"
        :record.sync="record"
        @emit="catchEmit"
      />

      <v-snackbar v-model="msg.show" :timeout="6000" top center
        >{{ msg.message }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="msg.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <record-tabs
      v-if="!loading"
      :record="record"
      @emit="catchEmit"
      :activeTab="activeTab"
    />
    <record-toolbar v-if="!loading" :record="record" :form="$refs.form" />
  </v-form>
</template>


<script>
import toolbar from "./toolbar";
import headerEmployee from "./headers/employee";
import headerAddress from "./headers/address";
import headerCustomer from "./headers/customer";
import headerCompany from "./headers/company";
import headerVendor from "./headers/vendor";

import headerInventoryitem from "./headers/inventoryitem";
import headerKititem from "./headers/kititem";
import headerSalesorder from "./headers/salesorder";
import headerInvoice from "./headers/invoice";
import headerBill from "./headers/bill";
import headerPurchaseorder from "./headers/purchaseorder";
import headerFulfillment from "./headers/itemfulfillment";
import headerPrepayment from "./headers/prepayment";
import headerItemreceipt from "./headers/itemreceipt";
import headerCreditmemo from "./headers/creditmemo";
import headerDelivery from "./headers/delivery";
import headerDeliverycheck from "./headers/deliverycheck";
import headerEstimate from "./headers/estimate";
import headerInventoryadjustment from "./headers/inventoryadjustment";
import headerInventorytransfer from "./headers/inventorytransfer";
import headerCustomerpayment from "./headers/customerpayment";
import headerCustomerrefund from "./headers/customerrefund";
import headerreturnauthorization from "./headers/returnauthorization";
import headercampaign from "./headers/campaign";
import headermanufacturergroup from "./headers/manufacturergroup";
import headerreturnrequest from "./headers/returnrequest";
import headercountry from "./headers/country";

import getRecord from "../../common/methods/getRecord";

import tabs from "./tabs";
/* eslint-disable */
export default {
  props: ["collection", "testparam"],
  data() {
    return {
      valid: false,
      rid: this.$route.params.id,
      mode: this.$route.params.mode,
      msg: false,
      loading: true,
      recalc: false,
      record: { _id: "", v: null },
      activeTab: null,
      actions: false,
      save: false,
      edit: false,
    };
  },
  methods: {
    stop_reload(event) {
      if (["edit", "create"].includes(this.$route.params.mode)) {
        event.preventDefault();
        event.returnValue = true;
      }
      return;
    },
    async catchEmit(e) {
      if (e.activeTab) {
        this.activeTab = e.activeTab;
        setTimeout(() => {
          this.$vuetify.goTo(e.section);
        }, 500);
      } else this.record = e;
      // this.record = JSON.parse(JSON.stringify(e));
    },
    async getRecord() {
      this.record.recordtype = this.$route.params.recordtype;

      try {
        this.loading = true;
        if (this.rid) {
          this.record = await getRecord(
            this,
            this.rid,
            this.collection,
            this.$route.params.recordtype
          );
        } else this.record.created = "new";
        //this.record.created = this.record.created != "copy" ? "new" : "copy";

        this.loading = false;
      } catch (error) {
        this.msg = { message: `${error.message}`, show: true };
      }
      //localStorage.setItem('record', JSON.stringify(this.record));
      //this.record = JSON.parse(localStorage.getItem('record'));
    },
  },
  watch: {
    "$route.params": {
      handler: async function (after, before) {
        this.rid = this.$route.params.id;
        await this.getRecord();
      },
      deep: true,
    },
    "record.recordtype": {
      handler: async function (after, before) {
        console.log(after, before);
      },
      deep: true,
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.stop_reload);
    await this.getRecord();
    for (let [key, value] of Object.entries(this.$route.params)) {
      this.$set(this.record, key, value);
    }
    for (let [key, value] of Object.entries(this.$route.query)) {
      this.$set(this.record, key, value);
    }

    // Użyj danych i usuń je z localStorage, jeśli nie są już potrzebne
    const tmp = JSON.parse(localStorage.getItem("data.tmp"));
    if (tmp) {
      for (let [key, value] of Object.entries(tmp)) {
        this.$set(this.record, key, value);
      }
      localStorage.removeItem("data.tmp");
    }

    document.title = `3C ERP - ` + this.record.name;
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.stop_reload);
  },
  components: {
    "header-employee": headerEmployee,
    "header-address": headerAddress,
    "header-customer": headerCustomer,
    "header-company": headerCompany,
    "header-vendor": headerVendor,
    "header-salesorder": headerSalesorder,
    "header-purchaseorder": headerPurchaseorder,
    "header-inventoryitem": headerInventoryitem,
    "header-kititem": headerKititem,
    "header-invoice": headerInvoice,
    "header-noinvoice": headerInvoice,
    "header-bill": headerBill,
    "header-itemfulfillment": headerFulfillment,
    "header-prepayment": headerPrepayment,
    "header-itemreceipt": headerItemreceipt,
    "header-creditmemo": headerCreditmemo,
    "header-delivery": headerDelivery,
    "header-deliverycheck": headerDeliverycheck,
    "header-estimate": headerEstimate,
    "header-inventoryadjustment": headerInventoryadjustment,
    "header-inventorytransfer": headerInventorytransfer,
    "header-customerpayment": headerCustomerpayment,
    "header-customerrefund": headerCustomerrefund,
    "header-returnauthorization": headerreturnauthorization,
    "header-campaign": headercampaign,
    "header-manufacturergroup": headermanufacturergroup,
    "header-returnrequest": headerreturnrequest,
    "header-country": headercountry,
    "record-tabs": tabs,
    "record-toolbar": toolbar,
  },
};
</script>
<style >
.v-list-item__title,
.v-list-item__subtitle,
.v-list-item__content div.v-list-item__subtitle,
.v-list-item__content div.v-list-item__title {
  user-select: all !important;
}
</style>>