var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formSettings && _vm.formSettings.tool && _vm.record._id)?_c('div',{staticStyle:{"width":"100%","position":"relative"}},[(
        _vm.formSettings.tool.status && _vm.record.status
          ? _vm.formSettings.tool.status.includes(_vm.record.status._id)
          : true && _vm.formSettings.tool.mode
          ? _vm.formSettings.tool.mode.includes(_vm.$route.params.mode)
          : true
      )?_c(_vm.formSettings.tool.tool,{tag:"component",attrs:{"record":_vm.record,"selected":_vm.selected,"id":_vm.formSettings.tool.tool},on:{"update:record":function($event){_vm.record=$event},"update:selected":function($event){_vm.selected=$event},"emit":_vm.catchEmit}}):_vm._e()],1):_vm._e(),(_vm.query && _vm.query.recordtype == 'report')?_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-tabs',{class:_vm.tabs.other && Object.values(_vm.tabs).length == 1 ? 'hidetablist' : '',attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((_vm.tabs),function(location_value,location,index){return _c('v-tab',{key:index,class:location == 'other' ? 'd-none' : ''},[_vm._v(" "+_vm._s(location)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabs),function(location_value,location,index){return _c('v-tab-item',{key:index},[_c('v-tabs',{attrs:{"vertical":!_vm.$vuetify.breakpoint.mobile}},[_vm._l((location_value),function(value,key){return _c('v-tab',{key:key,class:key == 'other' ? 'd-none' : ''},[(value)?_c('v-badge',{attrs:{"tile":"","left":"","inline":"","content":value.length,"value":value.length,"color":"#363636"}},[_vm._v(_vm._s(key)+" ")]):_vm._e()],1)}),_vm._l((location_value),function(value,key){return _c('v-tab-item',{key:key},[_c('v-data-table',{attrs:{"headers":_vm.headers.filter(
                      function (f) { return !f.parentfield && !(_vm.groupby || '').includes(f.value); }
                    ),"items":value,"options":_vm.options,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","footer-props":{ itemsPerPageOptions: _vm.rows },"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"custom-filter":_vm.filter,"search":_vm.search,"custom-sort":_vm.sort,"hide-default-footer":_vm.hidefooter,"show-select":_vm.show,"group-by":_vm.groupby,"custom-group":_vm.customGroup},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                    var group = ref.group;
                    var toggle = ref.toggle;
                    var isOpen = ref.isOpen;
                    var items = ref.items;
return [_c('th',{attrs:{"colspan":_vm.headers.filter(
                          function (f) { return !f.parentfield &&
                            _vm.fields[f.field] &&
                            !['field-currency', 'field-int'].includes(
                              _vm.fields[f.field].setup.type
                            ); }
                        ).length}},[_c('v-btn',{ref:group,refInFor:true,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1),_vm._l((_vm.headers.filter(
                        function (f) { return !f.parentfield &&
                          _vm.fields[f.field] &&
                          ['field-currency', 'field-int'].includes(
                            _vm.fields[f.field].setup.type
                          ); }
                      )),function(col){return _c('th',{key:col.value},[_vm._v(" "+_vm._s(items .reduce(function (t, v) { t += v[col.value] || 0; return t; }, 0) .toFixed( _vm.fields[col.field] && _vm.fields[col.field].setup.type == "field-int" ? 0 : 2 ))+" ")])})]}},{key:"headers",fn:function(props){return [_c('tableheaders',{attrs:{"props":props,"items":_vm.items,"options":_vm.options}})]}},{key:"item",fn:function(props){return [(!props.deleted)?_c('tablerows',{attrs:{"props":props,"expandedCount":_vm.expanded.length,"headers":_vm.headers.filter(
                          function (f) { return !(_vm.groupby || '').includes(f.value); }
                        ),"options":_vm.options,"fields":_vm.fields,"lazy":_vm.lazy}}):_vm._e()]}},{key:"expanded-item",fn:function(props){return [(_vm.options.subrecords)?_c('editrecord',{key:props.item._id,attrs:{"options":_vm.options,"record":props.item,"parent":_vm.record,"showselect":_vm.show,"index":_vm.items
                          .map(function (x) {
                            return x._id;
                          })
                          .indexOf(props.item._id),"columns":_vm.headers},on:{"update:parent":function($event){_vm.record=$event},"emit":function($event){return _vm.catchEmit($event, props)}}}):_vm._e()]}},(
                      _vm.$route.params.mode == 'view' &&
                      ['salesorder', 'purchaseorder'].includes(
                        _vm.$route.params.recordtype
                      )
                    )?{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"position":"relative","text-align":"right"}},[_c('v-col',[(
                            _vm.$route.params.mode == 'view' &&
                            ['transactionlines'].includes(_vm.settings._id) &&
                            ['salesorder', 'purchaseorder'].includes(
                              _vm.$route.params.recordtype
                            )
                          )?_c('v-overflow-btn',{staticClass:"mt-0",staticStyle:{"min-width":"250px"},attrs:{"disabled":!_vm.selected.length,"flat":"","items":_vm.quickemails,"label":"Quick Email/Action","segmented":"","dense":"","menu-props":"{ maxHeight: unset }"}}):_vm._e()],1)],1)]},proxy:true}:null,{key:"footer",fn:function(){return [_c('div',{staticStyle:{"position":"relative","text-align":"right"}},[(_vm.options.subrecords && _vm.options.add)?_c('v-fab-transition',[_c('v-btn',{staticStyle:{"top":"15px"},attrs:{"small":"","absolute":"","top":"","left":"","fab":""},on:{"click":function($event){return _vm.addrow()}}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e(),(
                          _vm.options.subrecords &&
                          _vm.selected.length &&
                          ['edit', 'create'].includes(_vm.$route.params.mode)
                        )?_c('v-fab-transition',[_c('v-btn',{staticStyle:{"left":"80px","top":"15px"},attrs:{"small":"","absolute":"","top":"","left":"","fab":""},on:{"click":function($event){return _vm.deleterows()}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e(),_c('v-expand-transition',[_c('div',[_c('v-container',{attrs:{"fluid":""}},[(
                                _vm.query &&
                                _vm.query.setup.filters &&
                                _vm.query.setup.filters.some(function (f) { return f.show; })
                              )?_c('v-col',[_c('v-row',{staticClass:"pb-6"},[_c('v-subheader',[_vm._v("Filters")])],1),_vm._l((_vm.query.setup.columns.filter(
                                  function (col) { return col.field == 'date'; }
                                )),function(column,index){return _c('div',{key:index},[(column.function)?_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-subheader',[_vm._v(" "+_vm._s(column.name || _vm.fields[column.field].name)+" Settings")])],1),_c('v-col',{staticClass:"col-3 py-1"},[_c('v-select',{attrs:{"items":_vm.dateFunc,"label":"Date Unit","item-text":"name","item-value":"_id","outlined":"","dense":""},on:{"change":function($event){return _vm.dataUnitChange($event)}},model:{value:(column.function),callback:function ($$v) {_vm.$set(column, "function", $$v)},expression:"column.function"}})],1),_c('v-col',{staticClass:"col-3 py-1"},[_c('v-select',{attrs:{"items":_vm.dateSummary,"label":"Date Summary","item-text":"name","item-value":"_id","outlined":"","dense":""},on:{"change":function($event){return _vm.dataUnitChange($event)}},model:{value:(column.summary),callback:function ($$v) {_vm.$set(column, "summary", $$v)},expression:"column.summary"}})],1)],1):_vm._e()],1)}),_vm._l((_vm.query.setup.filters),function(filter,index){return _c('div',{key:index},[(
                                    ['$or', '$and'].includes(filter.operator)
                                  )?_c('v-row',[_c('v-col',_vm._l((filter.value),function(filter,index){return _c('div',{key:index},[(filter.show)?_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-subheader',[_vm._v(_vm._s(filter.name || (_vm.fields[filter.field] ? _vm.fields[filter.field].name : "")))])],1),_c('v-col',{staticClass:"col-3 py-1"},[_c('v-select',{attrs:{"items":_vm.operators,"label":"Operator","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(filter.operator),callback:function ($$v) {_vm.$set(filter, "operator", $$v)},expression:"filter.operator"}})],1),_c('v-col',{staticClass:"py-1"},[(filter.fieldSettings)?_c(_vm.fields[filter.field].setup.type,{tag:"v-component",attrs:{"record":filter,"value":_vm.filterDecode(filter),"fieldSettings":filter.fieldSettings,"formSettings":filter},on:{"emit":function($event){return _vm.catchEmitFilter($event, filter)}}}):_vm._e()],1)],1):_vm._e()],1)}),0)],1):_vm._e(),(
                                    filter.show &&
                                    !['$or', '$and'].includes(filter.operator)
                                  )?_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-subheader',[_vm._v(_vm._s(filter.name || (_vm.fields[filter.field] ? _vm.fields[filter.field].name : "")))])],1),_c('v-col',{staticClass:"col-3 py-1"},[_c('v-select',{attrs:{"items":_vm.operators,"label":"Operator","item-text":"name","item-value":"_id","outlined":"","dense":""},model:{value:(filter.operator),callback:function ($$v) {_vm.$set(filter, "operator", $$v)},expression:"filter.operator"}})],1),_c('v-col',{staticClass:"py-1"},[(filter.fieldSettings)?_c(_vm.fields[filter.field].setup.type,{tag:"v-component",attrs:{"record":filter,"value":_vm.filterDecode(filter),"fieldSettings":filter.fieldSettings,"formSettings":filter},on:{"emit":function($event){return _vm.catchEmitFilter($event, filter)}}}):_vm._e()],1)],1):_vm._e()],1)}),_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.action()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-download")]),_vm._v(" Generate/Refresh ")],1)],2):_vm._e(),(
                                _vm.selected.length &&
                                ['deliverycheck'].includes(_vm.record.recordtype)
                              )?_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.printLabel()}}},[_vm._v("Print Selected Label")])],1):_vm._e(),(
                                _vm.selected.length &&
                                ['purchaseorder'].includes(
                                  _vm.record.recordtype
                                ) &&
                                ['edit'].includes(_vm.record.mode)
                              )?_c('v-row',[_c('v-spacer'),_c('v-spacer'),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 mb-2",attrs:{"tile":"","color":"primary"}},on),[_vm._v("Set ETD for selected")])]}}],null,true),model:{value:(_vm.etdmenu),callback:function ($$v) {_vm.etdmenu=$$v},expression:"etdmenu"}},[_c('v-date-picker',{on:{"change":_vm.setETD},model:{value:(_vm.etd),callback:function ($$v) {_vm.etd=$$v},expression:"etd"}})],1)],1)],1):_vm._e(),(
                                _vm.selected.length &&
                                ['inventorylocations'].includes(_vm.settings._id)
                              )?_c('v-col',[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.binTransfer()}}},[_vm._v("Bin transfer")])],1):_vm._e(),_c('v-row',[_c('v-spacer'),_c('v-col',[_c('exportdata',{attrs:{"items":value,"headers":_vm.headers,"name":_vm.record
                                      ? _vm.record.name
                                      : _vm.query
                                      ? _vm.query.name
                                      : null,"customexport":_vm.customexport}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)])],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)}),1)],2)],1)],1),_c('v-dialog',{attrs:{"width":"600px","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.printDialog),callback:function ($$v) {_vm.printDialog=$$v},expression:"printDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Print Label")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm.printDialog = false}}},[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v(" mdi-cancel ")])],1)],1),_c('v-list',{staticClass:"mt-4",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.printers,"item-text":"name","item-value":"id","placeholder":"","clearable":"","label":"Printer","filled":"","dense":""},model:{value:(_vm.printer),callback:function ($$v) {_vm.printer=$$v},expression:"printer"}})],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mb-2",attrs:{"tile":"","disabled":!_vm.printer,"x-large":"","color":"primary","block":"","depressed":""},on:{"click":function($event){return _vm.printLabel()}}},[_vm._v(" Print")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.msg.type,"timeout":6000,"top":"","absolute":"","right":""},model:{value:(_vm.msg.show),callback:function ($$v) {_vm.$set(_vm.msg, "show", $$v)},expression:"msg.show"}},[_vm._v(_vm._s(_vm.msg.message))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }