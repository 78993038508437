 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="1">Warehouse PL</v-tab>
          <v-tab :key="1">Warehouse NL</v-tab>
          <v-tab :key="2">Warehouse UK</v-tab>
          <v-tab :key="3">DROP SHIP</v-tab>
          <v-tab-item :key="1">
            <v-data-table
              :headers="perItem ? headersPerItem : headers"
              :items="
                itemsPL
                  .filter(
                    (item) => !onlybelowpsl || (onlybelowpsl && item.belowpsl)
                  )
                  .filter(
                    (item) =>
                      !onlybackorderquantity ||
                      (onlybackorderquantity &&
                        item.destinationquantitybackorder)
                  )
                  .filter(
                    (item) => (vendor && item.customer._id == vendor) || !vendor
                  )
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.destinationquantitybackorder="{ item }">
                <v-chip
                  label
                  :color="item.destinationquantitybackorder ? 'red' : 'none'"
                  dark
                  >{{ item.destinationquantitybackorder }}</v-chip
                >
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.difshipmethod">
                    <span
                      >Shipping Method different than on original SO! ({{
                        item.originalshippingmethod
                      }})</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.shippingmethod }}
                </span>
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="perItem ? headersPerItem : headers"
                        :items="itemsPL"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="perItem"
                        label="Show Items"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        outlined
                        dense
                        clearable
                        :loading="loading"
                      ></v-select>
                    </v-col>
                    <v-col v-if="perItem">
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="onlybackorderquantity"
                        label="Only BO"
                        hide-details
                      ></v-switch>
                      <v-switch
                        class="mt-0"
                        v-model="onlybelowpsl"
                        label="Only Below PSL (1/2)"
                        hide-details
                      ></v-switch>
                    </v-col>

                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack ({{ selectedQty }})</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-data-table
              :headers="perItem ? headersPerItem : headers"
              :items="
                itemsNL
                  .filter(
                    (item) => !onlybelowpsl || (onlybelowpsl && item.belowpsl)
                  )
                  .filter(
                    (item) =>
                      !onlybackorderquantity ||
                      (onlybackorderquantity &&
                        item.destinationquantitybackorder)
                  )
                  .filter(
                    (item) => (vendor && item.customer._id == vendor) || !vendor
                  )
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.destinationquantitybackorder="{ item }">
                <v-chip
                  label
                  :color="item.destinationquantitybackorder ? 'red' : 'none'"
                  dark
                  >{{ item.destinationquantitybackorder }}</v-chip
                >
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.difshipmethod">
                    <span
                      >Shipping Method different than on original SO! ({{
                        item.originalshippingmethod
                      }})</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.shippingmethod }}
                </span>
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="perItem ? headersPerItem : headers"
                        :items="itemsNL"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="perItem"
                        label="Show Items"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        outlined
                        dense
                        clearable
                        :loading="loading"
                      ></v-select>
                    </v-col>
                    <v-col v-if="perItem">
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="onlybackorderquantity"
                        label="Only BO"
                        hide-details
                      ></v-switch>
                      <v-switch
                        class="mt-0"
                        v-model="onlybelowpsl"
                        label="Only Below PSL (1/2)"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack ({{ selectedQty }})</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-data-table
              :headers="perItem ? headersPerItem : headers"
              :items="
                itemsUK
                  .filter(
                    (item) => !onlybelowpsl || (onlybelowpsl && item.belowpsl)
                  )
                  .filter(
                    (item) =>
                      !onlybackorderquantity ||
                      (onlybackorderquantity &&
                        item.destinationquantitybackorder)
                  )
                  .filter(
                    (item) => (vendor && item.customer._id == vendor) || !vendor
                  )
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.destinationquantitybackorder="{ item }">
                <v-chip
                  label
                  :color="item.destinationquantitybackorder ? 'red' : 'none'"
                  dark
                  >{{ item.destinationquantitybackorder }}</v-chip
                >
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="perItem ? headersPerItem : headers"
                        :items="itemsUK"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="perItem"
                        label="Show Items"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        outlined
                        dense
                        clearable
                        :loading="loading"
                      ></v-select>
                    </v-col>
                    <v-col v-if="perItem">
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="onlybackorderquantity"
                        label="Only BO"
                        hide-details
                      ></v-switch>
                      <v-switch
                        class="mt-0"
                        v-model="onlybelowpsl"
                        label="Only Below PSL (1/2)"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack ({{ selectedQty }})</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-data-table
              :headers="perItem ? headersPerItem : headers"
              :items="
                itemsDROPSHIP
                  .filter(
                    (item) => !onlybelowpsl || (onlybelowpsl && item.belowpsl)
                  )
                  .filter(
                    (item) =>
                      !onlybackorderquantity ||
                      (onlybackorderquantity &&
                        item.destinationquantitybackorder)
                  )
                  .filter(
                    (item) => (vendor && item.customer._id == vendor) || !vendor
                  )
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.destinationquantitybackorder="{ item }">
                <v-chip
                  label
                  :color="item.destinationquantitybackorder ? 'red' : 'none'"
                  dark
                  >{{ item.destinationquantitybackorder }}</v-chip
                >
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData
                        :headers="perItem ? headersPerItem : headers"
                        :items="itemsDROPSHIP"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="perItem"
                        label="Show Items"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="vendors"
                        v-model="vendor"
                        label="Vendor"
                        item-text="displayname"
                        item-value="_id"
                        outlined
                        dense
                        clearable
                        :loading="loading"
                      ></v-select>
                    </v-col>
                    <v-col v-if="perItem">
                      <v-switch
                        class="mt-0 mb-0"
                        v-model="onlybackorderquantity"
                        label="Only BO"
                        hide-details
                      ></v-switch>
                      <v-switch
                        class="mt-0"
                        v-model="onlybelowpsl"
                        label="Only Below PSL (1/2)"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack ({{ selectedQty }})</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      perItem: false,
      loading: false,
      onlybackorderquantity: false,
      onlybelowpsl: false,
      headers: [
        { text: "To", value: "destination" },
        { text: "Date", value: "date" },
        { text: "Stock Request", value: "transaction" },
        { text: "Customer/Vendor", value: "customer" },
        { text: "Created From", value: "createdfrom" },
        { text: "Stock Order", value: "stockorder" },
        //{ text: "Shipping Address", value: "shippingaddress" },
        //{ text: "Shipping Method", value: "shippingmethod" },
        { text: "Quantity", value: "quantity" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "OnOrder", value: "quantityonorder" },
        //{ text: "BO", value: "quantitybackordermemo" },
        { text: "PO/MM", value: "specialorders" },
        //{ text: "Internal Comments", value: "memo" },
        { text: "ETA", value: "eta" },
        { text: "Warehouse Comments", value: "warehousememo" },
        { text: "Tracking", value: "trackingnumber" },
        //{ text: "Terms", value: "terms" },
      ],
      headersPerItem: [
        { text: "To", value: "destination" },
        { text: "Date", value: "date" },
        { text: "Item", value: "item" },
        { text: "Stock Request", value: "transaction" },
        { text: "Customer/Vendor", value: "customer" },
        { text: "Created From", value: "createdfrom" },
        { text: "Stock Order", value: "stockorder" },
        //{ text: "Shipping Address", value: "shippingaddress" },
        //{ text: "Shipping Method", value: "shippingmethod" },
        { text: "Quantity", value: "quantity" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "OnOrder", value: "quantityonorder" },
        { text: "Dest. Available", value: "destinationquantityavailable" },
        { text: "Dest. BO", value: "destinationquantitybackorder" },
        { text: "Dest. PSL", value: "destinationpsl" },
        { text: "PO/MM", value: "specialorders" },
        //{ text: "Internal Comments", value: "memo" },
        { text: "ETA", value: "eta" },
        { text: "Warehouse Comments", value: "warehousememo" },
        { text: "Tracking", value: "trackingnumber" },
        //{ text: "Terms", value: "terms" },
      ],
      items: [],
      itemsPL: [],
      itemsNL: [],
      itemsUK: [],
      vendors: [],
      vendor: null,
      //itemsPEDDERSUK: [],
      itemsDROPSHIP: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      customer: null,
      customers: [],
      message: "",

      shippingcost: 0,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.items.forEach((line) => {
      line.customer.displayname =
        line.customer.displayname || line.customer.name;
      this.customers.push(line.customer);
    });
    this.loading = false;
  },
  watch: {
    // selected() {
    //   this.shippingcost += select.transaction.shippingcost || 0;
    // },
    async perItem(v) {
      this.selected = [];
      if (!v) {
        this.onlybelowpsl = false;
        this.onlybackorderquantity = false;
      }
      this.loading = true;
      await this.getData();
      this.loading = false;
    },
    onlybelowpsl(v) {
      this.selected = [];
      if (v) this.onlybackorderquantity = false;
    },
    onlybackorderquantity(v) {
      this.selected = [];
      if (v) this.onlybelowpsl = false;
    },
  },
  computed: {
    selectedQty() {
      let total = this.selected.reduce(
        (total, v) => {
          if (this.onlybackorderquantity) {
            total.qty += Math.min(
              (v.quantitycommitted || 0) - (v.quantitytopack || 0),
              Math.max(
                v.destinationquantitybackorder - (v.quantitytopack || 0),
                0
              )
            );
            total.weight +=
              Math.min(
                (v.quantitycommitted || 0) - (v.quantitytopack || 0),
                Math.max(
                  v.destinationquantitybackorder - (v.quantitytopack || 0),
                  0
                )
              ) * (v.weight || 0);
          }
          if (this.onlybelowpsl) {
            if (
              !v.destinationpsl ||
              (v.destinationpsl || 0) / 2 >
                (v.destinationquantityavailable || 0)
            ) {
              total.qty += (v.quantitycommitted || 0) - (v.quantitytopack || 0);
              total.weight +=
                ((v.quantitycommitted || 0) - (v.quantitytopack || 0)) *
                (v.weight || 0);
            }
          }
          if (!this.onlybackorderquantity && !this.onlybelowpsl) {
            total.qty += (v.quantitycommitted || 0) - (v.quantitytopack || 0);
            total.weight +=
              ((v.quantitycommitted || 0) - (v.quantitytopack || 0)) *
              (v.weight || 0);
          }
          return total;
        },
        { qty: 0, weight: 0 }
      );

      return `Qty: ${total.qty}, Weight: ${total.weight.toFixed(2)}`;
    },
  },
  methods: {
    async getData() {
      this.selected = [];
      this.items = [];
      this.itemsPL = [];
      this.itemsNL = [];
      this.itemsUK = [];
      this.itemsDROPSHIP = [];
      this.vendors = [];
      let result = await service(
        "orders",
        {
          action: "getOpenStockRequest",
          perItem: this.perItem,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
      });
      result.items.forEach((item) => {
        this.vendors.push(item.customer);
        if (
          [
            "5e0dbaba9e33df43f0b3a495",
            "5e395c6024f636543c9cf62b",
            "6188f964a2a404c7408e7bce",
            "5e395a5c24f636543c9cf5ac",
            "627cee8a0f148d1cb7e46aa2",
          ].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsPL.push(item);
        if (
          ["66e04fcba16b2e1e7d873be8"].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsNL.push(item);
        if (
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          ) &&
          !item.customerdropship
        )
          this.itemsUK.push(item);
        if (
          [
            "5e0dbaba9e33df43f0b3a496",
            "5faa7485371aa739b8a08843",
            "5fd370869659a6154824058e",
            "605db48d8905a260ada139b0",
          ].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsUK.push(item);

        if (item.customerdropship) this.itemsDROPSHIP.push(item);
      });
      //console.log(this.itemsPL);
      //this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    action() {
      let location = null;
      //let customer = null;
      let shippingaddress = null;
      let recordtype = null;
      let error = false;
      let createdfrom = [];
      let filters = [];
      this.selected.forEach((select) => {
        if (select.quantitycommitted) {
          if (shippingaddress && select.shippingaddress != shippingaddress) {
            alert("You cannot select more shipping address");
            error = true;
          }
          shippingaddress = select.shippingaddress;
          if (location && select.location != location) {
            alert("You cannot select more locations");
            error = true;
          }
          location = select.location;
          if (recordtype && select.recordtype != recordtype) {
            alert("You cannot select different record type");
            error = true;
          }
          recordtype = select.recordtype;
          // if (customer && select.customer._id != customer) {
          //   alert("You cannot select more Customer");
          //   error = true;
          // }
          // customer = select.customer._id;

          if (this.perItem) {
            if (!createdfrom.includes(select.transaction._id)) {
              createdfrom.push(select.transaction._id);
            }
            filters.push(select._id);
          } else {
            if (!createdfrom.includes(select._id)) createdfrom.push(select._id);
          }
        }
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype:
              recordtype == "salesorder"
                ? "itemfulfillment"
                : "inventorytransfer",
            mode: "create",
            createdfrom: createdfrom,
            location: location,
            message: this.message,
            customer: this.customer,
            packinginstructions: this.packinginstruction,
            shippingmethod: this.shippingmethod,
            codamount: this.codamount,
            onlybackorderquantity: this.onlybackorderquantity,
            onlybelowpsl: this.onlybelowpsl,
            filters: filters,
          },
        });
    },
  },
  components: {
    exportData,
  },
};
</script>