var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$store.state.user &&
    !['role_warehouse_uk'].includes(_vm.$store.state.user.role._id) &&
    !(
      _vm.$route.params.recordtype == 'employee' &&
      ['role_kam', 'role_kam_uk'].includes(_vm.$store.state.user.role._id)
    )
  )?_c('div',[(!_vm.$route.params.mode)?_c('queryresult',{attrs:{"queryid":_vm.$route.params.recordtype}}):_c('record',{attrs:{"collection":"users"}})],1):_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Access Denied")])}
var staticRenderFns = []

export { render, staticRenderFns }