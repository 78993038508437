<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom v-if="record.kam && $route.params.mode == 'view'">
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card flat>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mdi-account-card-details</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Role/Job TItle</v-list-item-subtitle>
                    <v-list-item-title>{{ record.jobtitle }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mail</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Primary Email:</v-list-item-subtitle>
                    <v-list-item-title>{{ record.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">phone</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Contact Phone:</v-list-item-subtitle>
                    <v-list-item-title>{{ record.phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mdi-map</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Regions:</v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        this.countries.map((c) => c.name).join(", ")
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <vuevectormap
              v-if="selectedRegions.length"
              class="ma-2"
              map="world"
              ref="map"
              width="750px"
              height="400px"
              :regionsSelectable="true"
              :regionsSelectableOne="true"
              :selectedRegions="selectedRegions"
              :focusOn="{ region: selectedRegions[0], animate: true }"
            >
            </vuevectormap>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>

    <v-tab-item v-if="record.kam && $route.params.mode == 'view'">
      <statistics :kam="record._id" mode="sales" :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-inbox :record="record" :customer="record._id" filter="user" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import statistics from "../../../components/tools/statistics/ViewNEW.vue";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
      instructions: {},
      selectedRegions: [],
      countries: [],
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {},
      deep: true,
    },
  },
  async created() {
    this.countries = await service("countries", {
      action: "find",
      data: [{ salesrep: this.record._id }],
    }).then((response) => {
      this.selectedRegions = response.map((v) => v._id);
      return response;
    });
  },
  computed: {},
  components: {
    statistics,
  },
};
</script>
<style scoped>
.notes {
  font-size: 14px;
}
</style>
