 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="advanced ? headersadvanced : headers"
          :items="filtereditems.length ? filtereditems : items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
        >
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>

          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData
                    :headers="advanced ? headersadvanced : headers"
                    :items="filtereditems.length ? filtereditems : items"
                  />
                </v-col>
                <v-col>
                  <v-menu
                    v-model="menufrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date from"
                        placeholder
                        v-model="datefrom"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                        class="mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datefrom"
                      :first-day-of-week="1"
                      @change="update = true"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date to"
                        placeholder
                        v-model="dateto"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        clearable
                        class="mt-2 mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateto"
                      :first-day-of-week="1"
                      @change="update = true"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col>
                  <v-select
                    :items="companies"
                    v-model="company"
                    label="Company"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    hide-details
                    class="mb-2"
                    dense
                    @change="update = true"
                  ></v-select>
                  <v-select
                    dense
                    v-model="customer"
                    :items="customers"
                    label="Vendors"
                    outlined
                    item-text="name"
                    item-value="_id"
                    hide-details
                    multiple
                    clearable
                  ></v-select>

                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2"
                    :disabled="!update"
                    :loading="loading"
                    >Refresh</v-btn
                  >
                </v-col>

                <v-col>
                  <v-switch
                    class="mt-0 mb-5"
                    v-model="excludefleetorder"
                    hide-details
                    label="Exclude Fleet Orders"
                    @change="update = true"
                  ></v-switch>
                  <v-select
                    :disabled="!excludefleetorder"
                    :items="companies"
                    v-model="excludefleetordercompany"
                    label="Exclude Fleet (Company)"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    hide-details
                    class="mb-2"
                    dense
                    @change="update = true"
                  ></v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-switch
                    class="mt-0 mb-1"
                    hide-details
                    v-model="currency"
                    label="Vendor Currency"
                  ></v-switch>

                  <v-switch
                    class="mt-0 mb-1"
                    hide-details
                    v-model="advanced"
                    label="Advanced"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Vendor", value: "customer", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Total PO Value", value: "amount", sortable: false },
        { text: "Last Month PO Value", value: "lastamount", sortable: false },
        { text: "Monthly Purchase Target", value: "target", sortable: false },
        { text: "% Target", value: "percenttarget", sortable: false },
        { text: "Current Stock Value", value: "totalvalue", sortable: false },
        { text: "Exchange Rate", value: "exchangerate", sortable: false },
      ],
      headersadvanced: [
        { text: "Vendor", value: "customer", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Total PO Value", value: "amount", sortable: false },
        { text: "Total PO Qty", value: "quantity", sortable: false },
        { text: "Last Month PO Value", value: "lastamount", sortable: false },
        { text: "Last Month PO Qty.", value: "lastquantity", sortable: false },
        { text: "Total BO Value", value: "boamount", sortable: false },
        { text: "Total BO Qty.", value: "boquantity", sortable: false },
        { text: "Total Stock Value", value: "stockamount", sortable: false },
        { text: "Total Stock Qty.", value: "stockquantity", sortable: false },
        { text: "Monthly Purchase Target", value: "target", sortable: false },
        { text: "% Target", value: "percenttarget", sortable: false },
        { text: "Current Stock Value", value: "totalvalue", sortable: false },
        { text: "Exchange Rate", value: "exchangerate", sortable: false },
      ],
      datefrom: new Date(new Date().getFullYear(), 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: null,
      items: [],
      rows: [100],
      search: "",
      show: false,
      currency: true,
      advanced: false,
      excludefleetorder: true,
      excludefleetordercompany: ["ozpartsuklimited"],
      customers: [],
      companies: [],
      company: [],
      customer: [
        // "5e14abec0398a160b00f5631",
        // "5e14abec0398a160b00f5641",
        // "5e14abf00398a160b00f5697",
        // "5e14abfb0398a160b00f57c0",
        // "5e14ac000398a160b00f5855",
        // "5e14ac010398a160b00f5875",
        // "5e14ac000398a160b00f5866",
        // "5e14abee0398a160b00f566a",
        // "5e6f33b839d811070f6cca15",
      ],
      filtereditems: [],
    };
  },
  async created() {
    this.companies = await service("users", {
      action: "find",
      data: [{ recordtype: "company" }],
    });

    await this.getData("created");
  },
  watch: {
    async currency(value) {
      this.update = true;
    },
    async customer(value) {
      this.filtereditems = this.items.filter((item) => {
        return this.customer.includes(item.customer._id);
      });
      //this.update = true;
      //await this.getData();
    },
  },
  methods: {
    async getData(init) {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getPurchaseReports",
          currency: this.currency,
          datefrom: this.datefrom,
          dateto: this.dateto,
          excludefleetorder: this.excludefleetorder,
          excludefleetordercompany: this.excludefleetordercompany,
          company: this.company,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        if (init == "created") {
          this.customers.push(item.customer);
        }
      });

      this.items = result.items;
      this.filtereditems = this.items.filter((item) => {
        return this.customer.includes(item.customer._id);
      });
      this.update = false;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    exportData,
  },
};
</script>