<template>
  <v-container class="mb-12">
    <v-row>
      <v-col sm="12">
        <v-text-field
          v-model="settings.name"
          label="Name"
          outlined
          required
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-select
          :items="recordtypes"
          v-model="recordtype"
          label="Record Type"
          item-text="name"
          item-value="recordtype"
          outlined
          required
          return-object
          :rules="[rules.required]"
        ></v-select>
      </v-col>
      <v-col sm="6">
        <v-select
          :items="importModes"
          v-model="settings.importMode"
          label="Import Mode"
          item-text="name"
          item-value="value"
          outlined
          required
          :rules="[rules.required]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-select
          :items="separators"
          v-model="settings.separator"
          label="Separator"
          item-text="name"
          item-value="value"
          outlined
          required
          :rules="[rules.required]"
          @change="file = null"
        ></v-select>
      </v-col>
      <v-col sm="6">
        <v-file-input
          label="File input"
          v-model="file"
          accept=".csv"
          @change="loadCSV($event)"
          outlined
          required
          :rules="[rules.importfile]"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../api/index";
export default {
  props: ["settings"],
  data() {
    return {
      file: [],
      csv: "",
      recordtype: {},
      parse_csv: [],
      separators: [
        { value: ";", name: "Samicolon" },
        { value: ",", name: "Comma" },
      ],
      recordtypes: [
        {
          recordtype: "bintransfer",
          name: "Bin Transfer",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "itemreceipt",
          name: "Item Receipt",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "inventoryadjustment",
          name: "Inventory Adjustment",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "inventorytransfer",
          name: "Inventory Transfer",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "itemfulfillment",
          name: "Item Fulfillment",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "returnauthorization",
          name: "Return Authorization",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "invoice",
          name: "Invoice",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "estimate",
          name: "Estimate",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "salesorder",
          name: "Sales Order",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "purchaseorder",
          name: "Purchase Order",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "creditmemo",
          name: "Credit Memo",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "customerpayment",
          name: "Customer Payment",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "bill",
          name: "Bill",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "delivery",
          name: "Delivery",
          collection: "deliveries",
          key: "name",
        },
        {
          recordtype: "customer",
          name: "Customer",
          collection: "users",
          key: "name",
        },
        {
          recordtype: "employee",
          name: "Employee",
          collection: "users",
          key: "name",
        },
        {
          recordtype: "vendor",
          name: "Vendor",
          collection: "users",
          key: "name",
        },
        {
          recordtype: "inventoryitem",
          name: "Inventory Item",
          collection: "items",
          key: "name",
        },
        {
          recordtype: "kititem",
          name: "Kit Item",
          collection: "items",
          key: "name",
        },
        {
          recordtype: "catalogueitem",
          name: "Catalogue Item",
          collection: "catalogueitems",
          key: "name",
        },
        {
          recordtype: "shippingitem",
          name: "Shipping Item",
          collection: "items",
          key: "name",
        },
        {
          recordtype: "stockcheck",
          name: "Stock Check",
          collection: "transactions",
          key: "name",
        },
        {
          recordtype: "transactionrequest",
          name: "ETA Request",
          collection: "transactions_requests",
          key: "name",
        },
        {
          recordtype: "outofstockmessage",
          name: "Out Of Stock Message",
          collection: "lists",
          key: "name",
        },
      ],
      importModes: [
        { value: "update", name: "Update" },
        { value: "add", name: "Add" },
      ],
      rules: {
        required: (v) => !!v || "Field is required",
        importfile: (v) => !!v.name || "Field is required",
      },
    };
  },
  watch: {
    recordtype(value) {
      this.settings.recordtype = value.recordtype;
      this.settings.collection = value.collection;
      this.settings.key = value.key;
    },
  },
  created() {
    this.recordtypes.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  },
  methods: {
    loadCSV(e) {
      var vm = this;
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(vm.file);
        // Handle errors load
        reader.onload = function (event) {
          var csv = event.target.result;
          vm.settings.csv = csv;
          let json = vm.csvToJson(csv, vm.settings.separator);
          console.log(json);
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert("FileReader are not supported in this browser.");
      }
    },
    csvToJson(str, delimiter = ",", quotechar = '"', headerList) {
      let vm = this;
      const cutlast = (_, i, a) => i < a.length - 1;
      // const regex = /(?:[\t ]?)+("+)?(.*?)\1(?:[\t ]?)+(?:,|$)/gm; // no variable chars
      const regex = new RegExp(
        `(?:[\\t ]?)+(${quotechar}+)?(.*?)\\1(?:[\\t ]?)+(?:${delimiter}|$)`,
        "gm"
      );
      const lines = str.split("\n");
      const headers = [...lines.splice(0, 1)[0].matchAll(regex)].filter(
        cutlast
      );
      const list = [];

      for (const line of lines) {
        const val = {};
        for (const [i, m] of [...line.matchAll(regex)]
          .filter(cutlast)
          .entries()) {
          // Attempt to convert to Number if possible, also use null if blank
          if (headers[i][2]) {
            vm.settings.csvheaders.push(headers[i][2]);
            m[2] = m[2].includes("|") ? m[2].split("|") : m[2];
            val[headers[i][2]] = m[2];
          }
        }
        list.push(val);
      }

      return list;
    },
    csvJSON(csv) {
      let vm = this;
      let lines = csv.split("\n");
      let json = [];
      let result = [];
      let matchSeparator = null;
      if (vm.settings.separator == ";") matchSeparator = /;/g;
      if (vm.settings.separator == ",") matchSeparator = /,/g;

      json = lines.map((line) => {
        // line = line.replace(/"[^"]+"/g, function(match) {
        //   return match.replace(matchSeparator, "separ@tor");
        // });
        //console.log(line);
        let row = line.split(vm.settings.separator).map((val) => {
          val = val.replace(/separ@tor/g, vm.settings.separator);
          if (val[0] == '"' && val[val.length - 1] == '"') {
            val = val.slice(1, -1);
          }
          //val = val.replace(/^"|"$/g, "");
          val = val.trim();
          val = val.includes("|") ? val.split("|") : val;
          return val;
        });
        return row;
      });

      vm.settings.csvheaders = json[0];
      json.map(function (line, indexLine) {
        if (indexLine < 1) return; // Jump header line

        let obj = {};

        vm.settings.csvheaders.map(function (header, indexHeader) {
          obj[header] = line[indexHeader];
        });

        result.push(obj);
      });

      return result; // JavaScript object
    },
  },
};
</script>