<template>
  <v-tabs vertical dark v-model="active">
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Open Orders</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-checks</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Deliveries</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>local_shipping</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tabs-items v-model="active" touchless>
      <v-tab-item>
        <v-container fluid class="py-0">
          <v-alert v-if="record.hidden" type="info" dense>Hidden Item </v-alert>
          <v-alert v-if="nla" type="error" dense icon="mdi-alert-circle">
            No Longer Available
          </v-alert>
          <v-alert v-if="stockclearance" type="info" dense>
            Stock Clearance
          </v-alert>
          <v-alert
            v-if="duplicatebarcode"
            dense
            icon="mdi-barcode-off"
            type="error"
          >
            Duplicate Barcode
          </v-alert>
          <v-alert v-if="newitem" dense type="warning" icon="mdi-new-box">
            New Item
          </v-alert>
          <v-row row wrap>
            <v-col>
              <v-subheader>Main</v-subheader>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-title>
                          {{ record.name }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ record.description }}
                        </v-list-item-subtitle>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Barcode:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ record.barcode }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ record.weight }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Manufacturer:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.manufacturer }} ({{
                            record.manufacturergroup
                              ? record.manufacturergroup.name
                              : ""
                          }})
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Manufacturer Stock:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.manufacturerstock }}
                          {{
                            record.manufacturerstock2
                              ? `(${
                                  record.manufacturergroup &&
                                  record.manufacturergroup.name == "Turbosmart"
                                    ? "TS EU: "
                                    : ""
                                }${record.manufacturerstock2})`
                              : ""
                          }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Last Update:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{
                            record.manufacturerstockdate
                              ? new Date(record.manufacturerstockdate)
                                  .toISOString()
                                  .substr(0, 10)
                              : ""
                          }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Price Group:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.pricegroup ? record.pricegroup.name : "" }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="replaces.length"></v-divider>
                <v-list-item v-if="replaces.length">
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Replaces:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ replaces.map((i) => i.name).join(", ") }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>

            <v-col style="max-width: 50vw">
              <v-carousel
                :continuous="false"
                hide-delimiter-background
                height="400"
              >
                <v-carousel-item
                  v-for="(item, i) in photosrc"
                  :key="i"
                  :src="item.url"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <div
              style="display: contents"
              v-for="inventory of record['inventorydetails'].filter(
                (inventory) => {
                  return (
                    !inventory.location.hidden &&
                    (this.locationsFilter.includes(inventory.location._id) ||
                      this.locationsFilter.includes(
                        inventory.location.location
                      ) ||
                      !this.locationsFilter.length)
                  );
                }
              )"
              :key="inventory._id"
            >
              <v-col>
                <v-subheader
                  >{{ inventory.location.name }}
                  {{
                    inventory.stockcategory
                      ? "(" + inventory.stockcategory.name + ")"
                      : ""
                  }}</v-subheader
                >
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col class="py-0">
                          <v-list-item-subtitle
                            >Available :</v-list-item-subtitle
                          >

                          <v-list-item-title>
                            {{ inventory.quantityavailable || 0 }}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="py-0">
                          <v-list-item-subtitle>On Hand:</v-list-item-subtitle>

                          <v-list-item-title>
                            {{ inventory.quantityonhand || 0 }}
                          </v-list-item-title>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="py-0">
                          <v-list-item-subtitle>BO:</v-list-item-subtitle>

                          <v-list-item-title>
                            {{
                              (inventory.quantitybackorder || 0) +
                              (inventory.quantityspecialbackorder || 0)
                            }}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="py-0">
                          <v-list-item-subtitle>On Order:</v-list-item-subtitle>

                          <v-list-item-title>
                            {{
                              (inventory.quantityonorder || 0) +
                              (inventory.quantityonspecialorder || 0)
                            }}
                          </v-list-item-title>
                        </v-col>
                        <v-col
                          class="py-0"
                          v-if="deliveries.onorder[inventory.location._id]"
                        >
                          <v-list-item>
                            <v-list-item-content class="py-0">
                              <v-list-item-subtitle
                                v-for="(del, index) of deliveries.onorder[
                                  inventory.location._id
                                ] || []"
                                :key="index"
                              >
                                <small
                                  >{{ del.deliverymethod }} :
                                  {{ del.quantity }}</small
                                >
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                  <div
                    v-for="(del, index) of deliveries.intransit[
                      inventory.location._id
                    ]"
                    :key="index"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col class="py-0">
                            <v-list-item-subtitle
                              >ETA: {{ del.eta }}</v-list-item-subtitle
                            >
                            <v-list-item-title
                              >{{
                                del.quantityavailable +
                                del.specialquantity +
                                +del.quantitybackorder
                              }}
                              pcs.<br />{{ del.destination }}</v-list-item-title
                            >
                          </v-col>
                          <v-col class="py-0">
                            <v-list-item-subtitle class="ml-2"
                              ><small
                                >Available: {{ del.quantityavailable }}</small
                              ></v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="ml-2"
                              ><small
                                >Backorder:
                                {{
                                  del.specialquantity + del.quantitybackorder
                                }}</small
                              >
                              <v-dialog
                                v-model="dialog"
                                width="600px"
                                v-if="
                                  (del.specialorders &&
                                    del.specialorders.length) ||
                                  (del.backorders && del.backorders.length)
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="ml-2"
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                  >
                                    mdi-format-list-bulleted
                                  </v-icon>
                                </template>
                                <v-card>
                                  <v-data-table
                                    :headers="BOheaders"
                                    :items="
                                      del.specialorders.concat(del.backorders)
                                    "
                                    :loading="loading"
                                    loading-text="Loading... Please wait"
                                  >
                                    <template v-slot:item.customer="{ item }">
                                      <router-link
                                        :to="{
                                          path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                                        }"
                                        class="link"
                                        >{{
                                          item.customer.displayname ||
                                          item.customer.name
                                        }}</router-link
                                      >
                                    </template>
                                    <template
                                      v-slot:item.transaction="{ item }"
                                    >
                                      <router-link
                                        :to="{
                                          path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                                        }"
                                        class="link"
                                        >{{
                                          item.transaction.displayname ||
                                          item.transaction.name
                                        }}</router-link
                                      >
                                    </template>
                                  </v-data-table>
                                </v-card>
                              </v-dialog>
                            </v-list-item-subtitle>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                  <!-- <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Total Value:</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          inventory.totalvalue || 0
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                </v-list>
              </v-col>
              <v-divider vertical></v-divider>
            </div>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <tool-openorderstatus
          :item="record._id"
          :customheaders="customheaders"
        />
      </v-tab-item>
      <v-tab-item>
        <tool-itemsindeliveries :item="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-relatedtransactions
          :record="record"
          :customheaders="customheaders2"
        />
      </v-tab-item>
      <v-tab-item>
        <statistics :item="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-logs :record="record._id" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
import statistics from "../../../components/tools/itemstatistics/View";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      active: null,
      loading: false,
      duplicatebarcode: false,
      deliveries: {},
      replaces: [],
      photosrc: [
        { url: "http://www.ozparts.eu/DBA4654S-10_LR_FRONT.jpg" },
        { url: "http://www.ozparts.eu/T3_4000_Kangaroo-Paw" },
      ],
      customheaders: [
        //{ text: "Location", value: "location", sortable: false },
        { text: "Date of Order", value: "date", sortable: false },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Customer", value: "customer", sortable: false },
        {
          text: "Qty. Open",
          value: "quantityopen",
          sortable: false,
          summary: true,
        },
        { text: "Committed", value: "quantitycommitted", sortable: false },
        { text: "Service", value: "additionalservice" },
        { text: "SO#", value: "correlatedtransaction" },
        { text: "PO#", value: "specialorders" },
        { text: "ETA", value: "eta", sortable: false },
        { text: "ETA Memo", value: "etamemo", sortable: false },
        {
          text: "ETA Internal",
          value: "etamemo2",
          sortable: false,
          width: "180px",
        },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Internal Comments", value: "memomain", sortable: false },
        { text: "Stock Order", value: "stockorder", sortable: false },
        { text: "Fleet Order", value: "fleetorder", sortable: false },
        { text: "Dropship", value: "dropship", sortable: false },
        {
          text: "Ship Date",
          value: "planneddateofshipment",
          sortable: false,
        },
        {
          text: "Sales Rep",
          value: "salesrep",
          sortable: false,
        },
      ],
      customheaders2: [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Customer", value: "customer" },
        { text: "Transaction", value: "transaction" },
        { text: "Created From", value: "createdfromtransaction" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "Shipp Address", value: "shippingaddress" },
        { text: "Type", value: "recordtype" },
        { text: "Status", value: "status" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount", summary: true },
        { text: "Gross Amount", value: "grossamount", summary: true },
        { text: "Quantity", value: "quantity", summary: true },
        {
          text: "Quantity Committed",
          value: "quantitycommitted",
          summary: true,
        },
        { text: "Quantity Received", value: "quantityreceived", summary: true },
        { text: "Quantity Billed", value: "quantitybilled", summary: true },
        {
          text: "Inventory Valuation",
          value: "inventoryvaluation",
        },
        { text: "Memo", value: "memo" },
        { text: "ETA/ETD", value: "eta" },
        { text: "ETA/ETD memo", value: "etamemo" },
        { text: "Customer Location", value: "customerlocation" },
      ],
      BOheaders: [
        { text: "Customer", value: "customer" },
        { text: "SO#", value: "transaction" },
      ],
      locationsFilter: [],
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {
        if (this.record.photos && this.record.photos.length > 0) {
          this.photosrc = this.record.photos;
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.$store.state.user.location) {
      this.locationsFilter.push(this.$store.state.user.location, "5e0dbaba9e33df43f0b3a495");
      let altlocation = await service("accounting", {
        action: "findOne",
        data: [{ _id: this.$store.state.user.location }],
      }).then((res) => {
        if (res.location) this.locationsFilter.push(res.location);
        if (res.destination) this.locationsFilter.push(res.destination);
      });
    }
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    if (this.record.photos && this.record.photos.length > 0)
      this.photosrc = this.record.photos;
    this.record["inventorydetails"].sort((a, b) =>
      a.location._id < b.location._id ? -1 : 1
    );
    this.deliveries = await service(
      "deliveries",
      {
        action: "getItemInTransit",
        item: this.record._id,
      },
      false,
      false
    );
    this.replaces = await service("items", {
      action: "find",
      data: [
        { supersededby: this.record._id },
        { projection: { name: true, recordtype: true, collection: true } },
      ],
    }).then((res) => {
      return res;
    });
    if (this.record.barcode) {
      return await service("items", {
        action: "findOne",
        data: [{ _id: { $ne: this.record._id }, barcode: this.record.barcode }],
      })
        .catch((err) => false)
        .then((res) => {
          this.duplicatebarcode = res ? true : false;
        });
    } else this.duplicatebarcode = false;
  },
  computed: {
    newitem() {
      return Math.round(
        Math.abs((new Date() - new Date(this.record.created)) / 86400000)
      ) < 365
        ? true
        : false;
    },
    totalvalue() {
      let totalvalue = 0;
      this.record["inventorydetails"].forEach((line) => {
        totalvalue += line.totalvalue;
      });
      return totalvalue.toFixed(2);
    },
    nla() {
      for (let line of this.record["inventorydetails"]) {
        if (line.stockcategory && line.stockcategory._id == "nla") {
          console.log(true);
          return true;
        }
      }
      return false;
    },
    stockclearance() {
      for (let line of this.record["inventorydetails"]) {
        if (line.stockcategory && line.stockcategory._id == "stockclearance") {
          console.log(true);
          return true;
        }
      }
      return false;
    },
    quantityavailable() {
      let quantityavailable = 0;
      this.record["inventorydetails"].forEach((line) => {
        if (
          line.stockcategory &&
          (line.stockcategory._id || line.stockcategory) == "nla"
        )
          this.nla = true;
        console.log(line.stockcategory);
        quantityavailable += line.quantityavailable;
      });
      return quantityavailable;
    },
  },
  components: {
    statistics,
  },
};
</script>
<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
  white-space: unset;
}
</style>

