/* eslint-disable */
import { variables } from '../../config/variables'
import store from '../../store/index'
async function getServer() {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.state.user.token || ""}`,
        User: store.state.user._id,
    };
    return await fetch(`${variables.apiurl3}/scanner/v1/printerserver`, {
        method: 'GET',
        headers: headers,
    }).then(
        async (res) => {
            let resposne = await res.json();
            resposne.ip = "localhost"
            return resposne;
        }
    );
}


async function getPrinters() {
    try {
        let printerServer = await getServer();

        if (
            printerServer &&
            printerServer.ip &&
            printerServer.port
        ) {

            return fetch(
                `http://${printerServer.ip}:${printerServer.port}/api/printers`,
                {
                    method: "GET",
                    headers: {},
                }
            ).then(async (res) => {

                let resposne = await res.json();
                console.log(resposne)
                if (resposne && resposne.data) return resposne.data.printers || [];
                else return [];
            });
        } else return [];
    } catch (err) {
        console.log(err);
    }
}


async function print(name, barcode, bin, copy = 1, printer) {
    try {
        //let printer = localStorage.getItem("printer");
        let nameArray = []
        if (name.length > 16) {
            for (let i = 0; i <= name.length; i += 16)
                nameArray.push(name.substring(i, i + 16));
        } else {
            nameArray = [name]
        }
        let printerServer = await getServer();
        if (printerServer && printerServer.ip &&
            printerServer.port && printer && name && barcode) {
            if (bin == "TBA") bin = "";
            for (let label = 0; label < copy; label++) {
                fetch(`http://${printerServer.ip}:${printerServer.port}/api/printers/${printer}`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        zpl: `
N
q609

B80,50,0,1,2,5,64,B,"${barcode}"
${nameArray.map((name,index)=>{
    return `A80,${(nameArray.length>1?220:200)-(Math.ceil(nameArray.length/2)*30) + (index*30)},0,${nameArray.length>1?2:3},2,2,N,"${name.trim()}"`
}).join("\n")}
A80,${nameArray.length>1?320:280},0,2,2,2,N,"${bin}"
A420,${nameArray.length>1 && bin ?360:340},0,2,1,1,N,"${new Date().toISOString().substring(0, 10)}"
P1
        `,
                    }),
                });
            }
        }

    } catch (err) {
        console.log(err);
    }
}



export default {
    getPrinters,
    print
}
