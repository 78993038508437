<template>
  <v-autocomplete
    :error="
      required && (!recordSelected || (multiple && !recordSelected.length))
    "
    append-icon="mdi-magnify"
    hint="type name and put enter to find"
    v-model="recordSelected"
    :item-text="displayfield"
    item-value="_id"
    :label="label"
    :items="records"
    :multiple="multiple"
    :rules="[rules.required]"
    :return-object="recordObj"
    :readonly="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived ||
        loading) &&
      !this.formSettings.show
    "
    :disabled="
      (this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        disabled) &&
      !this.formSettings.show
    "
    :search-input.sync="search"
    :clearable="$route.params.mode == 'view' || record.archived ? false : true"
    outlined
    :loading="loading"
    hide-no-data
    dense
    v-on:keyup.enter="fetchEntriesDebounced(search, true)"
  >
    <template
      v-slot:selection="{ item, index }"
      v-if="$route.params.mode == 'view'"
    >
      <a
        v-if="item._id && item.collection && item.recordtype"
        color="primary"
        :key="index"
        :href="`/${item.collection}/${item.recordtype}/view/${item._id}`"
        target="_blank"
        class="link d-block"
        >{{ item.displayname || item.name }}</a
      ><span v-else>{{ item.displayname || item.name }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      records: [],
      recordSelected: this.value || null,
      recordObj: true,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      multiple:
        this.formSettings.multiple ||
        this.fieldSettings.setup.multiple ||
        ["$in", "$nin"].includes(this.formSettings.operator),
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) =>
          this.required
            ? (value && !!value._id) || "type name and put enter to find."
            : true,
      },
      mode: this.$route.params.mode,
      loading: false,
      search: null,
      _timerId: "search",
      typing: false,
      displayfield: this.fieldSettings.setup.displayfield || "displayname",
    };
  },
  watch: {
    async recordSelected(value) {
      if (this.formSettings.show) this.field = "value";
      if (this.record[this.field] != value)
        this.$emit("emit", { field: this.field, value: value || null });
      //this.records = await this.getData(this.fieldSettings, this.formSettings);
    },

    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        if (this.record[this.field]) {
          let value = this.record[this.field];
          if (Array.isArray(value))
            value.forEach((val) => {
              val.displayname = val.displayname || val.name;
            });
          else {
            value = this.record[this.field];
            if (value.displayname || value.name)
              value.displayname = value.displayname || value.name;
          }
          this.recordSelected = this.record[this.field];
          if (this.multiple) this.records.push(...this.record[this.field]);
          else this.records.push(this.record[this.field]);
        } else this.recordSelected = null;
        this.search = "";
      },
      deep: true,
    },
    async search(val) {
      if (
        !this.recordSelected ||
        (this.recordSelected && this.recordSelected.name != val)
      )
        this.fetchEntriesDebounced(val);
    },
  },
  computed: {
    disabled() {
      if (
        this.field == "createdfromtransaction" &&
        this.record.parentrecordtype == "inventoryadjustment" &&
        !this.record.quantity
      ) {
        return true;
      }
    },
  },
  async created() {
    if (this.formSettings.show) {
      this.field = "value";
      let field = fieldCalculated(this.fieldSettings, this.record);
      let filtervalue = this.recordSelected;
      if (!Array.isArray(filtervalue)) filtervalue = [filtervalue];
      this.records = await service(field.setup.collection, {
        action: "find",
        data: [{ _id: { $in: filtervalue } }],
      }).then((options) => {
        options.forEach((obj) => {
          if (this.recordObj) {
            obj.displayname = obj.displayname || obj.name;
            options.push(obj);
          }
        });
        return options;
      });
    }
    if (this.record[this.field]) {
      let value = this.record[this.field];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          val.displayname = val.displayname || val.name;
        });
        this.records.push(...value);
      } else {
        value = this.record[this.field];
        if ((value.displayname || value.name) && value._id)
          value.displayname = value.displayname || value.name;
        this.records.push(value);
      }
      this.recordSelected = value;
    }
  },
  methods: {
    fetchEntriesDebounced(val, init) {
      console.log(init);
      this.typing = true;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      let delay = init ? 0 : 1500;
      this._timerId = setTimeout(async () => {
        this.typing = false;
        if (val) {
          if (this.loading) return;

          if (
            (val.length >= 5 || init) &&
            !this.loading &&
            this.typing == false
          ) {
            this.loading = true;
            this.records = await this.getData(
              this.fieldSettings,
              this.formSettings,
              val
            );
            if (this.recordSelected) {
              if (this.multiple)
                this.records.push(...(this.recordSelected || []));
              else this.records.push(this.recordSelected);
            }
            this.loading = false;
          }
        }
      }, delay);
    },
    async getData(source, form, search) {
      //console.log(source,form)
      source.setup.filters = source.setup.filters || {};

      let field = fieldCalculated(source, this.record);
      if (search) {
        search = search.replaceAll(")", "\\)");
        search = search.replaceAll("(", "\\(");
        source.filters["$or"] = [
          { name: { $regex: `.*${search}.*`, $options: "i" } },
          { displayname: { $regex: `.*${search}.*`, $options: "i" } },
        ];
      }
      let parameters = [
        source.filters,
        {
          projection: {
            name: true,
            displayname: true,
            recordtype: true,
            collection: true,
          },
        },
      ];
      let data = {
        action: "find",
        data: parameters,
        limit: 50,
        sort: { name: 1 },
      };
      //console.log("Select", data);
      let selected = this.multiple
        ? this.recordSelected
        : [this.recordSelected];
      let options = await service(field.setup.collection, data);

      options.forEach((obj) => {
        if (this.recordObj) {
          obj.displayname = obj.displayname || obj.name;
          options.push(obj);
        }
      });
      return options;
    },
  },
};
</script>