<template>
  <div>
    <v-toolbar
      dark
      :color="mode == 'create' ? 'red' : mode == 'edit' ? 'green' : 'primary'"
    >
      <v-toolbar-title class="mr-4"
        >{{ title }}
        <small v-if="record.company" class="d-block caption">{{
          record.company.name
        }}</small>
      </v-toolbar-title>

      <v-chip outlined label v-if="status" class="mr-2">{{ status }}</v-chip>
      <v-btn
        icon
        @click="previous()"
        v-if="$route.params.mode == 'view' && record.company"
      >
        <v-tooltip bottom>
          <span>Previous</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-chevron-left</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="next()"
        v-if="$route.params.mode == 'view' && record.company"
      >
        <v-tooltip bottom>
          <span>Next</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-chevron-right</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-spacer></v-spacer>

      <v-overflow-btn
        v-if="
          $route.params.mode == 'view' &&
          !(
            !record.tmp_assignedprepayment &&
            record.tmp_prepayments &&
            record.amountdue
          ) &&
          ($route.params.recordtype == 'customerpayment' ||
            $route.params.recordtype == 'customer' ||
            $route.params.recordtype == 'delivery' ||
            record.pdfform)
        "
        style="min-width: 250px"
        flat
        class="mt-5"
        :items="quickemails"
        label="Quick Email"
        segmented
        dense
        :menu-props="{ maxHeight: 'unset' }"
      >
      </v-overflow-btn>
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="readyToPack()"
        :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
        v-if="
          record.quantitycommitted > 0 &&
          $route.params.mode == 'view' &&
          [
            'open',
            'partlybilled',
            'partlyfulfilled',
            'fulfilled',
            'partlybilled',
          ].includes(record.status._id)
        "
      >
        <v-tooltip bottom>
          <span>Ready to Pack</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-package-up</v-icon>
          </template>
        </v-tooltip>
      </v-btn>

      <v-btn
        icon
        @click="approveOrder()"
        :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
        v-if="
          record.status &&
          [
            'salesorder',
            'inventorytransfer',
            'stockrequest',
            'returnrequest',
            'stockcheck',
            'delivery',
          ].includes(record.recordtype) &&
          ['pendingapproval'].includes(record.status._id) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Approve</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-check-outline</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="invoice()"
        :disabled="!hasAccess(record.collection, 'invoice', 'create')"
        v-if="
          record.status &&
          [
            'open',
            'partlybilled',
            'partlyfulfilled',
            'fulfilled',
            'partlybilled',
          ].includes(record.status._id) &&
          record.recordtype == 'salesorder' &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Invoice</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-receipt</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="purchaseorder()"
        :disabled="!hasAccess(record.collection, 'purchaseorder', 'create')"
        v-if="
          record.status &&
          [
            'open',
            'partlybilled',
            'partlyfulfilled',
            'fulfilled',
            'partlybilled',
          ].includes(record.status._id) &&
          record.recordtype == 'salesorder' &&
          $route.params.mode == 'view' &&
          record.quantitybackorder
        "
      >
        <v-tooltip bottom>
          <span>Purchase Order</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-document-outline</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="returnrequest()"
        :disabled="!hasAccess(record.collection, 'returnrequest', 'create')"
        v-if="record.recordtype == 'invoice' && $route.params.mode == 'view'"
      >
        <v-tooltip bottom>
          <span>Return Request</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-document-outline</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <!-- <v-btn
        icon
        @click="stockrequest()"
        v-if="
          record.recordtype != 'stockrequest' &&
          record.quantitybackorder > 0 &&
          $route.params.mode == 'view' &&
          [
            'open',
            'partlybilled',
            'partlyfulfilled',
            'fulfilled',
            'partlybilled',
          ].includes(record.status._id)
        "
      >
        <v-tooltip bottom>
          <span>Stock Request</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-transfer</v-icon>
          </template>
        </v-tooltip>
      </v-btn> -->
      <v-btn
        icon
        @click="createsalesorder()"
        v-if="
          (['estimate', 'warranty'].includes(record.recordtype) ||
            (['purchaseorder'].includes(record.recordtype) &&
              record.customer &&
              ['company'].includes(record.customer.recordtype))) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Create Sales Order</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-document</v-icon>
          </template>
        </v-tooltip>
      </v-btn>

      <v-menu
        offset-y
        v-if="
          record.recordtype == 'inventoryitem' && $route.params.mode == 'view'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-tooltip bottom>
              <span>Print</span>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-printer</v-icon>
              </template>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="pdf('pdfinventoryitem', '', $store.state.user.location)"
          >
            <v-list-item-icon>
              <v-icon>mdi-book-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Print Information</v-list-item-title>
          </v-list-item>
          <v-list-item @click="printLabel()">
            <v-list-item-icon>
              <v-icon>mdi-barcode-scan</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Print Label</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog
        v-model="printDialog"
        width="600px"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-toolbar-title>Print Label</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon x-large @click="printDialog = false">
              <v-icon left x-large> mdi-cancel </v-icon>
            </v-btn>
          </v-toolbar>
          <v-list two-line class="mt-4">
            <v-list-item>
              <v-select
                v-model="printer"
                :items="printers"
                item-text="name"
                item-value="id"
                placeholder
                clearable
                label="Printer"
                filled
                dense
              ></v-select>
            </v-list-item>
            <v-list-item>
              <v-text-field
                :disabled="!printer"
                v-model="label.name"
                label="Name"
                filled
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                :disabled="!printer"
                v-model="label.barcode"
                label="Barcode"
                filled
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-select
                :disabled="!printer"
                v-model="label.bin"
                :items="binlocations"
                item-text="name"
                item-value="id"
                placeholder
                clearable
                label="Warehouse - Bin"
                filled
                dense
              ></v-select>
            </v-list-item>
            <v-list-item>
              <v-text-field
                :disabled="!printer"
                v-model="label.copy"
                label="Copy"
                filled
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-list-item>

            <v-divider></v-divider>
          </v-list>
          <v-card-actions>
            <v-btn
              tile
              :disabled="!printer"
              class="mb-2"
              @click="
                printLabel(
                  label.name,
                  label.barcode,
                  label.bin,
                  label.copy,
                  label.printer
                )
              "
              x-large
              color="primary"
              block
              depressed
            >
              Print</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        icon
        @click="creditmemo()"
        v-if="
          ['invoice', 'returnrequest'].includes(record.recordtype) &&
          !['pendingapproval'].includes(
            record.status ? record.status._id : record.status
          ) &&
          $route.params.mode == 'view' &&
          !['role_warehouse', 'role_warehouse_manager'].includes(
            this.$store.state.user.role._id
          )
        "
      >
        <v-tooltip bottom>
          <span>Corrective</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">assignment_return</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="vendorcreditmemo()"
        v-if="
          ['inventoryadjustment'].includes(record.recordtype) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Vendor Credit Memo</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">assignment_return</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="returnauthorization()"
        v-if="record.recordtype == 'creditmemo' && $route.params.mode == 'view'"
      >
        <v-tooltip bottom>
          <span>Return Authorization</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">assignment_return</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="bill()"
        v-if="
          !record.closed &&
          ['purchaseorder', 'invoice', 'noinvoice'].includes(
            record.recordtype
          ) &&
          record.customer &&
          ['vendor', 'company'].includes(record.customer.recordtype) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Bill</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-receipt</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="delivery()"
        v-if="
          !record.closed &&
          ['itemfulfillment', 'invoice', 'noinvoice', 'purchaseorder'].includes(
            record.recordtype
          ) &&
          record.customer &&
          ['vendor', 'company'].includes(record.customer.recordtype) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Make Delivery</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">local_shipping</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="inventorytransfer()"
        v-if="
          !record.closed &&
          ['released'].includes(
            record.status ? record.status._id || record.status : record.status
          ) &&
          ((record.recordtype == 'inventorytransfer' &&
            !record.quantityreceived &&
            record.quantity < 0) ||
            record.recordtype == 'stockrequest') &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Inventory Transfer</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-transfer</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="inventorytransfer()"
        v-if="
          !record.closed &&
          ['received'].includes(
            record.status ? record.status._id || record.status : record.status
          ) &&
          record.recordtype == 'delivery' &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Inventory Transfer</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-transfer</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="binTransfer()"
        v-if="record.recordtype == 'stockcheck' && $route.params.mode == 'view'"
      >
        <v-tooltip bottom>
          <span>Bin Transfer</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-transfer</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="inventoryadjustment()"
        v-if="
          (record.recordtype == 'returnauthorization' ||
            (record.recordtype == 'salesorder' && reqreturntovendor)) &&
          record.location.location &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Return to Vendor</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-transfer</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="receipt()"
        v-if="
          !record.closed &&
          record.recordtype == 'bill' &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Item Receipt</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-package-down</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="saveRecord()"
        v-if="$route.params.mode != 'view'"
        :disabled="
          record.alert ||
          (['report', 'query'].includes(record.recordtype) &&
            ((record.employee &&
              (record.employee._id || record.employee) !=
                $store.state.user._id) ||
              (!record.employee && record.created))) ||
          !hasAccess(record.collection, record.recordtype, 'edit') ||
          baselinkerArchived
        "
      >
        <v-tooltip bottom>
          <span>Save</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-content-save</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="saveAndEmail()"
        v-if="
          record.pdfform &&
          record.collection == 'transactions' &&
          [
            'invoice',
            'estimate',
            'salesorder',
            'customerpayment',
            'purchaseorder',
            'creditmemo',
            'prepayment',
          ].includes(record.recordtype) &&
          saveAndEmailBtn &&
          $route.params.mode != 'view'
        "
        :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
      >
        <v-tooltip bottom>
          <span>Save and Email</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-content-save-move</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="saveAndPrint()"
        v-if="
          record.pdfform &&
          $route.params.mode != 'view' &&
          record.collection != 'users'
        "
        :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-cloud-print-outline</v-icon>
          </template>
          <span>Save and Print</span>
        </v-tooltip>
      </v-btn>
      <v-btn icon @click="cancelRecord()" v-if="$route.params.mode != 'view'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-cancel</v-icon>
          </template>
          <span>Cancel</span>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="
          pdf(
            record.status && record.status._id == 'readytopack'
              ? 'pdfpickingticket'
              : null
          )
        "
        v-if="
          record.pdfform &&
          $route.params.mode == 'view' &&
          record.collection != 'users' &&
          !['invoice', 'creditmemo'].includes(record.recordtype)
        "
      >
        <v-tooltip bottom>
          <span>Print</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-printer</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-menu
        v-else-if="
          record.pdfform &&
          $route.params.mode == 'view' &&
          record.collection != 'users'
        "
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-tooltip bottom>
              <span>Print</span>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-printer</v-icon>
              </template>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="
              record.pdfform &&
              $route.params.mode == 'view' &&
              record.collection != 'users'
            "
            @click="
              pdf(
                record.status && record.status._id == 'readytopack'
                  ? 'pdfpickingticket'
                  : null
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-text-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Print Document</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="pdf('', '', '', 'attach')"
            v-if="
              ['invoice'].includes(record.recordtype) &&
              $route.params.mode == 'view' &&
              record.collection == 'transactions'
            "
            :disabled="
              !(
                record.files ||
                record.file ||
                record.file2 ||
                (record.trackingnumbers && record.trackingnumbers.length)
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Print Document + attachments</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="pdf('', true)"
            v-if="
              ['invoice', 'creditmemo'].includes(record.recordtype) &&
              $route.params.mode == 'view' &&
              record.collection != 'users' &&
              ['role_accounting', 'role_sales'].includes(
                this.$store.state.user.role._id
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-refresh</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Print Duplicate</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="pdf(record.altpdfform._id)"
            v-if="
              record.altpdfform &&
              $route.params.mode == 'view' &&
              record.collection != 'users'
            "
          >
            <v-list-item-icon>
              <v-icon>>mdi-file-document-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              >Print {{ record.altpdfform.name }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        icon
        :disabled="!hasAccess(record.collection, 'customerpayment', 'create')"
        @click="payment()"
        v-if="
          (record.amountdue ||
            ['customer', 'company', 'salesorder'].includes(
              record.recordtype
            )) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Make Payment</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-account-cash</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="prepayment()"
        :disabled="!hasAccess(record.collection, 'prepaymentinvoice', 'create')"
        v-if="
          (record.amountdue || ['salesorder'].includes(record.recordtype)) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Make Prepayment Invoice</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-cash-multiple</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="refund()"
        :disabled="!hasAccess(record.collection, 'customerrefund', 'create')"
        v-if="
          (record.unapplied ||
            ['customer', 'company'].includes(record.recordtype)) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Make Refund</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-cash-refund</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="salesorder()"
        v-if="
          ['customer', 'company'].includes(record.recordtype) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Create Sales Order</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-document</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn
        icon
        @click="estimate()"
        v-if="
          ['customer', 'company'].includes(record.recordtype) &&
          $route.params.mode == 'view'
        "
      >
        <v-tooltip bottom>
          <span>Create Estimate</span>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-file-document-outline</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item @click="test()">
            <v-list-item-icon>
              <v-icon>edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Test</v-list-item-title>
          </v-list-item> -->
          <v-list-item
            :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
            @click="editRecord()"
            v-if="$route.params.id && $route.params.mode == 'view'"
          >
            <v-list-item-icon>
              <v-icon>edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="
              !hasAccess(record.collection, record.recordtype, 'create')
            "
            @click="copy()"
            v-if="
              $route.params.id &&
              ($route.params.mode == 'view' ||
                ($route.params.mode == 'edit' &&
                  record.collection == 'queries')) &&
              [
                'items',
                'transactions',
                'users',
                'queries',
                'emails',
                'lists',
                'deliveries',
                'sources'
              ].includes(record.collection)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Copy</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="
              !hasAccess(record.collection, record.recordtype, 'create')
            "
            @click="newrecord()"
            v-if="
              $route.params.id &&
              $route.params.mode == 'view' &&
              ['items', 'transactions', 'users'].includes(record.collection)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-plus-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>New</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="closeOrder()"
            :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
            v-if="
              $route.params.id &&
              $route.params.mode != 'view' &&
              [
                'salesorder',
                'purchaseorder',
                'returnrequest',
                'transportclaim',
              ].includes($route.params.recordtype)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              record.closed ? "Open" : "Close"
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="deleteRecord()"
            v-if="
              $route.params.id &&
              $route.params.mode != 'view' &&
              record.collection != 'items'
            "
            :disabled="
              (record.recordtype == 'report' &&
                ((record.employee &&
                  record.employee != $store.state.user._id) ||
                  !record.employee)) ||
              !hasAccess(record.collection, record.recordtype, 'delete')
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="approveOrder()"
            :disabled="!hasAccess(record.collection, record.recordtype, 'edit')"
            v-if="
              record.status &&
              [
                'salesorder',
                'inventorytransfer',
                'stockrequest',
                'returnrequest',
              ].includes(record.recordtype) &&
              ['open'].includes(record.status._id) &&
              $route.params.mode == 'view'
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-clock-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pending Approval</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-snackbar v-model="msg.show" color="red" :timeout="6000" top center
        >{{ msg.message }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="msg.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-toolbar>
    <v-overlay :value="recalc">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import saveRecord from "../../common/methods/saveRecord";
import { sendEmail } from "../../common/methods/email";
import service from "../../api/index";
import printer from "../../common/methods/printer";

export default {
  props: ["record", "form"],
  data() {
    return {
      printDialog: false,
      printer: "",
      printers: [],
      label: { name: "", barcode: "", bin: "", copy: 1, printer: "" },
      valid: false,
      rid: this.$route.params.id,
      mode: this.$route.params.mode,
      msg: false,
      loading: true,
      recalc: false,
      active: null,
      actions: false,
      save: false,
      edit: false,
      quickemails: [],
      saveAndEmailBtn: false,
      baselinker_archived: [],
      baselinkerArchived: false,
    };
  },
  computed: {
    binlocations() {
      return (this.record.inventorylocations || []).map((bin) => {
        return {
          id: bin.bin,
          name: `${bin.location.name} - ${bin.bin}  ${
            bin.location.priority ? ` (priority: ${bin.location.priority})` : ""
          }`,
        };
      });
    },
    reqreturntovendor() {
      return (this.record.transactionlines || []).some((line) => {
        return line.quantityreceived;
      });
    },
    title() {
      let margeinto = (this.record.margeinto || [])
        .reduce((t, r) => {
          t.push(r.name);
          return t;
        }, [])
        .join(", ");
      if (!margeinto) margeinto = "";
      if (this.mode != "create")
        return `${this.record.name || "Loading..."} ${
          margeinto ? "(" + margeinto + ")" : ""
        }`;
      else {
        return `${this.record.name || "# To Be Generate..."} ${
          margeinto ? "(" + margeinto + ")" : ""
        }`;
      }
    },
    status() {
      if (
        this.record.status &&
        (this.record.recordtype != "customer" ||
          (this.record.inactive &&
            this.record.status._id == "inactivecustomer"))
      )
        return `${this.record.status.name ? this.record.status.name : ""}`;
    },
  },
  async created() {
    let baselinker_order_id = this.record.baselinker_order_id;
    if (
      this.record.baselinker_order_id &&
      !Array.isArray(this.record.baselinker_order_id)
    )
      baselinker_order_id = [this.record.baselinker_order_id];
    if (
      this.$route.params.mode == "create" &&
      baselinker_order_id &&
      baselinker_order_id.length
    )
      for (let order of baselinker_order_id) {
        let status = await service("baselinker", {
          action: "baselinker_getStatus",
          order: order,
        });
        if (status.status == "Archived") this.baselinker_archived.push(order);
      }
    if (this.baselinker_archived.length) this.baselinkerArchived = true;
  },
  watch: {
    async record() {
      this.refreshEmails();
    },
  },

  methods: {
    hasAccess(collection, recordtype, action) {
      // permission
      if (
        collection &&
        action &&
        this.$store.state.user.role &&
        this.$store.state.user.role.permission
      ) {
        if (this.$store.state.user.role.permission[collection]) {
          if (this.$store.state.user.role.permission[collection][recordtype]) {
            if (
              this.$store.state.user.role.permission[collection][recordtype] &&
              Array.isArray(
                this.$store.state.user.role.permission[collection][recordtype]
              )
            ) {
              return this.$store.state.user.role.permission[collection][
                recordtype
              ].includes(action);
            }
          } else {
            if (
              this.$store.state.user.role.permission[collection] &&
              Array.isArray(this.$store.state.user.role.permission[collection])
            )
              return this.$store.state.user.role.permission[
                collection
              ].includes(action);
          }
        } else return true;
      }
      // location
      if (
        this.$store.state.user.location &&
        this.record &&
        this.record.location
      ) {
        let availablelocations = [
          undefined,
          null,
          false,
          this.$store.state.user.location,
          ...(this.$store.state.user.forlocations || []),
        ];
        return availablelocations.includes(
          this.record && this.record.location
            ? this.record.location._id || this.record.location
            : undefined
        );
      }
      return true;
    },
    async refreshEmails() {
      this.saveAndEmailBtn = await service("sources_emails", {
        action: "findOne",
        data: [
          {
            source: this.record.source
              ? this.record.source._id || this.record.source
              : null,
            trigger: `trigger_email${this.record.recordtype}`,
          },
        ],
      })
        .catch((error) => {
          return false;
        })
        .then((res) => {
          return res ? true : false;
        });
      if (this.$route.params.mode == "view") {
        let templates = await this.getData("emails", [
          {
            recordtype: "emailtemplate",
            hidden: { $ne: true },
            type: { $in: [this.record.recordtype, null] },
            company: {
              $in: [this.record.company ? this.record.company._id : null, null],
            },
            reservation: !this.record.reservation ? { $ne: true } : { $ne: [] },
            role: {
              $in: [
                this.$store.state.user && this.$store.state.user.role._id
                  ? this.$store.state.user.role._id
                  : null,
                null,
                undefined,
              ],
            },
            $or: [
              {
                forsources: this.record.source
                  ? this.record.source._id
                  : { $ne: [] },
              },
              { forsources: [] },
              { forsources: { $exists: false } },
            ],
          },
        ]);
        this.quickemails = [];
        templates
          .sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
          .map((template) => {
            let email = {
              record: this.record._id,
              collection: this.record.collection,
              recordtype: this.record.recordtype,
              emailtemplate: template._id,
            };
            this.quickemails.push({
              text: template.displayname || template.name,
              callback: () => {
                var response = confirm("Send Email - Are you sure?");
                if (response) {
                  sendEmail(this, email);
                  alert("Sending in progress...");
                }
              },
            });
          });
      }
    },
    getData(collection, parameters = []) {
      let data = {
        action: "find",
        data: parameters,
      };
      return service(collection, data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          let options = response.map((obj) => {
            return obj;
          });
          return options;
        })
        .catch((error) => {});
    },
    async next() {
      let nextRecord = await service(this.record.collection, {
        action: "findOne",
        data: [
          {
            _id: { $gt: this.record._id },
            recordtype: this.record.recordtype,
            company: this.record.company._id,
          },
          { sort: { _id: 1 } },
        ],
      })
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          return response;
        });
      if (nextRecord)
        this.$router.push({
          path: `/${this.record.collection}/${this.record.recordtype}/view/${nextRecord._id}`,
        });
    },
    async previous() {
      let nextRecord = await service(this.record.collection, {
        action: "findOne",
        data: [
          {
            _id: { $lt: this.record._id },
            recordtype: this.record.recordtype,
            company: this.record.company._id,
          },
          { sort: { _id: -1 } },
        ],
      })
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          return response;
        });
      if (nextRecord)
        this.$router.push({
          path: `/${this.record.collection}/${this.record.recordtype}/view/${nextRecord._id}`,
        });
    },
    test() {
      //this.record.addressbook[0].name = "";
      console.log(this.record);
    },
    test2() {
      this.$set(this.record, "date", new Date());
    },
    pdf(pdfform = "", duplicate = "", location = "", full = "") {
      if (pdfform) pdfform = `_${pdfform}?pdfform=${pdfform}`;
      if (duplicate && !pdfform) duplicate = `/?duplicate=${duplicate}`;
      if (duplicate && pdfform) duplicate = `&duplicate=${duplicate}`;
      if (location && pdfform) location = `&location=${location}`;
      if (full) location = `?full=attach`;
      let pdf = `http://localhost:8080/api/pdf/${this.record.collection}/${
        this.record._id
      }/${(this.record.name || this.record.recordtype)
        .replaceAll("#", "%23")
        .replaceAll("/", "%2F")}${pdfform || ""}${duplicate || ""}${
        location || ""
      }`;
      window.open(pdf, "_blank");
    },
    copy() {
      alert("TBA");
    },
    email() {
      alert("TBA");
    },
    async getLocation() {
      return await service("items_location", {
        action: "findOne",
        data: [
          {
            item: this.record._id,
            location: "5e0dbaba9e33df43f0b3a495",
            priority: 1,
          },
        ],
      })
        .catch((error) => {
          return "";
        })
        .then((res) => {
          return res ? res.bin : "";
        });
    },
    async printLabel(name, barcode, bin, copy) {
      if (this.printDialog) {
        printer.print(name, barcode, bin, copy, this.printer);
        this.printDialog = false;
      } else {
        this.printDialog = true;
        this.label.name = this.record.name;
        this.label.barcode = this.record.barcode;
        this.label.bin = "";
        this.label.copy = 1;

        this.printers = await printer.getPrinters();
      }
    },
    async saveRecord() {
      this.recalc = true;
      let reqfields = [];
      this.form.inputs.forEach((input) => {
        if (!input.valid) reqfields.push(input.label);
      });
      if (reqfields.length)
        this.msg = {
          message: `Please fill up: ${reqfields.toString()}`,
          show: true,
        };
      if (this.form.validate()) {
        try {
          if (this.$route.params.mode == "create") {
            let status = await saveRecord(this.record, null);
            this.record._id = this.record._id || status._id;
            this.$route.params.id = this.record._id;
          } else await saveRecord(this.record, this.record._id);
          this.$router.push({
            path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
          });
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true };
          console.log(error);
          this.recalc = false;
        }
      }
      this.recalc = false;
    },
    async deleteRecord() {
      this.recalc = true;
      var response = confirm("Delete Record - Are you sure?");
      this.recalc = true;
      if (response) {
        //alert("Please Contact with Administrator");
        try {
          this.record.deleted = true;
          await saveRecord(this.record, this.record._id);
          this.$route.params.mode = "delete";
          this.$router.push({
            path: `/${this.record.collection}/${this.record.recordtype}`,
          });
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true };
          console.log(error);
          this.recalc = false;
        }
      }
      this.recalc = false;
    },
    editRecord() {
      this.$router.push({
        path: `/${this.record.collection}/${this.record.recordtype}/edit/${this.record._id}`,
      });
    },
    cancelRecord() {
      this.recalc = true;
      var response = confirm("Cancel Record - Are you sure?");
      if (response) {
        try {
          if (this.$route.params.mode != "create") {
            this.$router.push({
              path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
            });
          } else {
            this.$router.push({
              path: this.$store.state.lastPage,
            });
          }
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true };
          console.log(error);
          this.recalc = false;
        }
      }
      this.recalc = false;
    },
    async approveOrder() {
      this.recalc = true;
      try {
        await service(this.record.collection, {
          action: "approveOrder",
          orders: [this.record._id],
        });

        this.$router.push({
          path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}/?approved=T`,
        });
      } catch (error) {
        alert(error);
        this.recalc = false;
      }
      this.recalc = false;
    },
    async closeOrder() {
      var response = confirm("Cancel Record - Are you sure?");
      this.recalc = true;
      if (response) {
        try {
          await service(this.record.collection, {
            action: "closeOrder",
            orders: [this.record._id],
          });
          this.$router.push({
            path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
          });
        } catch (error) {
          this.recalc = false;
          alert(error);
        }
      }
      this.recalc = false;
    },
    async readyToPack() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "itemfulfillment",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async binTransfer() {
      let changes = {};
      this.record.stockchecks.forEach((line) => {
        if (line.item && (line.quantity || 0) != (line.quantityonhand || 0)) {
          changes[line.item._id] = changes[line.item._id] || [];
          changes[line.item._id].push(line);
        }
      });
      let lines = [];
      for (const [key, value] of Object.entries(changes)) {
        let fromBin = {};
        let toBin = {};
        value.forEach((v) => {
          if ((v.quantity || 0) < (v.quantityonhand || 0)) {
            if (fromBin[v.bin]) {
              if (
                fromBin[v.bin].quantity >
                (v.quantityonhand || 0) - (v.quantity || 0)
              ) {
                fromBin[v.bin].quantity =
                  (v.quantityonhand || 0) - (v.quantity || 0);
              }
            } else
              fromBin[v.bin] = {
                item: v.item,
                frombin: v.bin,
                quantity: (v.quantityonhand || 0) - (v.quantity || 0),
              };
          }
        });
        fromBin = Object.values(fromBin);
        value.forEach((v) => {
          if ((v.quantity || 0) > (v.quantityonhand || 0)) {
            if (toBin[v.bin]) {
              if (
                toBin[v.bin].quantity >
                (v.quantity || 0) - (v.quantityonhand || 0)
              ) {
                toBin[v.bin].quantity =
                  (v.quantity || 0) - (v.quantityonhand || 0);
              }
            } else
              toBin[v.bin] = {
                item: v.item,
                bin: v.bin,
                quantity: (v.quantity || 0) - (v.quantityonhand || 0),
              };
          }
        });

        toBin = Object.values(toBin);
        toBin.forEach((b) => {
          b.remaining = b.quantity;
          fromBin.forEach((fb) => {
            if (fb.quantity) {
              let qty = 0;
              if (fb.quantity >= b.remaining) {
                qty = b.remaining;
                fb.quantity -= b.remaining;
                b.remaining = 0;
              }
              if (fb.quantity < b.remaining) {
                qty = fb.quantity;
                b.remaining -= fb.quantity;
                fb.quantity = 0;
              }
              if (qty)
                lines.push({
                  item: b.item,
                  frombin: fb.frombin,
                  bin: b.bin,
                  quantity: qty,
                });
            }
          });
          if (b.remaining) {
            lines.push({
              item: b.item,
              frombin: null,
              bin: b.bin,
              quantity: b.remaining,
            });
          }
        });
        console.log(lines);
      }

      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "bintransfer",
          mode: "create",
          location: this.record.location,
          description: this.record.displayname,
          transaction_bin: lines,
        },
      });
    },
    async payment() {
      let appliedto = [];
      let createdfrom = [];
      let paymentmethod =
        this.record.paymentmethod ||
        (this.record.customer
          ? this.record.customer.paymentmethod
          : this.record.paymentmethod);
      if (this.record.recordtype == "invoice") {
        paymentmethod =
          this.record.createdfrom && this.record.createdfrom[0]
            ? this.record.createdfrom[0].paymentmethod
            : paymentmethod;
        appliedto.push({
          appliedto: { _id: this.record._id },
          amount: this.record.amountdue,
          grossamount: this.record.amountdue,
        });
        createdfrom.push(this.record._id);
      }
      if (this.record.recordtype == "salesorder") {
        createdfrom.push(this.record._id);
      }

      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "customerpayment",
          mode: "create",
          company: this.record.company || this.record.location.company,
          transactionappliedto: appliedto,
          createdfrom: createdfrom,
          paymentmethod: paymentmethod,
          memo: this.record.recordtype == "salesorder" ? this.record.name : "",
          amount:
            this.record.recordtype == "invoice"
              ? this.record.amountdue
              : this.record.recordtype == "salesorder"
              ? this.record.grossamount
              : 0,
          customer: ["customer", "company"].includes(this.record.recordtype)
            ? this.record._id
            : this.record.customer,
        },
      });
    },
    async prepayment() {
      let appliedto = [];

      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "prepayment",
          mode: "create",
          company: this.record.company,
          createdfrom: [this.record._id],
          customer:
            this.record.recordtype == "customer"
              ? this.record._id
              : this.record.customer,
        },
      });
    },
    async refund() {
      let appliedto = [];
      if (this.record.recordtype == "customerpayment") {
        appliedto.push({
          appliedto: { _id: this.record._id },
          amount: this.record.unapplied,
          grossamount: this.record.unapplied,
        });
      }
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "customerrefund",
          mode: "create",
          company: this.record.company,
          amount: this.record.unapplied,
          transactionappliedto: appliedto,
          customer:
            this.record.recordtype == "customer"
              ? this.record._id
              : this.record.customer,
        },
      });
    },
    async salesorder() {
      if (this.$store.state.user.role._id == "role_peddersuk") {
        this.simpleorder("salesorder");
      } else {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "salesorder",
            mode: "create",
            customer: this.record._id,
          },
        });
      }
    },
    async simpleorder(type) {
      this.$router.push({
        name: "Tools",
        params: {
          recordtype: type,
          tool: "simpleorder",
          customer: this.record,
        },
      });
    },
    async purchaseorder() {
      let vendor = null;
      let items = this.record.transactionlines
        .filter((line) => {
          return (
            line.item &&
            line.item.recordtype == "inventoryitem" &&
            line.quantitybackorder > line.quantityonorder &&
            line.specialbackorder &&
            (line.split ? line.splitdestination : true)
          );
        })
        .map((line) => {
          return {
            item: line.item,
            quantity: line.quantitybackorder - line.quantityonorder,
            createdfromline: {
              _id: line._id,
              stockorder: line.stockorder,
              fleetorder: line.fleetorder,
              specialorder: line.specialorder,
              filled: true,
            },
            createdfromtransaction: line.transaction,
            memoline: line.memoline,
          };
        });
      if (!this.record.location.destination && items.length) {
        vendor = await service("items_vendor", {
          action: "findOne",
          data: [{ item: items[0].item._id, preferred: true }],
        });
      }
      let po = {
        recordtype: "purchaseorder",
        mode: "create",
        //createdfrom: [this.record._id],
        transactionlines: items,
        //company: this.record.company,
        // location: this.record.location.destination,
        // destination: this.record.location,
        // customer: this.record.location.company,
        company: this.record.company,
        location: this.record.location,
        destination: this.record.location.destination,
        referencenumber: this.record.referencenumber,
        customer: vendor ? vendor.customer : null,
        memo: this.record.pomemo,
      };

      if (this.record.dropship) {
        po.dropship = true;
        po.shippingaddress = this.record.shippingaddress;
        po.shipname = this.record.shipname;
        po.shipaddressee = this.record.shipaddressee;
        po.shipaddress = this.record.shipaddress;
        po.shipaddress2 = this.record.shipaddress2;
        po.shipcity = this.record.shipcity;
        po.shipzip = this.record.shipzip;
        po.shipcountry = this.record.shipcountry;
        po.shipphone = this.record.shipphone;
        po.shipto = this.record.customer;

        if (this.record.dropshipfrom && this.record.dropshipfrom.customer) {
          po.customer = this.record.dropshipfrom.customer;
        }
      }
      this.$router.push({ name: "Transactions", params: po });
    },
    async returnrequest() {
      let items = this.record.transactionlines
        .filter((line) => {
          return line.item && line.item.recordtype == "inventoryitem";
        })
        .map((line) => {
          return {
            item: line.item,
            quantity: line.quantity,
            createdfromline: {
              _id: line._id,
              filled: true,
            },
            createdfromtransaction: line.transaction,
          };
        });

      this.$router.push({
        name: "Deliveries",
        params: {
          recordtype: "returnrequest",
          mode: "create",
          pdfform: "pdfreturnrequest",
          //createdfrom: [this.record._id],
          deliveryrecords: items,
          //company: this.record.company,
          // location: this.record.location.destination,
          // destination: this.record.location,
          // customer: this.record.location.company,
          company: this.record.company,
          location: this.record.location,

          customer: this.record.customer,
        },
      });
    },
    async estimate() {
      if (this.$store.state.user.role._id == "role_peddersuk") {
        this.simpleorder("estimate");
      } else {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "estimate",
            mode: "create",
            customer: this.record._id,
          },
        });
      }
    },
    async invoice() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "invoice",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async copy() {
      this.$router.push({
        name:
          this.record.collection == "users"
            ? "Users"
            : this.record.collection == "lists"
            ? "Lists"
            : this.record.collection == "deliveries"
            ? "Deliveries"
            : this.record.collection == "items"
            ? "Items"
            : this.record.collection == "queries"
            ? "Queries"
            : this.record.collection == "emails"
            ? "Emails"
                  : this.record.collection == "sources"
            ? "Sources"
            : "Transactions",
        params: {
          ...this.record,
          _id: null,
          number: null,
          name: null,
          displayname: null,
          companyname: null,
          archived: false,
          mode: "create",
          copied: true,
          status: null,
          date: null,
          closed: false,
        },
      });
    },
    async newrecord() {
      this.$router.push({
        name:
          this.record.collection == "users"
            ? "Users"
            : this.record.collection == "items"
            ? "Items"
            : "Transactions",
        params: {
          _id: null,
          recordtype: this.record.recordtype,
          mode: "create",
        },
      });
    },
    async bill() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "bill",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async vendorcreditmemo() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "creditmemo",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async delivery() {
      this.$router.push({
        name: "Deliveries",
        params: {
          recordtype: "delivery",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async creditmemo() {
      if (this.record.recordtype == "invoice")
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "creditmemo",
            mode: "create",
            createdfrom: [this.record._id],
          },
        });
      if (this.record.recordtype == "returnrequest")
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "creditmemo",
            mode: "create",
            customer: this.record.customer._id,
            location: this.record.location,
            returnrequest: this.record._id,
            transactionlines: this.record.deliveryrecords.map((line) => {
              return {
                item: line.item._id,
                quantity: line.quantity * -1,
                createdfromtransaction: line.createdfromtransaction,
                createdfromline: line.createdfromline,
                tax: line.createdfromline.tax,
              };
            }),
          },
        });
    },
    async returnauthorization() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "returnauthorization",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async receipt() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "itemreceipt",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async createsalesorder() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "salesorder",
          mode: "create",
          createdfrom: [this.record._id],
          //referencenumber: this.record.referencenumber,
        },
      });
    },
    async inventorytransfer() {
      if (this.record.recordtype == "delivery") {
        let lines = this.record.deliveryrecords
          .filter((line) => (line.quantityscanned || 0) < (line.quantity || 0))
          .map((line) => {
            return {
              item: line.item._id,
              quantity: (line.quantityscanned || 0) - (line.quantity || 0),
              quantitytopack:
                (line.quantityscanned || 0) - (line.quantity || 0),
            };
          });

        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "inventorytransfer",
            mode: "create",
            location: this.record.location._id,
            transactionlines: lines,
            memo: this.record.name,
          },
        });
      } else {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "inventorytransfer",
            mode: "create",
            createdfrom: [this.record._id],
          },
        });
      }
    },
    async inventoryadjustment() {
      if (this.record.recordtype == "salesorder") {
        let pz = await service("transactions", {
          action: "find",
          data: [
            {
              recordtype: "itemreceipt",
              correlated: this.record._id,
            },
          ],
        }).then((res) => res.map((line) => line._id));
        if (pz.length)
          this.$router.push({
            name: "Transactions",
            params: {
              recordtype: "inventoryadjustment",
              mode: "create",
              createdfrom: pz,
            },
          });
      } else {
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "inventoryadjustment",
            mode: "create",
            createdfrom: [this.record._id],
          },
        });
      }
    },
    async stockrequest() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "stockrequest",
          mode: "create",
          createdfrom: [this.record._id],
        },
      });
    },
    async saveAndEmail() {
      this.recalc = true;
      if (this.form.validate()) {
        var response = confirm("Save and Email Record - Are you sure?");
        if (response) {
          try {
            let trigger = false;
            //trigger = "trigger_emailrecord"
            if (this.record.recordtype == "invoice")
              trigger = "trigger_emailinvoice";
            if (this.record.recordtype == "customerpayment")
              trigger = "trigger_emailcustomerpayment";
            if (this.record.recordtype == "salesorder")
              trigger = "trigger_emailsalesorder";
            if (this.record.recordtype == "estimate")
              trigger = "trigger_emailestimate";
            if (this.record.recordtype == "purchaseorder")
              trigger = "trigger_emailpurchaseorder";
            if (this.record.recordtype == "creditmemo")
              trigger = "trigger_emailcreditmemo";
            if (this.record.recordtype == "prepayment")
              trigger = "trigger_emailprepayment";
            if (!trigger)
              throw { code: "EMAIL001", message: "Email Form is undefined" };
            if (this.$route.params.mode == "create") {
              let status = await saveRecord(this.record, null, trigger);
              this.record._id = this.record._id || status._id;
              this.$route.params.id = this.record._id;
            } else await saveRecord(this.record, this.record._id, trigger);
            this.$router.push({
              path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
            });
          } catch (error) {
            this.msg = { message: `${error.message}`, show: true };
            console.log(error);
            this.recalc = false;
          }
        }
      }
      this.recalc = false;
    },
    async saveAndPrint() {
      this.recalc = true;
      if (this.form.validate()) {
        try {
          let pdfform = "";
          if (
            this.record.recordtype == "itemfulfillment" &&
            (!this.record.status || !this.record.status._id == "readytopack")
          )
            pdfform = "pickingticket";
          if (this.$route.params.mode == "create") {
            let status = await saveRecord(this.record, null);
            this.record._id = this.record._id || status._id;
            this.$route.params.id = this.record._id;
          } else await saveRecord(this.record, this.record._id);
          this.pdf(pdfform);
          this.$router.push({
            path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
          });
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true };
          console.log(error);
          this.recalc = false;
        }
      }
      this.recalc = false;
    },
  },
};
</script>