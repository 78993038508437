<template>
  <v-card>
    <v-row>
      <v-col>
        <v-card-text>
          <v-progress-linear v-model="progress" color="primary" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-row>
            <v-col>
              <v-switch
                v-model="onhandonly"
                dense
                hide-details
                @change="sortPerField()"
                inset
                label="Only On Hand"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                label="Item"
                placeholder="Item"
                outlined
                dense
                hide-details
                v-model="searchTree"
                class="mt-2 mb-2"
                @change="search = searchTree"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="menufrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date from"
                    placeholder
                    v-model="datefrom"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    class="mt-2 mb-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datefrom"
                  :first-day-of-week="1"
                  @change="sortPerField()"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-treeview
            v-if="location"
            v-model="stockcheck.stockchecks"
            :load-children="fetch"
            :items="items"
            selected-color="primary"
            open-on-click
            selectable
            :search="search"
            :filter="filterTree"
            item-key="_id"
            return-object
            expand-icon="mdi-chevron-down"
            on-icon="mdi-map-marker-right"
            off-icon="mdi-archive-marker-outline"
            indeterminate-icon="mdi-archive-marker-outline"
          >
            <template v-slot:label="{ item }">
              <v-simple-table>
                <template v-slot:default v-if="item._id == 1">
                  <thead>
                    <tr>
                      <th class="text-left">
                        <v-icon
                          @click.stop="
                            sort.bin = sort.bin * -1;
                            sortPerField();
                          "
                          >mdi-sort</v-icon
                        >
                        Name
                      </th>
                      <th class="text-left" width="200px">
                        <v-select
                          @click.stop=""
                          dense
                          :items="brands"
                          @change="sortPerField()"
                          v-model="brand"
                          label="Brand"
                          multiple
                          hide-details
                        ></v-select>
                      </th>
                      <th class="text-left" width="80px">Count (on hand)</th>
                      <th class="text-left" width="200px">Last Check</th>
                    </tr>
                  </thead>
                </template>

                <template v-slot:default v-else>
                  <thead v-if="!item.item">
                    <tr>
                      <th class="text-left">
                        {{ item.name || item.item.name || "asd" }}
                        <span
                          ><b>{{ item.pallet ? "(Pallete)" : "" }}</b></span
                        >
                      </th>
                      <th class="text-left" width="200px">
                        {{
                          Array.isArray(item.manufacturergroup)
                            ? item.manufacturergroup.join(", ")
                            : "asd"
                        }}
                      </th>
                      <th class="text-left" width="80px">
                        {{
                          item.children
                            ? item.children.filter((v) => {
                                return v.children
                                  ? v.children.find((v2) =>
                                      v2.item.name.includes(search)
                                    )
                                  : v.item.name.includes(search);
                              }).length
                            : ""
                        }}
                        ({{
                          item.children && item.children.length
                            ? item.children.reduce((t, v) => {
                                if (v.children)
                                  t += v.children.reduce((t2, v2) => {
                                    if (
                                      !search ||
                                      v2.item.name.includes(search)
                                    )
                                      t2 += v2.quantityonhand;
                                    return t2;
                                  }, 0);
                                else if (
                                  !search ||
                                  v.item.name.includes(search)
                                )
                                  t += v.quantityonhand;
                                return t;
                              }, 0)
                            : quantityonhand
                        }})
                      </th>
                      <th class="text-left" width="200px">
                        {{
                          item.laststockcheck
                            ? new Date(item.laststockcheck)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>

              <v-simple-table v-if="item.item">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        <router-link
                          :to="{
                            path: `/items/inventoryitem/view/${item.item._id}`,
                          }"
                          class="link"
                          >{{ item.item.name }}</router-link
                        >
                      </th>
                      <th class="text-left" width="200px">
                        {{ item.manufacturergroup }}
                      </th>
                      <th width="80px">{{ item.quantityonhand }}</th>
                      <th class="text-left" width="200px">
                        <span class="d-block">{{
                          item.laststockcheck
                            ? new Date(item.laststockcheck)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}</span>
                        <small>{{ item.stockchecktype }}</small>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>
            </template>
          </v-treeview>
        </v-card-text>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" md="6">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                outlined
                dense
                :items="employees"
                v-model="stockcheck.employee"
                disabled
                label="Who Checked"
              ></v-select
            ></v-col>
            <v-col>
              <v-select
                outlined
                dense
                label="Location"
                v-model="stockcheck.location"
                :items="locations"
                required
                :error="!stockcheck.location"
              ></v-select
            ></v-col>
          </v-row>
          <v-combobox
            dense
            label="Description"
            v-model="stockcheck.description"
            :items="descriptions"
            outlined
          ></v-combobox>
          <v-data-table
            :headers="headers"
            v-model="stockcheck.stockchecks"
            show-select
            :items="stockcheck.stockchecks"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :search="search"
            :footer-props="{ itemsPerPageOptions: rows }"
            :custom-filter="filter"
          >
            <template v-slot:item.item="{ item }">
              <router-link
                :to="{
                  path: `/items/item/view/${item.item._id}`,
                }"
                class="link"
                >{{ item.item.name }}</router-link
              >
            </template>
          </v-data-table>
          <v-btn
            class="mt-4"
            tile
            block
            color="primary"
            :disabled="!stockcheck.stockchecks.length"
            @click="createStockCheck"
          >
            Create Stock Check</v-btn
          >
        </v-card-text>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <!-- <v-card-actions>
      <v-btn text @click="tree = []"> Reset </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="white--text" color="green darken-1" depressed>
        Save
        <v-icon right> mdi-content-save </v-icon>
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";

export default {
  data: () => ({
    stockcheck: {
      mode: "create",
      recordtype: "stockcheck",
      collection: "transactions",
      location: null,
      stockchecks: [],
      employee: null,
      description: "",
    },
    descriptions: [
      "Annual Stock Check",
      "Weekly Stock Check",
      "Daily Stock Check",
      "Verifcation",
    ],
    menufrom: false,
    datefrom: new Date(new Date().getFullYear(), 0, 2)
      .toISOString()
      .substr(0, 10),
    brands: [],
    brand: [],
    checked: 0,
    onhandonly: true,
    bins: 0,
    databin: [],
    loading: false,
    tree: [],
    sort: {
      bin: 1,
      laststockcheck: 1,
    },
    sectors: [],
    items: [
      {
        _id: 1,
        name: "All Locations (bins)",
        children: [],
      },
    ],
    employees: [],
    selected: [],
    locations: [
      { text: "PL", value: "5e0dbaba9e33df43f0b3a495" },
      { text: "NL", value: "66e04fcba16b2e1e7d873be8" },
    ],
    headers: [
      { text: "Item", value: "item" },
      { text: "Bin", value: "bin" },
      { text: "on Hand", value: "quantityonhand" },
    ],
    rows: [10],
    searchTree: "",
    search: "",
    pallet: [
      //PL
      "A3-1-1",
      "A3-1-2",
      "A3-1-3",
      "A3-2-1",
      "A3-2-2",
      "A3-2-3",
      "A3-3-1",
      "A3-3-2",
      "A3-3-3",
      "A3-4-1",
      "A3-4-2",
      "A3-4-3",
      "A3-5-1",
      "A3-5-2",
      "A3-5-3",
      "A3-6-1",
      "A3-6-2",
      "A3-6-3",
      "A4-1-1",
      "A4-1-2",
      "A4-1-3",
      "A4-2-1",
      "A4-2-2",
      "A4-2-3",
      "A4-3-1",
      "A4-3-2",
      "A4-3-3",
      "A4-4-1",
      "A4-4-2",
      "A4-4-3",
      "A4-5-1",
      "A4-5-2",
      "A4-5-3",
      "A4-6-1",
      "A4-6-2",
      "A4-6-3",
      "A5-1-1",
      "A5-1-2",
      "A5-1-3",
      "A5-2-1",
      "A5-2-2",
      "A5-2-3",
      "A5-3-1",
      "A5-3-2",
      "A5-3-3",
      "A5-4-1",
      "A5-4-2",
      "A5-4-3",
      "A5-5-1",
      "A5-5-2",
      "A5-5-3",
      "A5-6-1",
      "A5-6-2",
      "A5-6-3",
      "A6-1-1",
      "A6-1-2",
      "A6-1-3",
      "A6-2-1",
      "A6-2-2",
      "A6-2-3",
      "A6-3-1",
      "A6-3-2",
      "A6-3-3",
      "A6-4-1",
      "A6-4-2",
      "A6-4-3",
      "A6-5-1",
      "A6-5-2",
      "A6-5-3",
      "A6-6-1",
      "A6-6-2",
      "A6-6-3",
      "A7-1-1",
      "A7-1-2",
      "A7-1-3",
      "A7-2-1",
      "A7-2-2",
      "A7-2-3",
      "A7-3-1",
      "A7-3-2",
      "A7-3-3",
      "A7-4-1",
      "A7-4-2",
      "A7-4-3",
      "A7-5-1",
      "A7-5-2",
      "A7-5-3",
      "A7-6-1",
      "A7-6-2",
      "A7-6-3",
      "AA1-2-1",
      "AA1-2-2",
      "AA1-2-3",
      "AA1-3-1",
      "AA1-3-2",
      "AA1-3-3",
      "AA2-2-1",
      "AA2-2-2",
      "AA2-2-3",
      "AA2-3-1",
      "AA2-3-2",
      "AA2-3-3",
      "AA3-2-1",
      "AA3-2-2",
      "AA3-2-3",
      "AA3-3-1",
      "AA3-3-2",
      "AA3-3-3",
      "AA3-4-1",
      "AA3-4-2",
      "AA3-4-3",
      "AA4-1-1",
      "AA4-1-2",
      "AA4-2-1",
      "AA4-2-2",
      "AA4-3-1",
      "AA4-3-2",
      "AA4-4-1",
      "AA4-4-2",
      "AA4-5-1",
      "AA4-5-2",
      "AA5-1-1",
      "AA5-1-2",
      "AA5-2-1",
      "AA5-2-2",
      "AA5-3-1",
      "AA5-3-2",
      "AA5-4-1",
      "AA5-4-2",
      "AA5-5-1",
      "AA5-5-2",
      "AA6-1-1",
      "AA6-1-2",
      "AA6-2-1",
      "AA6-2-2",
      "AA6-3-1",
      "AA6-3-2",
      "AA6-4-1",
      "AA6-4-2",
      "AA6-5-1",
      "AA6-5-2",
      "AA7-1-1",
      "AA7-1-2",
      "AA7-2-1",
      "AA7-2-2",
      "AA7-3-1",
      "AA7-3-2",
      "AA7-4-1",
      "AA7-4-2",
      "AA7-5-1",
      "AA7-5-2",
      "AA8-1-1",
      "AA8-1-2",
      "AA8-2-1",
      "AA8-2-2",
      "AA8-3-1",
      "AA8-3-2",
      "AA8-4-1",
      "AA8-4-2",
      "AA8-5-1",
      "AA8-5-2",
      "AA9-1-1",
      "AA9-1-2",
      "AA9-2-1",
      "AA9-2-2",
      "AA9-3-1",
      "AA9-3-2",
      "AA9-4-1",
      "AA9-4-2",
      "AA9-5-1",
      "AA9-5-2",
      "AG1-1-1",
      "AG1-1-2",
      "AG1-1-3",
      "AG1-2-1",
      "AG1-2-2",
      "AG1-2-3",
      "AG1-3-1",
      "AG1-3-2",
      "AG1-3-3",
      "AG1-4-1",
      "AG1-4-2",
      "AG1-4-3",
      "AG1-5-1",
      "AG1-5-2",
      "AG1-5-3",
      "AG2-1-1",
      "AG2-1-2",
      "AG2-1-3",
      "AG2-2-1",
      "AG2-2-2",
      "AG2-2-3",
      "AG2-3-1",
      "AG2-3-2",
      "AG2-3-3",
      "AG2-4-1",
      "AG2-4-2",
      "AG2-4-3",
      "AG2-5-1",
      "AG2-5-2",
      "AG2-5-3",
      "AG3-1-1",
      "AG3-1-2",
      "AG3-1-3",
      "AG3-2-1",
      "AG3-2-2",
      "AG3-2-3",
      "AG3-3-1",
      "AG3-3-2",
      "AG3-3-3",
      "AG3-4-1",
      "AG3-4-2",
      "AG3-4-3",
      "AG3-5-1",
      "AG3-5-2",
      "AG3-5-3",
      "AG4-1-1",
      "AG4-1-2",
      "AG4-1-3",
      "AG4-2-1",
      "AG4-2-2",
      "AG4-2-3",
      "AG4-3-1",
      "AG4-3-2",
      "AG4-3-3",
      "AG4-4-1",
      "AG4-4-2",
      "AG4-4-3",
      "AG4-5-1",
      "AG4-5-2",
      "AG4-5-3",
      "AG5-1-1",
      "AG5-2-1",
      "AG5-3-1",
      "AG5-4-1",
      "AG6-1-1",
      "AG6-1-2",
      "AG6-2-1",
      "AG6-2-2",
      "AG7-1-1",
      "AG7-1-2",
      "AG7-1-3",
      "AG7-2-1",
      "AG7-2-2",
      "AG7-2-3",
      "AG7-3-1",
      "AG7-3-2",
      "AG7-3-3",
      "AG7-4-1",
      "AG7-4-2",
      "AG7-4-3",
      "AG8-1-1",
      "AG8-1-2",
      "AG8-1-3",
      "AG8-2-1",
      "AG8-2-2",
      "AG8-2-3",
      "AG8-3-1",
      "AG8-3-2",
      "AG8-3-3",
      "AG8-4-1",
      "AG8-4-2",
      "AG8-4-3",
      "AH1-1-1",
      "AH1-1-2",
      "AH1-1-3",
      "AH1-2-1",
      "AH1-2-2",
      "AH1-2-3",
      "AH1-3-1",
      "AH1-3-2",
      "AH1-3-3",
      "AH1-4-1",
      "AH1-4-2",
      "AH1-4-3",
      "AH2-1-1",
      "AH2-1-2",
      "AH2-1-3",
      "AH2-2-1",
      "AH2-2-2",
      "AH2-2-3",
      "AH2-3-1",
      "AH2-3-2",
      "AH2-3-3",
      "AH2-4-1",
      "AH2-4-2",
      "AH2-4-3",
      "AH3-1-1",
      "AH3-1-2",
      "AH3-1-3",
      "AH3-2-1",
      "AH3-2-2",
      "AH3-2-3",
      "AH3-3-1",
      "AH3-3-2",
      "AH3-3-3",
      "AH3-4-1",
      "AH3-4-2",
      "AH3-4-3",
      "AH4-1-1",
      "AH4-1-2",
      "AH4-1-3",
      "AH4-2-1",
      "AH4-2-2",
      "AH4-2-3",
      "AH4-3-1",
      "AH4-3-2",
      "AH4-3-3",
      "AH4-4-1",
      "AH4-4-2",
      "AH4-4-3",
      "AH5-1-1",
      "AH5-1-2",
      "AH5-2-1",
      "AH5-2-2",
      "AH5-3-1",
      "AH5-3-2",
      "AH5-4-1",
      "AH5-4-2",
      "AH5-5-1",
      "AH5-5-2",
      "AH6-1-1",
      "AH6-1-2",
      "AH6-1-3",
      "AH6-2-1",
      "AH6-2-2",
      "AH6-2-3",
      "AH7-1-1",
      "AH7-1-2",
      "AH7-2-1",
      "AH7-2-2",
      "AH7-3-1",
      "AH7-3-2",
      "AH7-4-1",
      "AH7-4-2",
      "AH8-1-1",
      "AH8-1-2",
      "AH8-1-3",
      "AH8-2-1",
      "AH8-2-2",
      "AH8-2-3",
      "AH8-3-1",
      "AH8-3-2",
      "AH8-3-3",
      "AH8-4-1",
      "AH8-4-2",
      "AH8-4-3",
      "AI1-1-1",
      "AI1-1-2",
      "AI1-1-3",
      "AI1-2-1",
      "AI1-2-2",
      "AI1-2-3",
      "AI1-3-1",
      "AI1-3-2",
      "AI1-3-3",
      "AI1-4-1",
      "AI1-4-2",
      "AI1-4-3",
      "AI1-5-1",
      "AI1-5-2",
      "AI1-5-3",
      "AI2-1-1",
      "AI2-1-2",
      "AI2-1-3",
      "AI2-2-1",
      "AI2-2-2",
      "AI2-2-3",
      "AI2-3-1",
      "AI2-3-2",
      "AI2-3-3",
      "AI2-4-1",
      "AI2-4-2",
      "AI2-4-3",
      "AI2-5-1",
      "AI2-5-2",
      "AI2-5-3",
      "AI3-1-1",
      "AI3-1-2",
      "AI3-1-3",
      "AI3-2-1",
      "AI3-2-2",
      "AI3-2-3",
      "AI3-3-1",
      "AI3-3-2",
      "AI3-3-3",
      "AI3-4-1",
      "AI3-4-2",
      "AI3-4-3",
      "AI3-5-1",
      "AI3-5-2",
      "AI3-5-3",
      "AI4-1-1",
      "AI4-1-2",
      "AI4-1-3",
      "AI4-2-1",
      "AI4-2-2",
      "AI4-2-3",
      "AI4-3-1",
      "AI4-3-2",
      "AI4-3-3",
      "AI4-4-1",
      "AI4-4-2",
      "AI4-4-3",
      "AI4-5-1",
      "AI4-5-2",
      "AI4-5-3",
      "AI5-1-1",
      "AI5-2-1",
      "AI5-3-1",
      "AI5-4-1",
      "AI6-1-1",
      "AI6-1-2",
      "AI6-1-3",
      "AI6-2-1",
      "AI6-2-2",
      "AI6-2-3",
      "AI7-1-1",
      "AI7-1-2",
      "AI7-2-1",
      "AI7-2-2",
      "AI7-3-1",
      "AI7-3-2",
      "AI7-4-1",
      "AI7-4-2",
      "AI8-1-1",
      "AI8-1-2",
      "AI8-1-3",
      "AI8-2-1",
      "AI8-2-2",
      "AI8-2-3",
      "AI8-3-1",
      "AI8-3-2",
      "AI8-3-3",
      "AI8-4-1",
      "AI8-4-2",
      "AI8-4-3",
      "AJ1-1-1",
      "AJ1-1-2",
      "AJ1-1-3",
      "AJ1-2-1",
      "AJ1-2-2",
      "AJ1-2-3",
      "AJ1-3-1",
      "AJ1-3-2",
      "AJ1-3-3",
      "AJ1-4-1",
      "AJ1-4-2",
      "AJ1-4-3",
      "AJ1-5-1",
      "AJ1-5-2",
      "AJ1-5-3",
      "AJ2-1-1",
      "AJ2-1-2",
      "AJ2-1-3",
      "AJ2-2-1",
      "AJ2-2-2",
      "AJ2-2-3",
      "AJ2-3-1",
      "AJ2-3-2",
      "AJ2-3-3",
      "AJ2-4-1",
      "AJ2-4-2",
      "AJ2-4-3",
      "AJ2-5-1",
      "AJ2-5-2",
      "AJ2-5-3",
      "AJ3-1-1",
      "AJ3-1-2",
      "AJ3-1-3",
      "AJ3-2-1",
      "AJ3-2-2",
      "AJ3-2-3",
      "AJ3-3-1",
      "AJ3-3-2",
      "AJ3-3-3",
      "AJ3-4-1",
      "AJ3-4-2",
      "AJ3-4-3",
      "AJ3-5-1",
      "AJ3-5-2",
      "AJ3-5-3",
      "AJ4-1-1",
      "AJ4-1-2",
      "AJ4-1-3",
      "AJ4-2-1",
      "AJ4-2-2",
      "AJ4-2-3",
      "AJ4-3-1",
      "AJ4-3-2",
      "AJ4-3-3",
      "AJ4-4-1",
      "AJ4-4-2",
      "AJ4-4-3",
      "AJ4-5-1",
      "AJ4-5-2",
      "AJ4-5-3",
      "AJ5-1-1",
      "AJ5-1-2",
      "AJ5-2-1",
      "AJ5-2-2",
      "AJ5-3-1",
      "AJ5-3-2",
      "AJ5-4-1",
      "AJ5-4-2",
      "AJ5-5-1",
      "AJ5-5-2",
      "AJ5-6-1",
      "AJ5-6-2",
      "AJ5-7-1",
      "AJ5-7-2",
      "AJ6-1-1",
      "AJ6-1-2",
      "AJ6-1-3",
      "AJ6-2-1",
      "AJ6-2-2",
      "AJ6-2-3",
      "AJ7-1-1",
      "AJ7-1-2",
      "AJ7-2-1",
      "AJ7-2-2",
      "AJ7-3-1",
      "AJ7-3-2",
      "AJ7-4-1",
      "AJ7-4-2",
      "AJ8-1-1",
      "AJ8-1-2",
      "AJ8-1-3",
      "AJ8-2-1",
      "AJ8-2-2",
      "AJ8-2-3",
      "AJ8-3-1",
      "AJ8-3-2",
      "AJ8-3-3",
      "AJ8-4-1",
      "AJ8-4-2",
      "AJ8-4-3",
      "AK1-1-1",
      "AK1-1-2",
      "AK1-1-3",
      "AK1-2-1",
      "AK1-2-2",
      "AK1-2-3",
      "AK1-3-1",
      "AK1-3-2",
      "AK1-3-3",
      "AK1-4-1",
      "AK1-4-2",
      "AK1-4-3",
      "AK1-5-1",
      "AK1-5-2",
      "AK1-5-3",
      "AK2-1-1",
      "AK2-1-2",
      "AK2-1-3",
      "AK2-2-1",
      "AK2-2-2",
      "AK2-2-3",
      "AK2-3-1",
      "AK2-3-2",
      "AK2-3-3",
      "AK2-4-1",
      "AK2-4-2",
      "AK2-4-3",
      "AK2-5-1",
      "AK2-5-2",
      "AK2-5-3",
      "AK3-1-1",
      "AK3-1-2",
      "AK3-1-3",
      "AK3-2-1",
      "AK3-2-2",
      "AK3-2-3",
      "AK3-3-1",
      "AK3-3-2",
      "AK3-3-3",
      "AK3-4-1",
      "AK3-4-2",
      "AK3-4-3",
      "AK3-5-1",
      "AK3-5-2",
      "AK3-5-3",
      "AK4-1-1",
      "AK4-1-2",
      "AK4-1-3",
      "AK4-2-1",
      "AK4-2-2",
      "AK4-2-3",
      "AK4-3-1",
      "AK4-3-2",
      "AK4-3-3",
      "AK4-4-1",
      "AK4-4-2",
      "AK4-4-3",
      "AK4-5-1",
      "AK4-5-2",
      "AK4-5-3",
      "AK6-1-1",
      "AK6-1-2",
      "AK6-1-3",
      "AK6-2-1",
      "AK6-2-2",
      "AK6-2-3",
      "AK7-1-1",
      "AK7-1-2",
      "AK7-2-1",
      "AK7-2-2",
      "AK7-3-1",
      "AK7-3-2",
      "AK7-4-1",
      "AK7-4-2",
      "AK8-1-1",
      "AK8-1-2",
      "AK8-1-3",
      "AK8-2-1",
      "AK8-2-2",
      "AK8-2-3",
      "AK8-3-1",
      "AK8-3-2",
      "AK8-3-3",
      "AK8-4-1",
      "AK8-4-2",
      "AK8-4-3",
      "B1-1-1",
      "B1-1-2",
      "B1-2-1",
      "B1-2-2",
      "B1-3-1",
      "B1-3-2",
      "B1-4-1",
      "B1-4-2",
      "B1-5-1",
      "B1-5-2",
      "B2-1-1",
      "B2-1-2",
      "B2-2-1",
      "B2-2-2",
      "B2-3-1",
      "B2-3-2",
      "B2-4-1",
      "B2-4-2",
      "B2-5-1",
      "B2-5-2",
      "B3-1-1",
      "B4-1-1",
      "B4-1-2",
      "B4-2-1",
      "B4-2-2",
      "B4-3-1",
      "B4-3-2",
      "B4-4-1",
      "B4-4-2",
      "B4-5-1",
      "B4-5-2",
      "B5-1-1",
      "B5-1-2",
      "B5-2-1",
      "B5-2-2",
      "B5-3-1",
      "B5-3-2",
      "B5-4-1",
      "B5-4-2",
      "B5-5-1",
      "B5-5-2",
      "C1-1-1",
      "C1-1-2",
      "C1-2-1",
      "C1-2-2",
      "C1-3-1",
      "C1-3-2",
      "C1-4-1",
      "C1-4-2",
      "C2-1-1",
      "C2-1-2",
      "C2-2-1",
      "C2-2-2",
      "C2-3-1",
      "C2-3-2",
      "C2-4-1",
      "C2-4-2",
      "C3-1-1",
      "C3-1-2",
      "C3-2-1",
      "C3-2-2",
      "C3-3-1",
      "C3-3-2",
      "C3-4-1",
      "C3-4-2",
      "F1-1-1",
      "F1-1-2",
      "F1-1-3",
      "F1-2-1",
      "F1-2-2",
      "F1-2-3",
      "F1-3-1",
      "F1-3-2",
      "F1-3-3",
      "F1-4-1",
      "F1-4-2",
      "F1-4-3",
      "F1-5-1",
      "F1-5-2",
      "F1-5-3",
      "F1-6-1",
      "F1-6-2",
      "F1-6-3",
      "F2-1-1",
      "F2-1-2",
      "F2-1-3",
      "F2-2-1",
      "F2-2-2",
      "F2-2-3",
      "F2-3-1",
      "F2-3-2",
      "F2-3-3",
      "F2-4-1",
      "F2-4-2",
      "F2-4-3",
      "F2-5-1",
      "F2-5-2",
      "F2-5-3",
      "F2-6-1",
      "F2-6-2",
      "F2-6-3",
      "F3-1-1",
      "F3-1-2",
      "F3-1-3",
      "F3-2-1",
      "F3-2-2",
      "F3-2-3",
      "F3-3-1",
      "F3-3-2",
      "F3-3-3",
      "F3-4-1",
      "F3-4-2",
      "F3-4-3",
      "F3-5-1",
      "F3-5-2",
      "F3-5-3",
      "F3-6-1",
      "F3-6-2",
      "F3-6-3",
      "F4-1-1",
      "F4-1-2",
      "F4-1-3",
      "F4-2-1",
      "F4-2-2",
      "F4-2-3",
      "F4-3-1",
      "F4-3-2",
      "F4-3-3",
      "F4-4-1",
      "F4-4-2",
      "F4-4-3",
      "F4-5-1",
      "F4-5-2",
      "F4-5-3",
      "F4-6-1",
      "F4-6-2",
      "F4-6-3",
      "X1",
      "X10",
      "X2",
      "X3",
      "X4",
      "X5",
      "X6",
      "X7",
      "X8",
      "X9",
      "X10",
      "X11",
      "X12",
      "X13",
      "X14",

      //NL

      "AA0101A01",
      "AA0101A02",
      "AA0101A03",
      "AA0101B01",
      "AA0101B02",
      "AA0101B03",
      "AA0101C01",
      "AA0101C02",
      "AA0101C03",
      "AA0101D01",
      "AA0101D02",
      "AA0101D03",
      "AA0101E01",
      "AA0101E02",
      "AA0101E03",
      "AA0101F01",
      "AA0101F02",
      "AA0101F03",
      "AA0101G01",
      "AA0101G02",
      "AA0101G03",
      "AA0101H01",
      "AA0101H02",
      "AA0101H03",
      "AA0102A01",
      "AA0102A02",
      "AA0102A03",
      "AA0102B01",
      "AA0102B02",
      "AA0102B03",
      "AA0102C01",
      "AA0102C02",
      "AA0102C03",
      "AA0102D01",
      "AA0102D02",
      "AA0102D03",
      "AA0102E01",
      "AA0102E02",
      "AA0102E03",
      "AA0102F01",
      "AA0102F02",
      "AA0102F03",
      "AA0102G01",
      "AA0102G02",
      "AA0102G03",
      "AA0102H01",
      "AA0102H02",
      "AA0102H03",
      "AA0103A01",
      "AA0103A02",
      "AA0103A03",
      "AA0103A04",
      "AA0103B01",
      "AA0103B02",
      "AA0103B03",
      "AA0103B04",
      "AA0103C01",
      "AA0103C02",
      "AA0103C03",
      "AA0103C04",
      "AA0103D01",
      "AA0103D02",
      "AA0103D03",
      "AA0103D04",
      "AA0103E01",
      "AA0103E02",
      "AA0103E03",
      "AA0103E04",
      "AA0103F01",
      "AA0103F02",
      "AA0103F03",
      "AA0103F04",
      "AA0103G01",
      "AA0103G02",
      "AA0103G03",
      "AA0103G04",
      "AA0103H01",
      "AA0103H02",
      "AA0103H03",
      "AA0103H04",
      "AA0104A01",
      "AA0104A02",
      "AA0104A03",
      "AA0104B01",
      "AA0104B02",
      "AA0104B03",
      "AA0104C01",
      "AA0104C02",
      "AA0104C03",
      "AA0104D01",
      "AA0104D02",
      "AA0104D03",
      "AA0104E01",
      "AA0104E02",
      "AA0104E03",
      "AA0104F01",
      "AA0104F02",
      "AA0104F03",
      "AA0104G01",
      "AA0104G02",
      "AA0104G03",
      "AA0104H01",
      "AA0104H02",
      "AA0104H03",
      "AA0105A01",
      "AA0105A02",
      "AA0105A03",
      "AA0105B01",
      "AA0105B02",
      "AA0105B03",
      "AA0105C01",
      "AA0105C02",
      "AA0105C03",
      "AA0105D01",
      "AA0105D02",
      "AA0105D03",
      "AA0105E01",
      "AA0105E02",
      "AA0105E03",
      "AA0105F01",
      "AA0105F02",
      "AA0105F03",
      "AA0105G01",
      "AA0105G02",
      "AA0105G03",
      "AA0105H01",
      "AA0105H02",
      "AA0105H03",
      "AA0106A01",
      "AA0106A02",
      "AA0106A03",
      "AA0106B01",
      "AA0106B02",
      "AA0106B03",
      "AA0106C01",
      "AA0106C02",
      "AA0106C03",
      "AA0106D01",
      "AA0106D02",
      "AA0106D03",
      "AA0106E01",
      "AA0106E02",
      "AA0106E03",
      "AA0106F01",
      "AA0106F02",
      "AA0106F03",
      "AA0106G01",
      "AA0106G02",
      "AA0106G03",
      "AA0106H01",
      "AA0106H02",
      "AA0106H03",
      "AA0107A01",
      "AA0107A02",
      "AA0107B01",
      "AA0107B02",
      "AA0107C01",
      "AA0107C02",
      "AA0107D01",
      "AA0107D02",
      "AA0107D03",
      "AA0107D04",
      "AA0107E01",
      "AA0107E02",
      "AA0107E03",
      "AA0107E04",
      "AA0107F01",
      "AA0107F02",
      "AA0107F03",
      "AA0107F04",
      "AA0107G01",
      "AA0107G02",
      "AA0107G03",
      "AA0107G04",
      "AA0107H01",
      "AA0107H02",
      "AA0107H03",
      "AA0107H04",
      "AA0107I01",
      "AA0107I02",
      "AA0107I03",
      "AA0107I04",
      "AA0201A01",
      "AA0201A02",
      "AA0201A03",
      "AA0201A04",
      "AA0201B01",
      "AA0201B02",
      "AA0201B03",
      "AA0201B04",
      "AA0201C01",
      "AA0201C02",
      "AA0201C03",
      "AA0201C04",
      "AA0201D01",
      "AA0201D02",
      "AA0201D03",
      "AA0201D04",
      "AA0201E01",
      "AA0201E02",
      "AA0201E03",
      "AA0201E04",
      "AA0201F01",
      "AA0201F02",
      "AA0201F03",
      "AA0201F04",
      "AA0201G01",
      "AA0201G02",
      "AA0201G03",
      "AA0201G04",
      "AA0201H01",
      "AA0201H02",
      "AA0201H03",
      "AA0201H04",
      "AA0201I01",
      "AA0201I02",
      "AA0201I03",
      "AA0201I04",
      "AA0202A01",
      "AA0202A02",
      "AA0202A03",
      "AA0202B01",
      "AA0202B02",
      "AA0202B03",
      "AA0202C01",
      "AA0202C02",
      "AA0202C03",
      "AA0202D01",
      "AA0202D02",
      "AA0202D03",
      "AA0202E01",
      "AA0202E02",
      "AA0202E03",
      "AA0202F01",
      "AA0202F02",
      "AA0202F03",
      "AA0202G01",
      "AA0202G02",
      "AA0202G03",
      "AA0202H01",
      "AA0202H02",
      "AA0202H03",
      "AA0203A01",
      "AA0203A02",
      "AA0203A03",
      "AA0203B01",
      "AA0203B02",
      "AA0203B03",
      "AA0203C01",
      "AA0203C02",
      "AA0203C03",
      "AA0203D01",
      "AA0203D02",
      "AA0203D03",
      "AA0203E01",
      "AA0203E02",
      "AA0203E03",
      "AA0203F01",
      "AA0203F02",
      "AA0203F03",
      "AA0203G01",
      "AA0203G02",
      "AA0203G03",
      "AA0203H01",
      "AA0203H02",
      "AA0203H03",
      "AA0204A01",
      "AA0204A02",
      "AA0204A03",
      "AA0204B01",
      "AA0204B02",
      "AA0204B03",
      "AA0204C01",
      "AA0204C02",
      "AA0204C03",
      "AA0204D01",
      "AA0204D02",
      "AA0204D03",
      "AA0204E01",
      "AA0204E02",
      "AA0204E03",
      "AA0204F01",
      "AA0204F02",
      "AA0204F03",
      "AA0204G01",
      "AA0204G02",
      "AA0204G03",
      "AA0204H01",
      "AA0204H02",
      "AA0204H03",
      "AA0205A01",
      "AA0205A02",
      "AA0205A03",
      "AA0205A04",
      "AA0205B01",
      "AA0205B02",
      "AA0205B03",
      "AA0205B04",
      "AA0205C01",
      "AA0205C02",
      "AA0205C03",
      "AA0205C04",
      "AA0205D01",
      "AA0205D02",
      "AA0205D03",
      "AA0205D04",
      "AA0205E01",
      "AA0205E02",
      "AA0205E03",
      "AA0205E04",
      "AA0205F01",
      "AA0205F02",
      "AA0205F03",
      "AA0205F04",
      "AA0205G01",
      "AA0205G02",
      "AA0205G03",
      "AA0205G04",
      "AA0205H01",
      "AA0205H02",
      "AA0205H03",
      "AA0205H04",
      "AA0206A01",
      "AA0206A02",
      "AA0206A03",
      "AA0206B01",
      "AA0206B02",
      "AA0206B03",
      "AA0206C01",
      "AA0206C02",
      "AA0206C03",
      "AA0206D01",
      "AA0206D02",
      "AA0206D03",
      "AA0206E01",
      "AA0206E02",
      "AA0206E03",
      "AA0206F01",
      "AA0206F02",
      "AA0206F03",
      "AA0206G01",
      "AA0206G02",
      "AA0206G03",
      "AA0206H01",
      "AA0206H02",
      "AA0206H03",
      "AA0301A01",
      "AA0301A02",
      "AA0301A03",
      "AA0301B01",
      "AA0301B02",
      "AA0301B03",
      "AA0301C01",
      "AA0301C02",
      "AA0301C03",
      "AA0301D01",
      "AA0301D02",
      "AA0301D03",
      "AA0301E01",
      "AA0301E02",
      "AA0301E03",
      "AA0301F01",
      "AA0301F02",
      "AA0301F03",
      "AA0301G01",
      "AA0301G02",
      "AA0301G03",
      "AA0301H01",
      "AA0301H02",
      "AA0301H03",
      "AA0302A01",
      "AA0302A02",
      "AA0302A03",
      "AA0302A04",
      "AA0302B01",
      "AA0302B02",
      "AA0302B03",
      "AA0302B04",
      "AA0302C01",
      "AA0302C02",
      "AA0302C03",
      "AA0302C04",
      "AA0302D01",
      "AA0302D02",
      "AA0302D03",
      "AA0302D04",
      "AA0302E01",
      "AA0302E02",
      "AA0302E03",
      "AA0302E04",
      "AA0302F01",
      "AA0302F02",
      "AA0302F03",
      "AA0302F04",
      "AA0302G01",
      "AA0302G02",
      "AA0302G03",
      "AA0302G04",
      "AA0302H01",
      "AA0302H02",
      "AA0302H03",
      "AA0302H04",
      "AA0303A01",
      "AA0303A02",
      "AA0303A03",
      "AA0303B01",
      "AA0303B02",
      "AA0303B03",
      "AA0303C01",
      "AA0303C02",
      "AA0303C03",
      "AA0303D01",
      "AA0303D02",
      "AA0303D03",
      "AA0303E01",
      "AA0303E02",
      "AA0303E03",
      "AA0303F01",
      "AA0303F02",
      "AA0303F03",
      "AA0303G01",
      "AA0303G02",
      "AA0303G03",
      "AA0303H01",
      "AA0303H02",
      "AA0303H03",
      "AA0304A01",
      "AA0304A02",
      "AA0304A03",
      "AA0304B01",
      "AA0304B02",
      "AA0304B03",
      "AA0304C01",
      "AA0304C02",
      "AA0304C03",
      "AA0304D01",
      "AA0304D02",
      "AA0304D03",
      "AA0304E01",
      "AA0304E02",
      "AA0304E03",
      "AA0304F01",
      "AA0304F02",
      "AA0304F03",
      "AA0304G01",
      "AA0304G02",
      "AA0304G03",
      "AA0304H01",
      "AA0304H02",
      "AA0304H03",
      "AA0305A01",
      "AA0305A02",
      "AA0305A03",
      "AA0305B01",
      "AA0305B02",
      "AA0305B03",
      "AA0305C01",
      "AA0305C02",
      "AA0305C03",
      "AA0305D01",
      "AA0305D02",
      "AA0305D03",
      "AA0305E01",
      "AA0305E02",
      "AA0305E03",
      "AA0305F01",
      "AA0305F02",
      "AA0305F03",
      "AA0305G01",
      "AA0305G02",
      "AA0305G03",
      "AA0305H01",
      "AA0305H02",
      "AA0305H03",
      "AA0306A01",
      "AA0306A02",
      "AA0306A03",
      "AA0306A04",
      "AA0306B01",
      "AA0306B02",
      "AA0306B03",
      "AA0306B04",
      "AA0306C01",
      "AA0306C02",
      "AA0306C03",
      "AA0306C04",
      "AA0306D01",
      "AA0306D02",
      "AA0306D03",
      "AA0306D04",
      "AA0306E01",
      "AA0306E02",
      "AA0306E03",
      "AA0306E04",
      "AA0306F01",
      "AA0306F02",
      "AA0306F03",
      "AA0306F04",
      "AA0306G01",
      "AA0306G02",
      "AA0306G03",
      "AA0306G04",
      "AA0306H01",
      "AA0306H02",
      "AA0306H03",
      "AA0306H04",
      "AA0306I01",
      "AA0306I02",
      "AA0306I03",
      "AA0306I04",
      "AA0401A01",
      "AA0401A02",
      "AA0401A03",
      "AA0401A04",
      "AA0401B01",
      "AA0401B02",
      "AA0401B03",
      "AA0401B04",
      "AA0401C01",
      "AA0401C02",
      "AA0401C03",
      "AA0401C04",
      "AA0401D01",
      "AA0401D02",
      "AA0401D03",
      "AA0401D04",
      "AA0401E01",
      "AA0401E02",
      "AA0401E03",
      "AA0401E04",
      "AA0401F01",
      "AA0401F02",
      "AA0401F03",
      "AA0401F04",
      "AA0401G01",
      "AA0401G02",
      "AA0401G03",
      "AA0401G04",
      "AA0401H01",
      "AA0401H02",
      "AA0401H03",
      "AA0401H04",
      "AA0401I01",
      "AA0401I02",
      "AA0401I03",
      "AA0401I04",
      "AA0402A01",
      "AA0402A02",
      "AA0402A03",
      "AA0402B01",
      "AA0402B02",
      "AA0402B03",
      "AA0402C01",
      "AA0402C02",
      "AA0402C03",
      "AA0402D01",
      "AA0402D02",
      "AA0402D03",
      "AA0402E01",
      "AA0402E02",
      "AA0402E03",
      "AA0402F01",
      "AA0402F02",
      "AA0402F03",
      "AA0402G01",
      "AA0402G02",
      "AA0402G03",
      "AA0402H01",
      "AA0402H02",
      "AA0402H03",
      "AA0403A01",
      "AA0403A02",
      "AA0403A03",
      "AA0403B01",
      "AA0403B02",
      "AA0403B03",
      "AA0403C01",
      "AA0403C02",
      "AA0403C03",
      "AA0403D01",
      "AA0403D02",
      "AA0403D03",
      "AA0403E01",
      "AA0403E02",
      "AA0403E03",
      "AA0403F01",
      "AA0403F02",
      "AA0403F03",
      "AA0403G01",
      "AA0403G02",
      "AA0403G03",
      "AA0403H01",
      "AA0403H02",
      "AA0403H03",
      "AA0404A01",
      "AA0404A02",
      "AA0404A03",
      "AA0404B01",
      "AA0404B02",
      "AA0404B03",
      "AA0404C01",
      "AA0404C02",
      "AA0404C03",
      "AA0404D01",
      "AA0404D02",
      "AA0404D03",
      "AA0404E01",
      "AA0404E02",
      "AA0404E03",
      "AA0404F01",
      "AA0404F02",
      "AA0404F03",
      "AA0404G01",
      "AA0404G02",
      "AA0404G03",
      "AA0404H01",
      "AA0404H02",
      "AA0404H03",
      "AA0405A01",
      "AA0405A02",
      "AA0405A03",
      "AA0405A04",
      "AA0405B01",
      "AA0405B02",
      "AA0405B03",
      "AA0405B04",
      "AA0405C01",
      "AA0405C02",
      "AA0405C03",
      "AA0405C04",
      "AA0405D01",
      "AA0405D02",
      "AA0405D03",
      "AA0405D04",
      "AA0405E01",
      "AA0405E02",
      "AA0405E03",
      "AA0405E04",
      "AA0405F01",
      "AA0405F02",
      "AA0405F03",
      "AA0405F04",
      "AA0405G01",
      "AA0405G02",
      "AA0405G03",
      "AA0405G04",
      "AA0405H01",
      "AA0405H02",
      "AA0405H03",
      "AA0405H04",
      "AA0406A01",
      "AA0406A02",
      "AA0406A03",
      "AA0406B01",
      "AA0406B02",
      "AA0406B03",
      "AA0406C01",
      "AA0406C02",
      "AA0406C03",
      "AA0406D01",
      "AA0406D02",
      "AA0406D03",
      "AA0406E01",
      "AA0406E02",
      "AA0406E03",
      "AA0406F01",
      "AA0406F02",
      "AA0406F03",
      "AA0406G01",
      "AA0406G02",
      "AA0406G03",
      "AA0406H01",
      "AA0406H02",
      "AA0406H03",
      "AB0501A01",
      "AB0501A02",
      "AB0501A03",
      "AB0501B01",
      "AB0501B02",
      "AB0501B03",
      "AB0501C01",
      "AB0501C02",
      "AB0501C03",
      "AB0501D01",
      "AB0501D02",
      "AB0501D03",
      "AB0501E01",
      "AB0501E02",
      "AB0501E03",
      "AB0501F01",
      "AB0501F02",
      "AB0501F03",
      "AB0501G01",
      "AB0501G02",
      "AB0501G03",
      "AB0501H01",
      "AB0501H02",
      "AB0501H03",
      "AB0501I01",
      "AB0501I02",
      "AB0501I03",
      "AB0501J01",
      "AB0501J02",
      "AB0501J03",
      "AB0502A01",
      "AB0502A02",
      "AB0502A03",
      "AB0502A04",
      "AB0502B01",
      "AB0502B02",
      "AB0502B03",
      "AB0502B04",
      "AB0502C01",
      "AB0502C02",
      "AB0502C03",
      "AB0502C04",
      "AB0502D01",
      "AB0502D02",
      "AB0502D03",
      "AB0502D04",
      "AB0502E01",
      "AB0502E02",
      "AB0502E03",
      "AB0502E04",
      "AB0502F01",
      "AB0502F02",
      "AB0502F03",
      "AB0502F04",
      "AB0502G01",
      "AB0502G02",
      "AB0502G03",
      "AB0502G04",
      "AB0502H01",
      "AB0502H02",
      "AB0502H03",
      "AB0502H04",
      "AB0502I01",
      "AB0502I02",
      "AB0502I03",
      "AB0502I04",
      "AB0502J01",
      "AB0502J02",
      "AB0502J03",
      "AB0502J04",
      "AB0503A01",
      "AB0503A02",
      "AB0503A03",
      "AB0503B01",
      "AB0503B02",
      "AB0503B03",
      "AB0503C01",
      "AB0503C02",
      "AB0503C03",
      "AB0503D01",
      "AB0503D02",
      "AB0503D03",
      "AB0503E01",
      "AB0503E02",
      "AB0503E03",
      "AB0503F01",
      "AB0503F02",
      "AB0503F03",
      "AB0503G01",
      "AB0503G02",
      "AB0503G03",
      "AB0503H01",
      "AB0503H02",
      "AB0503H03",
      "AB0503I01",
      "AB0503I02",
      "AB0503I03",
      "AB0503J01",
      "AB0503J02",
      "AB0503J03",
      "AB0504A01",
      "AB0504A02",
      "AB0504A03",
      "AB0504B01",
      "AB0504B02",
      "AB0504B03",
      "AB0504C01",
      "AB0504C02",
      "AB0504C03",
      "AB0504D01",
      "AB0504D02",
      "AB0504D03",
      "AB0504E01",
      "AB0504E02",
      "AB0504E03",
      "AB0504F01",
      "AB0504F02",
      "AB0504F03",
      "AB0504G01",
      "AB0504G02",
      "AB0504G03",
      "AB0504H01",
      "AB0504H02",
      "AB0504H03",
      "AB0504I01",
      "AB0504I02",
      "AB0504I03",
      "AB0504J01",
      "AB0504J02",
      "AB0504J03",
      "AB0505A01",
      "AB0505A02",
      "AB0505A03",
      "AB0505B01",
      "AB0505B02",
      "AB0505B03",
      "AB0505C01",
      "AB0505C02",
      "AB0505C03",
      "AB0505D01",
      "AB0505D02",
      "AB0505D03",
      "AB0505E01",
      "AB0505E02",
      "AB0505E03",
      "AB0505F01",
      "AB0505F02",
      "AB0505F03",
      "AB0505G01",
      "AB0505G02",
      "AB0505G03",
      "AB0505H01",
      "AB0505H02",
      "AB0505H03",
      "AB0505I01",
      "AB0505I02",
      "AB0505I03",
      "AB0505J01",
      "AB0505J02",
      "AB0505J03",
      "AB0506A01",
      "AB0506A02",
      "AB0506A03",
      "AB0506A04",
      "AB0506B01",
      "AB0506B02",
      "AB0506B03",
      "AB0506B04",
      "AB0506C01",
      "AB0506C02",
      "AB0506C03",
      "AB0506C04",
      "AB0506D01",
      "AB0506D02",
      "AB0506D03",
      "AB0506D04",
      "AB0506E01",
      "AB0506E02",
      "AB0506E03",
      "AB0506E04",
      "AB0506F01",
      "AB0506F02",
      "AB0506F03",
      "AB0506F04",
      "AB0506G01",
      "AB0506G02",
      "AB0506G03",
      "AB0506G04",
      "AB0506H01",
      "AB0506H02",
      "AB0506H03",
      "AB0506H04",
      "AB0506I01",
      "AB0506I02",
      "AB0506I03",
      "AB0506I04",
      "AB0506J01",
      "AB0506J02",
      "AB0506J03",
      "AB0506J04",
      "AB0506K01",
      "AB0506K02",
      "AB0506K03",
      "AB0506K04",
      "AB0601A01",
      "AB0601A02",
      "AB0601A03",
      "AB0601A04",
      "AB0601B01",
      "AB0601B02",
      "AB0601B03",
      "AB0601B04",
      "AB0601C01",
      "AB0601C02",
      "AB0601C03",
      "AB0601C04",
      "AB0601D01",
      "AB0601D02",
      "AB0601D03",
      "AB0601D04",
      "AB0601E01",
      "AB0601E02",
      "AB0601E03",
      "AB0601E04",
      "AB0601F01",
      "AB0601F02",
      "AB0601F03",
      "AB0601F04",
      "AB0601G01",
      "AB0601G02",
      "AB0601G03",
      "AB0601G04",
      "AB0601H01",
      "AB0601H02",
      "AB0601H03",
      "AB0601H04",
      "AB0601I01",
      "AB0601I02",
      "AB0601I03",
      "AB0601I04",
      "AB0601J01",
      "AB0601J02",
      "AB0601J03",
      "AB0601J04",
      "AB0601K01",
      "AB0601K02",
      "AB0601K03",
      "AB0601K04",
      "AB0602A01",
      "AB0602A02",
      "AB0602A03",
      "AB0602B01",
      "AB0602B02",
      "AB0602B03",
      "AB0602C01",
      "AB0602C02",
      "AB0602C03",
      "AB0602D01",
      "AB0602D02",
      "AB0602D03",
      "AB0602E01",
      "AB0602E02",
      "AB0602E03",
      "AB0602F01",
      "AB0602F02",
      "AB0602F03",
      "AB0602G01",
      "AB0602G02",
      "AB0602G03",
      "AB0602H01",
      "AB0602H02",
      "AB0602H03",
      "AB0602I01",
      "AB0602I02",
      "AB0602I03",
      "AB0602J01",
      "AB0602J02",
      "AB0602J03",
      "AB0603A01",
      "AB0603A02",
      "AB0603A03",
      "AB0603B01",
      "AB0603B02",
      "AB0603B03",
      "AB0603C01",
      "AB0603C02",
      "AB0603C03",
      "AB0603D01",
      "AB0603D02",
      "AB0603D03",
      "AB0603E01",
      "AB0603E02",
      "AB0603E03",
      "AB0603F01",
      "AB0603F02",
      "AB0603F03",
      "AB0603G01",
      "AB0603G02",
      "AB0603G03",
      "AB0603H01",
      "AB0603H02",
      "AB0603H03",
      "AB0603I01",
      "AB0603I02",
      "AB0603I03",
      "AB0603J01",
      "AB0603J02",
      "AB0603J03",
      "AB0604A01",
      "AB0604A02",
      "AB0604A03",
      "AB0604B01",
      "AB0604B02",
      "AB0604B03",
      "AB0604C01",
      "AB0604C02",
      "AB0604C03",
      "AB0604D01",
      "AB0604D02",
      "AB0604D03",
      "AB0604E01",
      "AB0604E02",
      "AB0604E03",
      "AB0604F01",
      "AB0604F02",
      "AB0604F03",
      "AB0604G01",
      "AB0604G02",
      "AB0604G03",
      "AB0604H01",
      "AB0604H02",
      "AB0604H03",
      "AB0604I01",
      "AB0604I02",
      "AB0604I03",
      "AB0604J01",
      "AB0604J02",
      "AB0604J03",
      "AB0605A01",
      "AB0605A02",
      "AB0605A03",
      "AB0605A04",
      "AB0605B01",
      "AB0605B02",
      "AB0605B03",
      "AB0605B04",
      "AB0605C01",
      "AB0605C02",
      "AB0605C03",
      "AB0605C04",
      "AB0605D01",
      "AB0605D02",
      "AB0605D03",
      "AB0605D04",
      "AB0605E01",
      "AB0605E02",
      "AB0605E03",
      "AB0605E04",
      "AB0605F01",
      "AB0605F02",
      "AB0605F03",
      "AB0605F04",
      "AB0605G01",
      "AB0605G02",
      "AB0605G03",
      "AB0605G04",
      "AB0605H01",
      "AB0605H02",
      "AB0605H03",
      "AB0605H04",
      "AB0605I01",
      "AB0605I02",
      "AB0605I03",
      "AB0605I04",
      "AB0605J01",
      "AB0605J02",
      "AB0605J03",
      "AB0605J04",
      "AB0606A01",
      "AB0606A02",
      "AB0606A03",
      "AB0606B01",
      "AB0606B02",
      "AB0606B03",
      "AB0606C01",
      "AB0606C02",
      "AB0606C03",
      "AB0606D01",
      "AB0606D02",
      "AB0606D03",
      "AB0606E01",
      "AB0606E02",
      "AB0606E03",
      "AB0606F01",
      "AB0606F02",
      "AB0606F03",
      "AB0606G01",
      "AB0606G02",
      "AB0606G03",
      "AB0606H01",
      "AB0606H02",
      "AB0606H03",
      "AB0606I01",
      "AB0606I02",
      "AB0606I03",
      "AB0606J01",
      "AB0606J02",
      "AB0606J03",
      "AB0701A01",
      "AB0701A02",
      "AB0701A03",
      "AB0701B01",
      "AB0701B02",
      "AB0701B03",
      "AB0701C01",
      "AB0701C02",
      "AB0701C03",
      "AB0701D01",
      "AB0701D02",
      "AB0701D03",
      "AB0701E01",
      "AB0701E02",
      "AB0701E03",
      "AB0701F01",
      "AB0701F02",
      "AB0701F03",
      "AB0701G01",
      "AB0701G02",
      "AB0701G03",
      "AB0701H01",
      "AB0701H02",
      "AB0701H03",
      "AB0701I01",
      "AB0701I02",
      "AB0701I03",
      "AB0701J01",
      "AB0701J02",
      "AB0701J03",
      "AB0702A01",
      "AB0702A02",
      "AB0702A03",
      "AB0702A04",
      "AB0702B01",
      "AB0702B02",
      "AB0702B03",
      "AB0702B04",
      "AB0702C01",
      "AB0702C02",
      "AB0702C03",
      "AB0702C04",
      "AB0702D01",
      "AB0702D02",
      "AB0702D03",
      "AB0702D04",
      "AB0702E01",
      "AB0702E02",
      "AB0702E03",
      "AB0702E04",
      "AB0702F01",
      "AB0702F02",
      "AB0702F03",
      "AB0702F04",
      "AB0702G01",
      "AB0702G02",
      "AB0702G03",
      "AB0702G04",
      "AB0702H01",
      "AB0702H02",
      "AB0702H03",
      "AB0702H04",
      "AB0702I01",
      "AB0702I02",
      "AB0702I03",
      "AB0702I04",
      "AB0702J01",
      "AB0702J02",
      "AB0702J03",
      "AB0702J04",
      "AB0703A01",
      "AB0703A02",
      "AB0703A03",
      "AB0703B01",
      "AB0703B02",
      "AB0703B03",
      "AB0703C01",
      "AB0703C02",
      "AB0703C03",
      "AB0703D01",
      "AB0703D02",
      "AB0703D03",
      "AB0703E01",
      "AB0703E02",
      "AB0703E03",
      "AB0703F01",
      "AB0703F02",
      "AB0703F03",
      "AB0703G01",
      "AB0703G02",
      "AB0703G03",
      "AB0703H01",
      "AB0703H02",
      "AB0703H03",
      "AB0703I01",
      "AB0703I02",
      "AB0703I03",
      "AB0703J01",
      "AB0703J02",
      "AB0703J03",
      "AB0704A01",
      "AB0704A02",
      "AB0704A03",
      "AB0704B01",
      "AB0704B02",
      "AB0704B03",
      "AB0704C01",
      "AB0704C02",
      "AB0704C03",
      "AB0704D01",
      "AB0704D02",
      "AB0704D03",
      "AB0704E01",
      "AB0704E02",
      "AB0704E03",
      "AB0704F01",
      "AB0704F02",
      "AB0704F03",
      "AB0704G01",
      "AB0704G02",
      "AB0704G03",
      "AB0704H01",
      "AB0704H02",
      "AB0704H03",
      "AB0704I01",
      "AB0704I02",
      "AB0704I03",
      "AB0704J01",
      "AB0704J02",
      "AB0704J03",
      "AB0705A01",
      "AB0705A02",
      "AB0705A03",
      "AB0705B01",
      "AB0705B02",
      "AB0705B03",
      "AB0705C01",
      "AB0705C02",
      "AB0705C03",
      "AB0705D01",
      "AB0705D02",
      "AB0705D03",
      "AB0705E01",
      "AB0705E02",
      "AB0705E03",
      "AB0705F01",
      "AB0705F02",
      "AB0705F03",
      "AB0705G01",
      "AB0705G02",
      "AB0705G03",
      "AB0705H01",
      "AB0705H02",
      "AB0705H03",
      "AB0705I01",
      "AB0705I02",
      "AB0705I03",
      "AB0705J01",
      "AB0705J02",
      "AB0705J03",
      "AB0706A01",
      "AB0706A02",
      "AB0706A03",
      "AB0706A04",
      "AB0706B01",
      "AB0706B02",
      "AB0706B03",
      "AB0706B04",
      "AB0706C01",
      "AB0706C02",
      "AB0706C03",
      "AB0706C04",
      "AB0706D01",
      "AB0706D02",
      "AB0706D03",
      "AB0706D04",
      "AB0706E01",
      "AB0706E02",
      "AB0706E03",
      "AB0706E04",
      "AB0706F01",
      "AB0706F02",
      "AB0706F03",
      "AB0706F04",
      "AB0706G01",
      "AB0706G02",
      "AB0706G03",
      "AB0706G04",
      "AB0706H01",
      "AB0706H02",
      "AB0706H03",
      "AB0706H04",
      "AB0706I01",
      "AB0706I02",
      "AB0706I03",
      "AB0706I04",
      "AB0706J01",
      "AB0706J02",
      "AB0706J03",
      "AB0706J04",
      "AB0706K01",
      "AB0706K02",
      "AB0706K03",
      "AB0706K04",
      "AB0801A01",
      "AB0801A02",
      "AB0801A03",
      "AB0801A04",
      "AB0801B01",
      "AB0801B02",
      "AB0801B03",
      "AB0801B04",
      "AB0801C01",
      "AB0801C02",
      "AB0801C03",
      "AB0801C04",
      "AB0801D01",
      "AB0801D02",
      "AB0801D03",
      "AB0801D04",
      "AB0801E01",
      "AB0801E02",
      "AB0801E03",
      "AB0801E04",
      "AB0801F01",
      "AB0801F02",
      "AB0801F03",
      "AB0801F04",
      "AB0801G01",
      "AB0801G02",
      "AB0801G03",
      "AB0801G04",
      "AB0801H01",
      "AB0801H02",
      "AB0801H03",
      "AB0801H04",
      "AB0801I01",
      "AB0801I02",
      "AB0801I03",
      "AB0801I04",
      "AB0801J01",
      "AB0801J02",
      "AB0801J03",
      "AB0801J04",
      "AB0801K01",
      "AB0801K02",
      "AB0801K03",
      "AB0801K04",
      "AB0802A01",
      "AB0802A02",
      "AB0802A03",
      "AB0802B01",
      "AB0802B02",
      "AB0802B03",
      "AB0802C01",
      "AB0802C02",
      "AB0802C03",
      "AB0802D01",
      "AB0802D02",
      "AB0802D03",
      "AB0802E01",
      "AB0802E02",
      "AB0802E03",
      "AB0802F01",
      "AB0802F02",
      "AB0802F03",
      "AB0802G01",
      "AB0802G02",
      "AB0802G03",
      "AB0802H01",
      "AB0802H02",
      "AB0802H03",
      "AB0802I01",
      "AB0802I02",
      "AB0802I03",
      "AB0802J01",
      "AB0802J02",
      "AB0802J03",
      "AB0803A01",
      "AB0803A02",
      "AB0803A03",
      "AB0803B01",
      "AB0803B02",
      "AB0803B03",
      "AB0803C01",
      "AB0803C02",
      "AB0803C03",
      "AB0803D01",
      "AB0803D02",
      "AB0803D03",
      "AB0803E01",
      "AB0803E02",
      "AB0803E03",
      "AB0803F01",
      "AB0803F02",
      "AB0803F03",
      "AB0803G01",
      "AB0803G02",
      "AB0803G03",
      "AB0803H01",
      "AB0803H02",
      "AB0803H03",
      "AB0803I01",
      "AB0803I02",
      "AB0803I03",
      "AB0803J01",
      "AB0803J02",
      "AB0803J03",
      "AB0804A01",
      "AB0804A02",
      "AB0804A03",
      "AB0804B01",
      "AB0804B02",
      "AB0804B03",
      "AB0804C01",
      "AB0804C02",
      "AB0804C03",
      "AB0804D01",
      "AB0804D02",
      "AB0804D03",
      "AB0804E01",
      "AB0804E02",
      "AB0804E03",
      "AB0804F01",
      "AB0804F02",
      "AB0804F03",
      "AB0804G01",
      "AB0804G02",
      "AB0804G03",
      "AB0804H01",
      "AB0804H02",
      "AB0804H03",
      "AB0804I01",
      "AB0804I02",
      "AB0804I03",
      "AB0804J01",
      "AB0804J02",
      "AB0804J03",
      "AB0805A01",
      "AB0805A02",
      "AB0805A03",
      "AB0805A04",
      "AB0805B01",
      "AB0805B02",
      "AB0805B03",
      "AB0805B04",
      "AB0805C01",
      "AB0805C02",
      "AB0805C03",
      "AB0805C04",
      "AB0805D01",
      "AB0805D02",
      "AB0805D03",
      "AB0805D04",
      "AB0805E01",
      "AB0805E02",
      "AB0805E03",
      "AB0805E04",
      "AB0805F01",
      "AB0805F02",
      "AB0805F03",
      "AB0805F04",
      "AB0805G01",
      "AB0805G02",
      "AB0805G03",
      "AB0805G04",
      "AB0805H01",
      "AB0805H02",
      "AB0805H03",
      "AB0805H04",
      "AB0805I01",
      "AB0805I02",
      "AB0805I03",
      "AB0805I04",
      "AB0805J01",
      "AB0805J02",
      "AB0805J03",
      "AB0805J04",
      "AB0806A01",
      "AB0806A02",
      "AB0806A03",
      "AB0806B01",
      "AB0806B02",
      "AB0806B03",
      "AB0806C01",
      "AB0806C02",
      "AB0806C03",
      "AB0806D01",
      "AB0806D02",
      "AB0806D03",
      "AB0806E01",
      "AB0806E02",
      "AB0806E03",
      "AB0806F01",
      "AB0806F02",
      "AB0806F03",
      "AB0806G01",
      "AB0806G02",
      "AB0806G03",
      "AB0806H01",
      "AB0806H02",
      "AB0806H03",
      "AB0806I01",
      "AB0806I02",
      "AB0806I03",
      "AB0806J01",
      "AB0806J02",
      "AB0806J03",
      "AB0901A01",
      "AB0901A02",
      "AB0901A03",
      "AB0901B01",
      "AB0901B02",
      "AB0901B03",
      "AB0901C01",
      "AB0901C02",
      "AB0901C03",
      "AB0901D01",
      "AB0901D02",
      "AB0901D03",
      "AB0901E01",
      "AB0901E02",
      "AB0901E03",
      "AB0901F01",
      "AB0901F02",
      "AB0901F03",
      "AB0901G01",
      "AB0901G02",
      "AB0901G03",
      "AB0901H01",
      "AB0901H02",
      "AB0901H03",
      "AB0901I01",
      "AB0901I02",
      "AB0901I03",
      "AB0901J01",
      "AB0901J02",
      "AB0901J03",
      "AB0902A01",
      "AB0902A02",
      "AB0902A03",
      "AB0902A04",
      "AB0902B01",
      "AB0902B02",
      "AB0902B03",
      "AB0902B04",
      "AB0902C01",
      "AB0902C02",
      "AB0902C03",
      "AB0902C04",
      "AB0902D01",
      "AB0902D02",
      "AB0902D03",
      "AB0902D04",
      "AB0902E01",
      "AB0902E02",
      "AB0902E03",
      "AB0902E04",
      "AB0902F01",
      "AB0902F02",
      "AB0902F03",
      "AB0902F04",
      "AB0902G01",
      "AB0902G02",
      "AB0902G03",
      "AB0902G04",
      "AB0902H01",
      "AB0902H02",
      "AB0902H03",
      "AB0902H04",
      "AB0902I01",
      "AB0902I02",
      "AB0902I03",
      "AB0902I04",
      "AB0902J01",
      "AB0902J02",
      "AB0902J03",
      "AB0902J04",
      "AB0903A01",
      "AB0903A02",
      "AB0903A03",
      "AB0903B01",
      "AB0903B02",
      "AB0903B03",
      "AB0903C01",
      "AB0903C02",
      "AB0903C03",
      "AB0903D01",
      "AB0903D02",
      "AB0903D03",
      "AB0903E01",
      "AB0903E02",
      "AB0903E03",
      "AB0903F01",
      "AB0903F02",
      "AB0903F03",
      "AB0903G01",
      "AB0903G02",
      "AB0903G03",
      "AB0903H01",
      "AB0903H02",
      "AB0903H03",
      "AB0903I01",
      "AB0903I02",
      "AB0903I03",
      "AB0903J01",
      "AB0903J02",
      "AB0903J03",
      "AB0904A01",
      "AB0904A02",
      "AB0904A03",
      "AB0904B01",
      "AB0904B02",
      "AB0904B03",
      "AB0904C01",
      "AB0904C02",
      "AB0904C03",
      "AB0904D01",
      "AB0904D02",
      "AB0904D03",
      "AB0904E01",
      "AB0904E02",
      "AB0904E03",
      "AB0904F01",
      "AB0904F02",
      "AB0904F03",
      "AB0904G01",
      "AB0904G02",
      "AB0904G03",
      "AB0904H01",
      "AB0904H02",
      "AB0904H03",
      "AB0904I01",
      "AB0904I02",
      "AB0904I03",
      "AB0904J01",
      "AB0904J02",
      "AB0904J03",
      "AB0905A01",
      "AB0905A02",
      "AB0905A03",
      "AB0905B01",
      "AB0905B02",
      "AB0905B03",
      "AB0905C01",
      "AB0905C02",
      "AB0905C03",
      "AB0905D01",
      "AB0905D02",
      "AB0905D03",
      "AB0905E01",
      "AB0905E02",
      "AB0905E03",
      "AB0905F01",
      "AB0905F02",
      "AB0905F03",
      "AB0905G01",
      "AB0905G02",
      "AB0905G03",
      "AB0905H01",
      "AB0905H02",
      "AB0905H03",
      "AB0905I01",
      "AB0905I02",
      "AB0905I03",
      "AB0905J01",
      "AB0905J02",
      "AB0905J03",
      "AB0906A01",
      "AB0906A02",
      "AB0906A03",
      "AB0906A04",
      "AB0906B01",
      "AB0906B02",
      "AB0906B03",
      "AB0906B04",
      "AB0906C01",
      "AB0906C02",
      "AB0906C03",
      "AB0906C04",
      "AB0906D01",
      "AB0906D02",
      "AB0906D03",
      "AB0906D04",
      "AB0906E01",
      "AB0906E02",
      "AB0906E03",
      "AB0906E04",
      "AB0906F01",
      "AB0906F02",
      "AB0906F03",
      "AB0906F04",
      "AB0906G01",
      "AB0906G02",
      "AB0906G03",
      "AB0906G04",
      "AB0906H01",
      "AB0906H02",
      "AB0906H03",
      "AB0906H04",
      "AB0906I01",
      "AB0906I02",
      "AB0906I03",
      "AB0906I04",
      "AB0906J01",
      "AB0906J02",
      "AB0906J03",
      "AB0906J04",
      "AB0906K01",
      "AB0906K02",
      "AB0906K03",
      "AB0906K04",
      "AB1001A01",
      "AB1001A02",
      "AB1001A03",
      "AB1001A04",
      "AB1001B01",
      "AB1001B02",
      "AB1001B03",
      "AB1001B04",
      "AB1001C01",
      "AB1001C02",
      "AB1001C03",
      "AB1001C04",
      "AB1001D01",
      "AB1001D02",
      "AB1001D03",
      "AB1001D04",
      "AB1001E01",
      "AB1001E02",
      "AB1001E03",
      "AB1001E04",
      "AB1001F01",
      "AB1001F02",
      "AB1001F03",
      "AB1001F04",
      "AB1001G01",
      "AB1001G02",
      "AB1001G03",
      "AB1001G04",
      "AB1001H01",
      "AB1001H02",
      "AB1001H03",
      "AB1001H04",
      "AB1001I01",
      "AB1001I02",
      "AB1001I03",
      "AB1001I04",
      "AB1001J01",
      "AB1001J02",
      "AB1001J03",
      "AB1001J04",
      "AB1001K01",
      "AB1001K02",
      "AB1001K03",
      "AB1001K04",
      "AB1002A01",
      "AB1002A02",
      "AB1002A03",
      "AB1002B01",
      "AB1002B02",
      "AB1002B03",
      "AB1002C01",
      "AB1002C02",
      "AB1002C03",
      "AB1002D01",
      "AB1002D02",
      "AB1002D03",
      "AB1002E01",
      "AB1002E02",
      "AB1002E03",
      "AB1002F01",
      "AB1002F02",
      "AB1002F03",
      "AB1002G01",
      "AB1002G02",
      "AB1002G03",
      "AB1002H01",
      "AB1002H02",
      "AB1002H03",
      "AB1002I01",
      "AB1002I02",
      "AB1002I03",
      "AB1002J01",
      "AB1002J02",
      "AB1002J03",
      "AB1003A01",
      "AB1003A02",
      "AB1003A03",
      "AB1003B01",
      "AB1003B02",
      "AB1003B03",
      "AB1003C01",
      "AB1003C02",
      "AB1003C03",
      "AB1003D01",
      "AB1003D02",
      "AB1003D03",
      "AB1003E01",
      "AB1003E02",
      "AB1003E03",
      "AB1003F01",
      "AB1003F02",
      "AB1003F03",
      "AB1003G01",
      "AB1003G02",
      "AB1003G03",
      "AB1003H01",
      "AB1003H02",
      "AB1003H03",
      "AB1003I01",
      "AB1003I02",
      "AB1003I03",
      "AB1003J01",
      "AB1003J02",
      "AB1003J03",
      "AB1004A01",
      "AB1004A02",
      "AB1004A03",
      "AB1004B01",
      "AB1004B02",
      "AB1004B03",
      "AB1004C01",
      "AB1004C02",
      "AB1004C03",
      "AB1004D01",
      "AB1004D02",
      "AB1004D03",
      "AB1004E01",
      "AB1004E02",
      "AB1004E03",
      "AB1004F01",
      "AB1004F02",
      "AB1004F03",
      "AB1004G01",
      "AB1004G02",
      "AB1004G03",
      "AB1004H01",
      "AB1004H02",
      "AB1004H03",
      "AB1004I01",
      "AB1004I02",
      "AB1004I03",
      "AB1004J01",
      "AB1004J02",
      "AB1004J03",
      "AB1005A01",
      "AB1005A02",
      "AB1005A03",
      "AB1005A04",
      "AB1005B01",
      "AB1005B02",
      "AB1005B03",
      "AB1005B04",
      "AB1005C01",
      "AB1005C02",
      "AB1005C03",
      "AB1005C04",
      "AB1005D01",
      "AB1005D02",
      "AB1005D03",
      "AB1005D04",
      "AB1005E01",
      "AB1005E02",
      "AB1005E03",
      "AB1005E04",
      "AB1005F01",
      "AB1005F02",
      "AB1005F03",
      "AB1005F04",
      "AB1005G01",
      "AB1005G02",
      "AB1005G03",
      "AB1005G04",
      "AB1005H01",
      "AB1005H02",
      "AB1005H03",
      "AB1005H04",
      "AB1005I01",
      "AB1005I02",
      "AB1005I03",
      "AB1005I04",
      "AB1005J01",
      "AB1005J02",
      "AB1005J03",
      "AB1005J04",
      "AB1006A01",
      "AB1006A02",
      "AB1006A03",
      "AB1006B01",
      "AB1006B02",
      "AB1006B03",
      "AB1006C01",
      "AB1006C02",
      "AB1006C03",
      "AB1006D01",
      "AB1006D02",
      "AB1006D03",
      "AB1006E01",
      "AB1006E02",
      "AB1006E03",
      "AB1006F01",
      "AB1006F02",
      "AB1006F03",
      "AB1006G01",
      "AB1006G02",
      "AB1006G03",
      "AB1006H01",
      "AB1006H02",
      "AB1006H03",
      "AB1006I01",
      "AB1006I02",
      "AB1006I03",
      "AB1006J01",
      "AB1006J02",
      "AB1006J03",
      "AB1101A01",
      "AB1101A02",
      "AB1101A03",
      "AB1101B01",
      "AB1101B02",
      "AB1101B03",
      "AB1101C01",
      "AB1101C02",
      "AB1101C03",
      "AB1101D01",
      "AB1101D02",
      "AB1101D03",
      "AB1101E01",
      "AB1101E02",
      "AB1101E03",
      "AB1101F01",
      "AB1101F02",
      "AB1101F03",
      "AB1101G01",
      "AB1101G02",
      "AB1101G03",
      "AB1101H01",
      "AB1101H02",
      "AB1101H03",
      "AB1101I01",
      "AB1101I02",
      "AB1101I03",
      "AB1101J01",
      "AB1101J02",
      "AB1101J03",
      "AB1102A01",
      "AB1102A02",
      "AB1102A03",
      "AB1102A04",
      "AB1102B01",
      "AB1102B02",
      "AB1102B03",
      "AB1102B04",
      "AB1102C01",
      "AB1102C02",
      "AB1102C03",
      "AB1102C04",
      "AB1102D01",
      "AB1102D02",
      "AB1102D03",
      "AB1102D04",
      "AB1102E01",
      "AB1102E02",
      "AB1102E03",
      "AB1102E04",
      "AB1102F01",
      "AB1102F02",
      "AB1102F03",
      "AB1102F04",
      "AB1102G01",
      "AB1102G02",
      "AB1102G03",
      "AB1102G04",
      "AB1102H01",
      "AB1102H02",
      "AB1102H03",
      "AB1102H04",
      "AB1102I01",
      "AB1102I02",
      "AB1102I03",
      "AB1102I04",
      "AB1102J01",
      "AB1102J02",
      "AB1102J03",
      "AB1102J04",
      "AB1103A01",
      "AB1103A02",
      "AB1103A03",
      "AB1103B01",
      "AB1103B02",
      "AB1103B03",
      "AB1103C01",
      "AB1103C02",
      "AB1103C03",
      "AB1103D01",
      "AB1103D02",
      "AB1103D03",
      "AB1103E01",
      "AB1103E02",
      "AB1103E03",
      "AB1103F01",
      "AB1103F02",
      "AB1103F03",
      "AB1103G01",
      "AB1103G02",
      "AB1103G03",
      "AB1103H01",
      "AB1103H02",
      "AB1103H03",
      "AB1103I01",
      "AB1103I02",
      "AB1103I03",
      "AB1103J01",
      "AB1103J02",
      "AB1103J03",
      "AB1104A01",
      "AB1104A02",
      "AB1104A03",
      "AB1104B01",
      "AB1104B02",
      "AB1104B03",
      "AB1104C01",
      "AB1104C02",
      "AB1104C03",
      "AB1104D01",
      "AB1104D02",
      "AB1104D03",
      "AB1104E01",
      "AB1104E02",
      "AB1104E03",
      "AB1104F01",
      "AB1104F02",
      "AB1104F03",
      "AB1104G01",
      "AB1104G02",
      "AB1104G03",
      "AB1104H01",
      "AB1104H02",
      "AB1104H03",
      "AB1104I01",
      "AB1104I02",
      "AB1104I03",
      "AB1104J01",
      "AB1104J02",
      "AB1104J03",
      "AB1105A01",
      "AB1105A02",
      "AB1105A03",
      "AB1105B01",
      "AB1105B02",
      "AB1105B03",
      "AB1105C01",
      "AB1105C02",
      "AB1105C03",
      "AB1105D01",
      "AB1105D02",
      "AB1105D03",
      "AB1105E01",
      "AB1105E02",
      "AB1105E03",
      "AB1105F01",
      "AB1105F02",
      "AB1105F03",
      "AB1105G01",
      "AB1105G02",
      "AB1105G03",
      "AB1105H01",
      "AB1105H02",
      "AB1105H03",
      "AB1105I01",
      "AB1105I02",
      "AB1105I03",
      "AB1105J01",
      "AB1105J02",
      "AB1105J03",
      "AB1106A01",
      "AB1106A02",
      "AB1106A03",
      "AB1106A04",
      "AB1106B01",
      "AB1106B02",
      "AB1106B03",
      "AB1106B04",
      "AB1106C01",
      "AB1106C02",
      "AB1106C03",
      "AB1106C04",
      "AB1106D01",
      "AB1106D02",
      "AB1106D03",
      "AB1106D04",
      "AB1106E01",
      "AB1106E02",
      "AB1106E03",
      "AB1106E04",
      "AB1106F01",
      "AB1106F02",
      "AB1106F03",
      "AB1106F04",
      "AB1106G01",
      "AB1106G02",
      "AB1106G03",
      "AB1106G04",
      "AB1106H01",
      "AB1106H02",
      "AB1106H03",
      "AB1106H04",
      "AB1106I01",
      "AB1106I02",
      "AB1106I03",
      "AB1106I04",
      "AB1106J01",
      "AB1106J02",
      "AB1106J03",
      "AB1106J04",
      "AB1106K01",
      "AB1106K02",
      "AB1106K03",
      "AB1106K04",
      "AB1201A01",
      "AB1201A02",
      "AB1201A03",
      "AB1201A04",
      "AB1201B01",
      "AB1201B02",
      "AB1201B03",
      "AB1201B04",
      "AB1201C01",
      "AB1201C02",
      "AB1201C03",
      "AB1201C04",
      "AB1201D01",
      "AB1201D02",
      "AB1201D03",
      "AB1201D04",
      "AB1201E01",
      "AB1201E02",
      "AB1201E03",
      "AB1201E04",
      "AB1201F01",
      "AB1201F02",
      "AB1201F03",
      "AB1201F04",
      "AB1201G01",
      "AB1201G02",
      "AB1201G03",
      "AB1201G04",
      "AB1201H01",
      "AB1201H02",
      "AB1201H03",
      "AB1201H04",
      "AB1201I01",
      "AB1201I02",
      "AB1201I03",
      "AB1201I04",
      "AB1201J01",
      "AB1201J02",
      "AB1201J03",
      "AB1201J04",
      "AB1201K01",
      "AB1201K02",
      "AB1201K03",
      "AB1201K04",
      "AB1202A01",
      "AB1202A02",
      "AB1202A03",
      "AB1202B01",
      "AB1202B02",
      "AB1202B03",
      "AB1202C01",
      "AB1202C02",
      "AB1202C03",
      "AB1202D01",
      "AB1202D02",
      "AB1202D03",
      "AB1202E01",
      "AB1202E02",
      "AB1202E03",
      "AB1202F01",
      "AB1202F02",
      "AB1202F03",
      "AB1202G01",
      "AB1202G02",
      "AB1202G03",
      "AB1202H01",
      "AB1202H02",
      "AB1202H03",
      "AB1202I01",
      "AB1202I02",
      "AB1202I03",
      "AB1202J01",
      "AB1202J02",
      "AB1202J03",
      "AB1203A01",
      "AB1203A02",
      "AB1203A03",
      "AB1203B01",
      "AB1203B02",
      "AB1203B03",
      "AB1203C01",
      "AB1203C02",
      "AB1203C03",
      "AB1203D01",
      "AB1203D02",
      "AB1203D03",
      "AB1203E01",
      "AB1203E02",
      "AB1203E03",
      "AB1203F01",
      "AB1203F02",
      "AB1203F03",
      "AB1203G01",
      "AB1203G02",
      "AB1203G03",
      "AB1203H01",
      "AB1203H02",
      "AB1203H03",
      "AB1203I01",
      "AB1203I02",
      "AB1203I03",
      "AB1203J01",
      "AB1203J02",
      "AB1203J03",
      "AB1204A01",
      "AB1204A02",
      "AB1204A03",
      "AB1204B01",
      "AB1204B02",
      "AB1204B03",
      "AB1204C01",
      "AB1204C02",
      "AB1204C03",
      "AB1204D01",
      "AB1204D02",
      "AB1204D03",
      "AB1204E01",
      "AB1204E02",
      "AB1204E03",
      "AB1204F01",
      "AB1204F02",
      "AB1204F03",
      "AB1204G01",
      "AB1204G02",
      "AB1204G03",
      "AB1204H01",
      "AB1204H02",
      "AB1204H03",
      "AB1204I01",
      "AB1204I02",
      "AB1204I03",
      "AB1204J01",
      "AB1204J02",
      "AB1204J03",
      "AB1205A01",
      "AB1205A02",
      "AB1205A03",
      "AB1205A04",
      "AB1205B01",
      "AB1205B02",
      "AB1205B03",
      "AB1205B04",
      "AB1205C01",
      "AB1205C02",
      "AB1205C03",
      "AB1205C04",
      "AB1205D01",
      "AB1205D02",
      "AB1205D03",
      "AB1205D04",
      "AB1205E01",
      "AB1205E02",
      "AB1205E03",
      "AB1205E04",
      "AB1205F01",
      "AB1205F02",
      "AB1205F03",
      "AB1205F04",
      "AB1205G01",
      "AB1205G02",
      "AB1205G03",
      "AB1205G04",
      "AB1205H01",
      "AB1205H02",
      "AB1205H03",
      "AB1205H04",
      "AB1205I01",
      "AB1205I02",
      "AB1205I03",
      "AB1205I04",
      "AB1205J01",
      "AB1205J02",
      "AB1205J03",
      "AB1205J04",
      "AB1206A01",
      "AB1206A02",
      "AB1206A03",
      "AB1206B01",
      "AB1206B02",
      "AB1206B03",
      "AB1206C01",
      "AB1206C02",
      "AB1206C03",
      "AB1206D01",
      "AB1206D02",
      "AB1206D03",
      "AB1206E01",
      "AB1206E02",
      "AB1206E03",
      "AB1206F01",
      "AB1206F02",
      "AB1206F03",
      "AB1206G01",
      "AB1206G02",
      "AB1206G03",
      "AB1206H01",
      "AB1206H02",
      "AB1206H03",
      "AB1206I01",
      "AB1206I02",
      "AB1206I03",
      "AB1206J01",
      "AB1206J02",
      "AB1206J03",
      "AB1301A01",
      "AB1301A02",
      "AB1301A03",
      "AB1301B01",
      "AB1301B02",
      "AB1301B03",
      "AB1301C01",
      "AB1301C02",
      "AB1301C03",
      "AB1301D01",
      "AB1301D02",
      "AB1301D03",
      "AB1301E01",
      "AB1301E02",
      "AB1301E03",
      "AB1301F01",
      "AB1301F02",
      "AB1301F03",
      "AB1301G01",
      "AB1301G02",
      "AB1301G03",
      "AB1301H01",
      "AB1301H02",
      "AB1301H03",
      "AB1301I01",
      "AB1301I02",
      "AB1301I03",
      "AB1301J01",
      "AB1301J02",
      "AB1301J03",
      "AB1302A01",
      "AB1302A02",
      "AB1302A03",
      "AB1302A04",
      "AB1302B01",
      "AB1302B02",
      "AB1302B03",
      "AB1302B04",
      "AB1302C01",
      "AB1302C02",
      "AB1302C03",
      "AB1302C04",
      "AB1302D01",
      "AB1302D02",
      "AB1302D03",
      "AB1302D04",
      "AB1302E01",
      "AB1302E02",
      "AB1302E03",
      "AB1302E04",
      "AB1302F01",
      "AB1302F02",
      "AB1302F03",
      "AB1302F04",
      "AB1302G01",
      "AB1302G02",
      "AB1302G03",
      "AB1302G04",
      "AB1302H01",
      "AB1302H02",
      "AB1302H03",
      "AB1302H04",
      "AB1302I01",
      "AB1302I02",
      "AB1302I03",
      "AB1302I04",
      "AB1302J01",
      "AB1302J02",
      "AB1302J03",
      "AB1302J04",
      "AB1303A01",
      "AB1303A02",
      "AB1303A03",
      "AB1303B01",
      "AB1303B02",
      "AB1303B03",
      "AB1303C01",
      "AB1303C02",
      "AB1303C03",
      "AB1303D01",
      "AB1303D02",
      "AB1303D03",
      "AB1303E01",
      "AB1303E02",
      "AB1303E03",
      "AB1303F01",
      "AB1303F02",
      "AB1303F03",
      "AB1303G01",
      "AB1303G02",
      "AB1303G03",
      "AB1303H01",
      "AB1303H02",
      "AB1303H03",
      "AB1303I01",
      "AB1303I02",
      "AB1303I03",
      "AB1303J01",
      "AB1303J02",
      "AB1303J03",
      "AB1304A01",
      "AB1304A02",
      "AB1304A03",
      "AB1304B01",
      "AB1304B02",
      "AB1304B03",
      "AB1304C01",
      "AB1304C02",
      "AB1304C03",
      "AB1304D01",
      "AB1304D02",
      "AB1304D03",
      "AB1304E01",
      "AB1304E02",
      "AB1304E03",
      "AB1304F01",
      "AB1304F02",
      "AB1304F03",
      "AB1304G01",
      "AB1304G02",
      "AB1304G03",
      "AB1304H01",
      "AB1304H02",
      "AB1304H03",
      "AB1304I01",
      "AB1304I02",
      "AB1304I03",
      "AB1304J01",
      "AB1304J02",
      "AB1304J03",
      "AB1305A01",
      "AB1305A02",
      "AB1305A03",
      "AB1305B01",
      "AB1305B02",
      "AB1305B03",
      "AB1305C01",
      "AB1305C02",
      "AB1305C03",
      "AB1305D01",
      "AB1305D02",
      "AB1305D03",
      "AB1305E01",
      "AB1305E02",
      "AB1305E03",
      "AB1305F01",
      "AB1305F02",
      "AB1305F03",
      "AB1305G01",
      "AB1305G02",
      "AB1305G03",
      "AB1305H01",
      "AB1305H02",
      "AB1305H03",
      "AB1305I01",
      "AB1305I02",
      "AB1305I03",
      "AB1305J01",
      "AB1305J02",
      "AB1305J03",
      "AB1306A01",
      "AB1306A02",
      "AB1306A03",
      "AB1306A04",
      "AB1306B01",
      "AB1306B02",
      "AB1306B03",
      "AB1306B04",
      "AB1306C01",
      "AB1306C02",
      "AB1306C03",
      "AB1306C04",
      "AB1306D01",
      "AB1306D02",
      "AB1306D03",
      "AB1306D04",
      "AB1306E01",
      "AB1306E02",
      "AB1306E03",
      "AB1306E04",
      "AB1306F01",
      "AB1306F02",
      "AB1306F03",
      "AB1306F04",
      "AB1306G01",
      "AB1306G02",
      "AB1306G03",
      "AB1306G04",
      "AB1306H01",
      "AB1306H02",
      "AB1306H03",
      "AB1306H04",
      "AB1306I01",
      "AB1306I02",
      "AB1306I03",
      "AB1306I04",
      "AB1306J01",
      "AB1306J02",
      "AB1306J03",
      "AB1306J04",
      "AB1306K01",
      "AB1306K02",
      "AB1306K03",
      "AB1306K04",
    ],
  }),

  computed: {
    progress() {
      return (this.checked / this.bins) * 100;
    },
    location() {
      return !!this.stockcheck.location;
    },
  },

  watch: {
    "stockcheck.stockchecks"(v, ov) {
      if (v.length > ov.length) {
        let tmp = v.map((l) => l.added || l.item._id);
        let tmp2 = v.map((l) => l._id);
        let tmp3 = [];

        this.databin.forEach((line) => {
          if (
            line.item &&
            tmp.includes(line.item._id) &&
            !tmp2.includes(line._id)
          )
            tmp3.push({ ...line, added: true });
        });
        if (tmp3.length) {
          this.stockcheck.stockchecks.push(...tmp3);
        }
      }
    },
    "stockcheck.location"(v) {
      this.fetch();
    },
    databin(val) {
      console.log("databin watch");
      let tmp = Object.values(
        this.databin
          .filter((v) => {
            if (this.onhandonly && !v.quantityonhand) return false;
            if (this.brand.length && !this.brand.includes(v.manufacturergroup))
              return false;
            return true;
          })
          .reduce((t, v) => {
            //sector
            t[v.sector] = t[v.sector] || {
              name: v.sector,
              _id: v.sector,
              laststockcheck: v.laststockcheck
                ? new Date(v.laststockcheck)
                : v.quantityonhand
                ? null
                : undefined,
              manufacturergroup: [],
              quantityonhand: 0,
              pallet: false,
              children: {},
            };
            if (this.pallet.includes(v.bin)) t[v.sector].pallet = true;
            //bin
            if (v.sector != v.bin) {
              t[v.sector].children[v.bin] = t[v.sector].children[v.bin] || {
                name: v.bin,
                _id: v.bin,
                laststockcheck: v.laststockcheck
                  ? new Date(v.laststockcheck)
                  : v.quantityonhand
                  ? null
                  : undefined,
                manufacturergroup: [],
                quantityonhand: 0,
                children: [],
              };
            }

            if (!v.laststockcheck && v.quantityonhand)
              t[v.sector].laststockcheck = null;
            else if (
              v.quantityonhand &&
              ((t[v.sector].laststockcheck &&
                new Date(v.laststockcheck) <
                  new Date(t[v.sector].laststockcheck)) ||
                t[v.sector].laststockcheck === undefined)
            )
              t[v.sector].laststockcheck = new Date(v.laststockcheck);
            //kryterium daty
            if (
              this.datefrom &&
              new Date(this.datefrom) > t[v.sector].laststockcheck
            )
              t[v.sector].laststockcheck = null;

            if (v.sector != v.bin) {
              if (!v.laststockcheck && v.quantityonhand)
                t[v.sector].children[v.bin].laststockcheck = null;
              else if (
                v.quantityonhand &&
                ((t[v.sector].children[v.bin].laststockcheck &&
                  new Date(v.laststockcheck) <
                    new Date(t[v.sector].children[v.bin].laststockcheck)) ||
                  t[v.sector].children[v.bin].laststockcheck === undefined)
              )
                t[v.sector].children[v.bin].laststockcheck = new Date(
                  v.laststockcheck
                );

              //kryterium daty
              if (
                this.datefrom &&
                new Date(this.datefrom) >
                  t[v.sector].children[v.bin].laststockcheck
              )
                t[v.sector].children[v.bin].laststockcheck = null;
            }

            if (v.sector != v.bin) {
              t[v.sector].children[v.bin].children.push(v);
            } else {
              t[v.sector].children[v._id] = v;
            }

            //manufacturergroup
            if (!t[v.sector].manufacturergroup.includes(v.manufacturergroup))
              t[v.sector].manufacturergroup.push(v.manufacturergroup);
            if (
              t[v.sector].children[v.bin] &&
              !t[v.sector].children[v.bin].manufacturergroup.includes(
                v.manufacturergroup
              )
            )
              t[v.sector].children[v.bin].manufacturergroup.push(
                v.manufacturergroup
              );

            // quantity
            t[v.sector].quantityonhand += v.quantityonhand || 0;
            if (v.sector != v.bin && t[v.sector].children[v.bin]) {
              t[v.sector].children[v.bin].quantityonhand +=
                v.quantityonhand || 0;
            }

            //fill brands
            if (!this.brands.includes(v.manufacturergroup))
              this.brands.push(v.manufacturergroup);

            return t;
          }, {})
      );
      this.checked = 0;
      this.bins = 0;
      this.items[0].children = tmp.map((l) => {
        this.bins++;
        if (l.laststockcheck) this.checked++;
        l.children = Object.values(l.children);
        return l;
      });
    },
  },

  methods: {
    createStockCheck() {
      this.stockcheck.stockchecks = this.stockcheck.stockchecks.filter(
        (line) => {
          return (
            !this.search ||
            (this.search && line.item.name.includes(this.search))
          );
        }
      );

      this.$router.push({
        name: "Transactions",
        params: this.stockcheck,
      });
    },
    filterTree(item, search, textKey) {
      if (item[textKey]) return item[textKey].includes(search);
      else if (item.item) return item.item[textKey].includes(search);
    },
    // filterData() {
    //   this.databin = this.databin.filter((v) => {
    //     if (this.onhandonly && !v.quantityonhand) return false;
    //     if (this.brand.length && !this.brand.includes(v.manufacturergroup))
    //       return false;
    //     return true;
    //   });
    //   this.sortPerField();
    // },
    sortPerField() {
      console.log("sort");
      this.databin = this.databin.sort((a, b) => {
        //prorytet sektor
        if (a.bin < b.bin) {
          return -1 * this.sort.bin;
        }
        if (a.bin > b.bin) {
          return 1 * this.sort.bin;
        }
        if (a.laststockcheck < b.laststockcheck) {
          return -1 * this.sort.laststockcheck;
        }
        if (a.laststockcheck > b.laststockcheck) {
          return 1 * this.sort.laststockcheck;
        }

        return 0;
      });
    },
    sortBy(arr, field) {
      return arr.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      });
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async fetch() {
      console.log(this.stockcheck.location);
      if (this.stockcheck.location) {
        this.loading = true;
        let result = await service(
          "items",
          {
            action: "getStockCheck",
            location: this.stockcheck.location,
            planner: true,
          },
          false,
          false
        );

        this.databin = result.items;
        this.sortPerField();

        this.loading = false;
      }
    },
  },
};
</script>