<template>
  <div
    v-if="
      $store.state.user &&
      !['role_warehouse_uk'].includes($store.state.user.role._id) &&
      !(
        $route.params.recordtype == 'employee' &&
        ['role_kam', 'role_kam_uk'].includes($store.state.user.role._id)
      )
    "
  >
    <!-- <orgchart v-if="$route.params.recordtype=='employee' && !$route.params.mode"/> -->
    <queryresult
      v-if="!$route.params.mode"
      :queryid="$route.params.recordtype"
    />
    <record collection="users" v-else />
  </div>
  <v-alert v-else type="error">Access Denied</v-alert>
</template>

<script>
//import orgchart from "./OrgChart";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    //orgchart
  },
};
</script>