<template>
  <div>
    <v-card>
      <v-progress-linear
        :indeterminate="recalc || loading"
        height="3"
      ></v-progress-linear>
      <v-tabs v-if="!loading || fields" v-model="active" dark>
        <v-tab v-for="(tab, tabindex) in schema.tabs" :key="tabindex" ripple>{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items touchless v-model="active">
        <v-tab-item v-for="(tab, tabindex) in schema.tabs" :key="tabindex">
          <v-container fluid v-if="tab.sections && tab.sections.length > 0">
            <v-row
              v-for="(section, sectionindex) in tab.sections"
              :key="sectionindex"
            >
              <v-subheader>{{ section.name }}</v-subheader>

              <v-container fluid class="py-0">
                <v-row v-if="section.columns">
                  <v-col
                    v-for="(col, index) in section.columns"
                    :key="index"
                    class="pb-0"
                  >
                    <div v-for="(field, index) in col" :key="index">
                      <component
                        v-if="
                          fields[field.field] &&
                          field.editable != false &&
                          (field.status && record.status
                            ? field.status.includes(record.status._id)
                            : true) &&
                          (field.mode
                            ? field.mode.includes($route.params.mode)
                            : true) &&
                          (field.iftrue ? record[field.iftrue] : true) &&
                          (field.iffalse ? !record[field.iffalse] : true)
                        "
                        @emit="catchEmit"
                        :is="fields[field.field].setup.type"
                        v-model="record[field.field]"
                        :record="record"
                        :fieldSettings="fields[field.field]"
                        :formSettings="field"
                        :key="update"
                        :id="fields[field.field].update"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col
                    v-for="(field, index) in section.fields"
                    :key="index"
                    class="pb-0"
                  >
                    <component
                      v-if="
                        fields[field.field] &&
                        (field.status && record.status
                          ? field.status.includes(record.status._id)
                          : true) &&
                        (field.mode
                          ? field.mode.includes($route.params.mode)
                          : true) &&
                        (field.iftrue ? record[field.iftrue] : true) &&
                        (field.iffalse ? record[field.iffalse] : true)
                      "
                      @emit="catchEmit"
                      :is="fields[field.field].setup.type"
                      v-model="record[field.field]"
                      :record="record"
                      :fieldSettings="fields[field.field]"
                      :formSettings="field"
                      :key="update"
                      :id="fields[field.field].update"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <div
                v-if="section.tool && record._id"
                style="width: 100%; position: relative"
              >
                <component
                  :is="section.tool.tool"
                  :record.sync="record"
                  :id="section.tool.tool"
                  :config="section.tool.config"
                  @emit="catchEmit"
                  v-if="
                    section.tool.status && record.status
                      ? section.tool.status.includes(record.status._id)
                      : true && section.tool.mode
                      ? section.tool.mode.includes($route.params.mode)
                      : true
                  "
                />
              </div>
              <datatable
                v-if="
                  section.sublist &&
                  fields[section.sublist.field] &&
                  !section.sublist.hidden &&
                  (section.sublist.status && record.status
                    ? section.sublist.status.includes(record.status._id)
                    : true) &&
                  (section.sublist.mode
                    ? section.sublist.mode.includes($route.params.mode)
                    : true)
                "
                :record.sync="record"
                :settings="fields[section.sublist.field]"
                :formSettings="section.sublist"
                @emit="catchEmit"
                :id="section.sublist.field"
                style="width: 100%; position: relative"
              />
            </v-row>
          </v-container>

          <result
            v-if="tab.table && tab.table.query"
            :queryid="tab.table.query"
            :filters="tab.table.filters"
            @emit="catchEmit"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
    <v-snackbar v-model="msg.show" :color="msg.type" :timeout="6000" top center
      >{{ msg.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="msg.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import service from "../../api/index";
import result from "../../pages/queries/view-results/View";
import getFields from "../../common/methods/getFields";
import recalcRecord from "../../common/validations/recalcRecord";

/* eslint-disable */
export default {
  props: ["record", "activeTab"],
  data() {
    return {
      msg: false,
      loading: true,
      recalc: false,
      active: null,
      form:
        this.record.form ||
        this.record.recordtype ||
        this.$route.params.recordtype,
      schema: {},
      fields: {},
      update: 0,
      mode: this.$route.params.mode,
      newRecord: this.record,
    };
  },
  components: {
    result,
  },
  watch: {
    activeTab(v) {
      this.active = v;
    },
  },
  methods: {
    async catchEmit(e) {
      if (this.mode != "view") {
        if (e.field) {
          this.$set(this.newRecord, e.field, e.value);
          //Object.assign({}, this.someObject, { a: 1, b: 2 })
          // this.record[e.field] = e.value;
        }
        try {
          //console.log("asdasd",e.field)
          this.loading = true;
          if (
            this.newRecord.collection &&
            !["deliveryrecords", "uniquelabels", "stockchecks"].includes(
              e.field
            ) &&
            this.fields[e.field]
          )
            this.newRecord = await recalcRecord(
              this.newRecord,
              false,
              e.field,
              e.subrecord
            );
          //Object.assign(this.record, newRecord);
          this.$set(this.newRecord, "error", false);
          this.$set(this.newRecord, "v", new Date().toISOString());

          this.loading = false;
        } catch (error) {
          this.$set(this.newRecord, "error", true);
          this.msg = { message: `${error.message}`, show: true, type: "error" };
          this.loading = false;
        }
        this.$emit("emit", this.newRecord);
      }
    },
  },
  async created() {
    const collection = "forms";
    let data = {
      action: "getForm",
      rid: this.form,
    };

    await service(collection, data)
      .catch((error) => {
        throw error;
      })
      .then(async (response) => {
        let tabs = {};
        response.tabs.forEach((tab, index) => {
          if (tab.sections)
            tab.sections.forEach((section, index) => {
              if (section.sublist && !this.record[section.sublist.field])
                this.record[section.sublist.field] = [];
              //   console.log(section)
              // if (section.fields)
              //   section.fields.forEach(field => {
              //     this.record[field.field] = null;
              //   });
            });

          if (tab.table && tab.table.filters)
            Object.entries(tab.table.filters).forEach(([key, value]) => {
              if (value.toString().indexOf("this.") >= 0) {
                let filterField = value.split(".");
                tab.table.filters[key] = this[filterField[1]][filterField[2]];
              }
            });
          tabs[index] = tab;
        });
        response.tabs = tabs;
        this.schema = response;

        if (!this.record.recordtype || !this.record.collection) {
          this.record.recordtype = response.setup.recordtype;
          this.record.collection = response.setup.collection;
        }
      })
      .catch((error) => {
        this.msg = { message: `${error.message}`, show: true, type: "error" };
        this.loading = false;
      });
    try {
      this.record.recordtype = this.$route.params.recordtype;
      this.fields = await getFields(this, this.record);

      if (this.mode != "view") {
        //console.log("recalc3");
        //console.log(this.newRecord.recordtype);
        this.newRecord = await recalcRecord(this.newRecord, false, "init");

        this.$emit("emit", this.newRecord);
      }
      this.loading = false;
    } catch (error) {
      this.$set(this.newRecord, "error", true);
      this.msg = { message: `${error.message}`, show: true, type: "error" };
      this.loading = false;
    }
  },
};
</script>
<style>
.v-input {
  font-size: 12px;
}
.v-window__container {
  height: auto !important;
}
.v-subheader {
  text-transform: uppercase;
  width: 100%;
  background-color: #e1e1e1;
}
</style>